<template>
  <bkf-widget>
    <template slot="title">
      {{ 'alert-rule.assigned-devices-and-groups'|trans }}
    </template>
    <template
      v-if="loaded && !archived"
      slot="actions"
    >
      <bkf-action-add @click="$refs.dialog.open" />
    </template>
    <template v-if="loaded">
      <v-data-table
        v-if="linkedDevicesAndDeviceGroups.length"
        :headers="columns"
        :items="linkedDevicesAndDeviceGroups"
        :hide-default-footer="true"
      >
        <template #[`item.name`]="{ item }">
          <div>
            {{ item.name }}
          </div>
          <div class="row__device-or-device-group pt-2">
            <template v-if="item.device">
              {{ 'alert-rule.device'|trans }}
            </template>
            <template
              v-else
            >
              {{ 'alert-rule.device-group'|trans }}
            </template>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <bkf-action-delete @click="unlink(item)" />
        </template>
      </v-data-table>
      <v-alert
        v-else
        type="info"
        class="mt-0"
      >
        {{ 'alert-rule.no-assigned-devices-and-device-groups'| trans }}
      </v-alert>
    </template>
    <div
      v-else
      class="pv-50 center-align"
    >
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <assign-dialog
      ref="dialog"
      :device-groups="unlinkedDeviceGroups"
      :devices="unlinkedDevices"
      @submit="link"
    />
    <bkf-page-settings :settings.sync="componentSettings" />
  </bkf-widget>
</template>

<script>
import { without } from 'ramda';
import AssignDialog from './AssignDialog.vue';

export default {
  components: {
    AssignDialog,
  },
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    deviceGroupsBus: {
      type: Object,
      required: true,
    },
    devicesBus: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      componentSettings: this.settings,
      linkedDeviceGroupsIds: [],
      linkedDevicesIds: [],
      deviceGroups: [],
      devices: [],
      columns: [{
        value: 'name',
        text: t`alert-rule.name`,
      }, {
        value: 'actions',
        text: '',
        align: 'right',
        sortable: false,
      }],
    };
  },
  computed: {
    archived() {
      return this.alertRule.status === 'h';
    },
    linkedDevicesAndDeviceGroups() {
      const devices = this.linkedDevices;
      const deviceGroups = this.linkedDeviceGroups;

      if (devices) {
        devices.forEach((element) => {
          Object.assign(element, { device: true });
        });
      }

      if (deviceGroups) {
        deviceGroups.forEach((element) => {
          Object.assign(element, { device: false });
        });
      }

      return [...this.linkedDevices, ...this.linkedDeviceGroups];
    },
    linkedDeviceGroups() {
      return this.deviceGroups.filter(
        (deviceGroup) => this.linkedDeviceGroupsIds.includes(deviceGroup.id),
      );
    },
    unlinkedDeviceGroups() {
      return this.deviceGroups.filter(
        (deviceGroup) => !this.linkedDeviceGroupsIds.includes(deviceGroup.id),
      );
    },
    linkedDevices() {
      return this.devices.filter(
        (device) => this.linkedDevicesIds.includes(device.id),
      );
    },
    unlinkedDevices() {
      return this.devices.filter(
        (device) => !this.linkedDevicesIds.includes(device.id),
      );
    },
  },
  watch: {
    componentSettings: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('resize-masonry-item', this.$el);
        });
      },
    },
  },
  created() {
    this.deviceGroupsBus.$on('unassign-device-group', this.onUnassignDeviceGroups);
    this.devicesBus.$on('unassign-device', this.onUnassignDevices);
  },
  async mounted() {
    this.getDeviceGroups();
    this.getDevices();
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
      this.$emit('detect-componet-resize', this.$el);
    });
    this.loaded = true;
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
    });
  },
  methods: {
    async getDeviceGroups() {
      const model = await this.$model('DeviceGroup', { contexts: ['default'] });
      const { entries } = await model.query({
        sort: { name: 'ASC' },
      });

      this.deviceGroups = entries.map((entry) => entry.$data);
      this.linkedDeviceGroupsIds = this.alertRule.deviceGroups.map((g) => g.id);
    },
    async getDevices() {
      this.devices = this.$store.getters['devices/devices'];
      this.linkedDevicesIds = this.alertRule.devices.map((g) => g.id);
    },
    redirect(row) {
      const { id } = row;
      if (row.device) {
        this.$router.push({ name: 'device', params: { id } });
      } else {
        this.$router.push({ name: 'device-group', params: { id } });
      }
    },
    async link(settings) {
      const { deviceGroups, devices } = settings;
      if (deviceGroups.length > 0) {
        this.linkDeviceGroups(deviceGroups);
      }
      if (devices.length > 0) {
        this.linkDevices(devices);
      }
    },
    async unlink(row) {
      if (row.device) {
        this.unlinkDevices([row.id]);
      } else {
        this.unlinkDeviceGroups([row.id]);
      }
    },
    async linkDeviceGroups(ids) {
      const { alertRule } = this;
      await this.$http.patch(`/alertrules/${alertRule.id}/devicegroups/${ids.join(',')}/assign`);
      await alertRule.sync();
      this.linkedDeviceGroupsIds.push(...ids);
      this.$notify.success(t`alert-rule.assigned-devicegroups`);
    },
    async unlinkDeviceGroups(ids) {
      const { alertRule } = this;
      await this.$http.patch(`/alertrules/${alertRule.id}/devicegroups/${ids.join(',')}/unassign`);
      await alertRule.sync();
      this.linkedDeviceGroupsIds = without(ids, this.linkedDeviceGroupsIds);
      this.$notify.success(t`alert-rule.unassigned-devicegroup`);
    },
    onUnassignDeviceGroups(...id) {
      this.linkedDeviceGroupsIds = without(id, this.linkedDeviceGroupsIds);
    },
    async linkDevices(ids) {
      const { alertRule } = this;
      await this.$http.patch(`/alertrules/${alertRule.id}/devices/${ids.join(',')}/assign`);
      await alertRule.sync();
      this.linkedDevicesIds.push(...ids);
      this.$notify.success(t`alert-rule.assigned-devices`);
    },
    async unlinkDevices(ids) {
      const { alertRule } = this;
      await this.$http.patch(`/alertrules/${alertRule.id}/devices/${ids.join(',')}/unassign`);
      await alertRule.sync();
      this.linkedDevicesIds = without(ids, this.linkedDevicesIds);
      this.$notify.success(t`alert-rule.unassigned-device`);
    },
    onUnassignDevices(...id) {
      this.linkedDevicesIds = without(id, this.linkedDevicesIds);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .row__device-or-device-group
    font-size 0.8em
    color #757575
    line-height 0.8em
  .flex-table
    display flex
    flex-direction column
    justify-content space-between
</style>
