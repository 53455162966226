<template>
  <div>
    <v-autocomplete
      v-bind="value"
      :items="options"
      item-text="name"
      item-value="id"
      :label="$trans(placeholder)"
      @change="redirect"
    />
  </div>
</template>

<script>

export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default: ' ',
    },
    options: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      value: null,
      search: '',
    };
  },
  methods: {
    redirect(id) {
      this.$router.push({ name: this.$route.name, params: { id } });
      this.value = null;
    },
  },
};
</script>
