<template>
  <bkf-action
    :tooltip="$trans(value ? 'action-button.shrink' : 'action-button.expand')"
    @click="onClick"
  >
    <v-icon>
      {{ value ? 'fullscreen_exit' : 'fullscreen' }}
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
      this.$emit('click');
    },
  },
};
</script>
