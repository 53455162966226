<template>
  <v-card
    class="px-3 py-3"
    outlined
    color="blue-grey lighten-5"
  >
    <v-expansion-panels
      v-if="devices.length > 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h4>
            {{ headerText }}: {{ devices.length }}
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table
            v-if="devices.length > 0"
            dense
          >
            <template #default>
              <thead>
                <tr>
                  <th
                    class="text-sm-start bold"
                  >
                    {{ 'id'|trans }}
                  </th>
                  <th
                    class="text-sm-start"
                  >
                    {{ 'name'|trans }}
                  </th>
                  <th
                    class="text-sm-start"
                  >
                    {{ 'users.subscription-expire'|trans }}
                  </th>
                  <th
                    class="text-sm-start"
                  >
                    {{ 'device.online'|trans }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="device in devices"
                  :key="device.id"
                >
                  <td class="text-sm-start">
                    {{ device.id }}
                  </td>
                  <td class="text-sm-start">
                    {{ device.name }}
                  </td>
                  <td class="text-sm-start">
                    {{ device.subscriptionExpire|date }}
                  </td>
                  <td class="text-sm-start">
                    {{ device.deviceStatus.lastOnline|dateTime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text v-else>
            <v-icon small>
              mdi-alert-circle-outline
            </v-icon>
            <i class="ml-2">{{ table.no_data|trans }}</i>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

export default {
  name: 'DetailsTableDevices',
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    headerText: {
      type: String,
      default: () => t`fiscal_transactions.details.heading`,
      required: false,
    },
  },
  computed: {
    devices() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      return this.data;
    },
  },
};
</script>
