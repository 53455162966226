import { mapGetters } from 'vuex';

/**
 * Injects current user property
 */
export default {
  computed: {
    ...mapGetters({
      user: 'users/current',
    }),
  },
};
