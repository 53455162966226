import Entity from './entity';
import Configurator from './configurator';
import Creator from './creator';
import ProductionViewConfigurator from './productionviewconfigurator';

export default {
  install(Vue) {
    Vue.use(Entity);
    Vue.use(Configurator);
    Vue.use(Creator);
    Vue.use(ProductionViewConfigurator);
  },
};
