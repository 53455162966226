import Component from './Card.vue';
import Content from './Content';
import Header from './Header';
import Media from './Media';

export default {
  install(Vue) {
    Vue.component('BkfCard', Component);
    Vue.use(Content);
    Vue.use(Header);
    Vue.use(Media);
  },
};
