/**
 * This mixin adds props definitions for params passed from route params to route component
 *
 * Example:
 * Device.vue has path: /manage/devices/:id
 * Instead of getting "id" in this component from this.$route.params.id (unsafe, coupled version),
 * Pass all route params to route component as props (more safe, testful, decoupled version).
 *
 * Reference: https://router.vuejs.org/en/essentials/passing-props.html
 */
export default {
  props: {
    // For Device, Operator, Sensor page components etc.
    id: {
      required: true,
      type: Number,
    },
  },
};
