import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=3b0eb63f&scoped=true&"
import script from "./Breadcrumb.vue?vue&type=script&lang=js&"
export * from "./Breadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./Breadcrumb.styl?vue&type=style&index=0&id=3b0eb63f&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0eb63f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VIcon})
