<template>
  <bkf-action
    :tooltip="$trans(tooltip)"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <v-icon>
      done_all
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    tooltip: {
      required: false,
      type: String,
      default: 'alert.archive-all',
    },
    disabled: {
      required: false,
      type: Boolean,
    },
  },
};
</script>
