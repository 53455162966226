<template>
  <div
    class="bkf-breadcrumb"
  >
    <div>
      <slot>
        <p class="fs-12r fw-400 pt-3 pb-0  mb-0">
          {{ $route.meta.title|trans }}
        </p>
      </slot>
      <v-breadcrumbs
        :items="routeItems"
        class="pl-0 pt-1 pb-1"
      >
        <template #divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div
      class="flex flex-1 mt-2 mb-2 flex-justify-end"
    >
      <portal-target name="page-actions" />
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    routeItems() {
      const routeItems = [];
      this.$route.matched.forEach((element) => {
        const routeObj = {
          text: this.$trans(element.meta.title),
          disabled: (element.meta.redirect),
          to: { name: element.name },
          exact: true,
        };
        routeItems.push(routeObj);
      });

      return routeItems;
    },
  },
};
</script>

<style scoped lang="stylus" src="./Breadcrumb.styl"></style>
