<template>
  <div class="device-tab-work mb-4">
    <div class="flex flex-align-items-center flex-justify-end">
      <div class="year flex">
        <bkf-action-previous-page @click="subYear" />
        <div class="fs-11r mt-1">
          {{ year }}
        </div>
        <bkf-action-next-page @click="addYear" />
      </div>
      <div class="mr-2">
        {{ 'device.when'|trans }}:
      </div>
      <v-radio-group
        v-model="show"
        :mandatory="true"
        row
        dense
      >
        <v-radio
          :label="$trans('device.was-worked-effectively')"
          value="work"
        />
        <v-radio
          :label="$trans('device.was-enabled')"
          value="active"
        />
      </v-radio-group>
    </div>
    <div
      v-if="loading"
      class="progress"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </div>
    <div
      v-if="!loading"
      ref="chartContainer"
      class="chart"
    >
      <e-charts
        ref="heatmap"
        class="echarts-heatmap"
        :options="heatmap"
        :autoresize="true"
      />
    </div>
  </div>
</template>

<script>
import { zip } from 'ramda';
import ECharts from 'vue-echarts/components/ECharts.vue';
import 'echarts';
import getYear from 'date-fns/get_year';
import human from '@utils/human';

export default {
  components: {
    ECharts,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      show: 'work',
      rawData: null,
      year: getYear(new Date()),
      charYear: getYear(new Date()),
    };
  },
  computed: {
    heatmap() {
      return {
        tooltip: {
          position: 'top',
          formatter({ data }) {
            const value = human(data[1]);
            return `${data[0]}: ${value}`;
          },
        },
        visualMap: {
          type: 'piecewise',
          show: true,
          min: 0,
          max: 86400,
          orient: 'horizontal',
          left: 'center',
          right: 'auto',
          bottom: 0,
          itemGap: 8,
          pieces: this.getPieces,
          inRange: {
            color: [
              '#CBCBCB',
              '#c8e6c9',
              '#81c784',
              '#4caf50',
              '#388e3c',
              '#33691e',
              '#1b5e20',
              '#33691e',
              '#003300',
            ],
          },
        },
        calendar: [{
          top: 30,
          left: 40,
          right: 30,
          range: this.chartYear,
          cellSize: ['auto', 20],
          splitLine: {
            show: false,
          },
          itemStyle: {
            normal: {
              color: '#e5e5e5',
              borderWidth: 2,
              borderType: 'solid',
              borderColor: '#fff',
            },
          },
          dayLabel: {
            firstDay: 1,
            nameMap: t`datetime.day-all-shorts-us`.split(','),
          },
          monthLabel: {
            formatter({ nameMap }) {
              return t`datetime.month-short.${nameMap.toLowerCase()}`;
            },
          },
          yearLabel: {
            margin: 50,
          },
        }],
        series: [{
          type: 'heatmap',
          coordinateSystem: 'calendar',
          calendarIndex: 0,
          data: this.chartData,
        }],
      };
    },
    getPiecesForWork() {
      return [
        { max: 0, label: '-' },
        { min: 1, max: 3600, label: '1h' },
        { min: 3600, max: 7200, label: '2h' },
        { min: 7200, max: 10800, label: '3h' },
        { min: 10800, max: 14400, label: '4h' },
        { min: 14400, max: 18000, label: '5h' },
        { min: 18000, max: 21600, label: '6h' },
        { min: 21600, max: 25200, label: '7h' },
        { min: 25200, label: '8h - 24h' },
      ];
    },
    getPiecesForActive() {
      return [
        { max: 0, label: '-' },
        { min: 1, max: 7200, label: '0h - 2h' },
        { min: 7200, max: 14400, label: '2h - 4h' },
        { min: 14400, max: 21600, label: '4h - 6h' },
        { min: 21600, max: 28800, label: '6h - 8h' },
        { min: 28800, max: 36000, label: '8h - 10h' },
        { min: 36000, max: 43200, label: '10h - 12h' },
        { min: 43200, max: 50400, label: '12h - 14h' },
        { min: 50400, max: 57600, label: '14h - 16h' },
        { min: 57600, max: 64800, label: '16h - 18h' },
        { min: 64800, max: 72000, label: '18h - 20h' },
        { min: 72000, max: 79200, label: '20h - 22h' },
        { min: 79200, label: '22h - 24h' },
      ];
    },
    getPieces() {
      return this.show === 'work' ? this.getPiecesForWork : this.getPiecesForActive;
    },
    chartData() {
      const { show, rawData } = this;

      if (rawData === null) {
        return [];
      }

      const { categories, work, active } = rawData;

      if (show === 'work') {
        return zip(categories, work);
      }

      return zip(categories, active);
    },

    getMaxWork() {
      return Math.max.apply(null, this.rawData.work);
    },
    getMaxActive() {
      return Math.max.apply(null, this.rawData.active);
    },
    getVisualMapMax() {
      let max = 0;
      if (this.show === 'work') {
        max = this.getMaxWork;
      } else {
        max = this.getMaxActive;
      }
      return max === 0 ? 3600 : max;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  beforeDestroy() {
    this.loading = true;
  },
  methods: {
    async fetchData() {
      const { device, year } = this;
      const url = `/devices/${device.id}/work/annual`;
      this.loading = true;
      const { data } = await this.$http.get(url, { year });
      this.rawData = data;
      this.chartYear = year;
      this.loading = false;
    },
    addYear() {
      this.year += 1;
      this.fetchData();
    },
    subYear() {
      this.year -= 1;
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  z-index: 10
  width: 100%
  height: 100px
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
  position: relative
  height: 200px

.echarts
  height: 200px
  min-height: auto
</style>
