<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="40%"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <bkf-form
          v-if="show"
          ref="form"
          :context="context"
          :entry="entry"
          :options="options"
          @fetching="onFetching"
          @fetched="onFetched"
          @fetchError="onFetchError"
          @submitting="onSubmitting"
          @submitted="onSubmitted"
          @submitError="onSubmitError"
          @validate-error="onValidateError"
        />
      </v-card-text>
      <v-spacer />
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :loading="fetching || submitting"
          @click="submit"
        >
          {{ 'dialog.update'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

// States as events
const events = {
  fetching: 'fetching',
  fetched: 'fetched',
  fetchError: 'fetchError',
  submitting: 'updating',
  submitted: 'updated',
  submitError: 'updateError',
  validateError: 'validateError',
};

// Component
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    context: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    show: false,
    showDialog: false,
    fetching: false,
    submitting: false,
    entry: null,
    options: {},
  }),
  watch: {
    showDialog(newValue) {
      if (newValue === false) {
        this.onClose();
      }
    },
  },
  methods: {
    open(entry, options = {}) {
      this.fetching = false;
      this.submitting = false;
      this.show = true;
      this.entry = entry || null;
      this.options = options;
    },
    submit() {
      this.$refs.form.submit();
    },
    async onClose() {
      this.$emit('close');
      await this.$sleep(500);
      this.show = false;
    },
    onFetching() {
      this.fetching = true;
      this.$emit(events.fetching);
    },
    onFetched() {
      this.fetching = false;
      this.$emit(events.fetched);
      this.showDialog = true;
    },
    onFetchError(payload) {
      this.fetching = false;
      this.show = false;
      this.$emit(events.fetchError, payload);
    },
    onSubmitting() {
      this.submitting = true;
    },
    async onSubmitted(payload) {
      this.$emit(events.submitted, payload);
      this.showDialog = false;
    },
    async onSubmitError(payload) {
      this.$emit(events.submitError, payload);
      this.showDialog = false;
    },
    onValidateError() {
      this.submitting = false;
      this.$emit(events.validateError);
    },
  },
};
</script>
