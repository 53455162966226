var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"550","eager":"","transform-origin":"bottom center"},on:{"input":function (v) { return v || _vm.submit(); }},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"d-flex flex-column"},[_c('v-toolbar',{staticClass:"flex-grow-0",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("trans")('dashboard.configure-dashboard')))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-4 flex-grow-1"},[_c('v-card-subtitle',[_vm._v("\n        "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.show'))+"\n      ")]),_vm._v(" "),_c('v-list',{attrs:{"subheader":"","flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_c('v-list-item',{attrs:{"value":"activity-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.activity-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c('v-list-item',{attrs:{"value":"efficiency-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.efficiency-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c('v-list-item',{attrs:{"value":"operator-summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.operator-summary'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c('v-list-item',{attrs:{"value":"attention"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.attention'))+"\n                ")])],1)]}}])}),_vm._v(" "),_c('v-list-item',{attrs:{"value":"work-efficiency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n                  "+_vm._s(_vm._f("trans")('dashboard.settings.widgets.effective-work'))+"\n                ")])],1)]}}])})],1)],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('dialog.close'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }