<template>
  <div class="page-report">
    <bkf-widget>
      <template slot="title">
        {{ 'report.field.types'|trans }}
      </template>
      <v-list>
        <template
          v-for="report in reports"
        >
          <v-divider :key="`${report.name}_divider`" />
          <v-list-item
            :key="report.name"
            :to="{ name: report.name }"
          >
            <v-list-item-avatar>
              <v-icon>
                mdi-file-document
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ report.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ report.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </bkf-widget>
  </div>
</template>

<script>

export default {
  props: {
    reports: {
      required: true,
      type: Array,
    },
  },
};
</script>
