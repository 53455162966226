var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}},[(_vm.isMobile)?_c('bkf-floating-button-add',{attrs:{"tooltip":_vm.$trans('device-operator-group.create')},nativeOn:{"click":function($event){return _vm.create.apply(null, arguments)}}}):_c('v-btn',{attrs:{"color":"primary","tile":""},nativeOn:{"click":function($event){return _vm.create.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm._f("trans")('device-operator-group.create'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":_vm.loading,"error":_vm.error}},[(_vm.deviceOperatorGroups.length)?_c('bkf-widget',[_c('v-card-title',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.deviceOperatorGroups,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size,"search":_vm.search},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((_vm.columns),function(h){return {key:("header." + (h.value)),fn:function(){return [(h.tooltip)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(h.tooltip))])]):[_vm._v("\n              "+_vm._s(h.text)+"\n            ")]]},proxy:true}}),{key:"item.diagnostics.active",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("human")(_vm.getGroupActiveTime(item)))+"\n          ")]}},{key:"item.diagnostics.work",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("human")(_vm.getGroupWorkTime(item)))+"\n          ")]}},{key:"item.diagnostics.productivity",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.diagnostics.productivity)+"%\n          ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-edit',{on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})],1):_c('bkf-empty-state')],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device-operator-group.create')}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.settings},on:{"update:settings":function($event){_vm.settings=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }