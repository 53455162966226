<template>
  <div>
    <div
      v-if="loading"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
      />
    </div>
    <div v-if="!loading">
      <shift-range
        v-for="(range, id) in shifts"
        :id="range.index"
        :key="range.index"
        :range="range"
        :error="errors[id]"
        @change="change"
        @remove="removeRange"
      />
      <v-btn
        color="primary"
        tile
        :disabled="canAddRange"
        @click="addRange"
      >
        {{ 'shifts.add-shift'|trans }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { findIndex, propEq, last } from 'ramda';
import ShiftRange from './Range';

function getPlusEight(start) {
  const end = Number.parseInt(start, 10) + 8 < 24 ? Number.parseInt(start, 10) + 8 : 24;
  return `${end}:00`;
}

export default {
  components: {
    ShiftRange,
  },
  props: {
    loading: {
      type: Boolean,
      require: false,
      default: true,
    },
    shifts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    getLastStart() {
      return this.shifts.length ? last(this.shifts).timeEnd : '00:00';
    },
    canAddRange() {
      return (this.shifts.length > 3);
    },
  },
  methods: {
    change(value) {
      const { index } = value;
      const id = findIndex(propEq('index', index))(this.shifts);
      this.shifts[id] = value;

      this.validateRanges();
      this.$emit('change', this.shifts);
    },
    removeRange(id) {
      const index = findIndex(propEq('index', id))(this.shifts);
      this.shifts.splice(index, 1);

      this.validateRanges();
      this.$emit('change', this.shifts);
    },
    addRange() {
      const index = this.uniqId();
      const name = t`shifts.shift`;
      const timeStart = this.getLastStart;
      const timeEnd = getPlusEight(timeStart);

      this.shifts.push({
        index, timeStart, timeEnd, name,
      });
      this.validateRanges();
      this.$emit('change', this.shifts);
    },
    uniqId() {
      return new Date().valueOf();
    },
    validateRanges() {
      const errors = [];
      let lastEnd = null;

      this.shifts.forEach((shift) => {
        const start = Number.parseInt(shift.timeStart, 10);
        const end = Number.parseInt(shift.timeEnd, 10);

        if (start < lastEnd) {
          errors.push(true);
          return true;
        }

        errors.push(false);
        lastEnd = end;
        return true;
      });
      this.errors = errors;
    },
  },
};
</script>
