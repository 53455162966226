<template>
  <div>
    <bkf-page
      v-if="isActivePage"
      :loading="loading"
    />
    <router-view />
  </div>
</template>

<script>
import pageMixin from '@mixins/page';

export default {
  mixins: [pageMixin],
};
</script>
