import VBreadcrumb from './VBreadcrumb';
import Guide from './Guide';
import VHeader from './VHeader';
import Loader from './Loader';
import VNavMenu from './VNavMenu';
import Page from './Page';
import VProfileMenu from './VProfileMenu';

export default {
  install(Vue) {
    Vue.use(VBreadcrumb);
    Vue.use(Guide);
    Vue.use(VHeader);
    Vue.use(Loader);
    Vue.use(VNavMenu);
    Vue.use(Page);
    Vue.use(VProfileMenu);
  },
};
