<template>
  <v-tooltip>
    <template #activator="{ on, attrs }">
      <slot
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <span>{{ content }}</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    content: {
      required: true,
      type: String,
      default: null,
    },
    placement: {
      required: false,
      type: String,
      default: 'bottom',
    },
  },
};
</script>
