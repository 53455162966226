<template>
  <bkf-action-export @click="exportData()" />
</template>

<script>
import { prop } from 'ramda';
import csv from '@utils/csv';

export default {
  props: {
    data: {
      required: true,
      type: Array,
    },
    columns: {
      required: true,
      type: Array,
    },
    filename: {
      required: false,
      type: String,
      default: null,
    },
  },
  methods: {
    exportData() {
      const { data, columns, filename } = this;

      const labels = [];
      const indexes = [];
      const values = [];

      columns.forEach(({
        value, exportable, exportBy, text,
      }) => {
        if (exportable !== false) {
          labels.push(text);
          indexes.push({ value, type: exportBy });
        }
      });

      data.forEach((dataRow) => {
        const row = [];
        indexes.forEach((index) => {
          if (typeof index.type === 'function') {
            row.push(index.type(prop(index.value, dataRow), dataRow));
          } else {
            row.push(prop(index.value, dataRow) !== null ? prop(index.value, dataRow) : '');
          }
        });
        values.push(row);
      });

      csv(values, labels, null, { filename });
    },
  },
};
</script>
