<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="50%"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 flex-grow-1">
        <bkf-report-creator
          v-if="showDialog && reportsType"
          :reports-type="reportsType"
          :model="model"
          @submitted="onSubmitted"
        />
        <alarm-creator
          v-if="showDialog && newAlarm"
          :model="model"
          @submitted="onSubmitted"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import sizeMixin from '@mixins/store/size';
import AlarmCreator from '../creator/AlarmCreator.vue';

export default {
  components: {
    AlarmCreator,
  },
  mixins: [sizeMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    reportsType: {
      type: Array,
      required: false,
      default: null,
    },
    newAlarm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      model: null,
      options: {},
    };
  },
  methods: {
    openDialog(model, options) {
      this.model = model;
      this.options = options;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async onClose() {
      this.$emit('close');
      await this.$sleep(500);
      this.show = false;
    },
    async onSubmitted(payload) {
      if (payload) {
        this.$emit('created', payload);
      }
      this.closeDialog();
    },
  },
};
</script>
