<template>
  <div class="mt-10">
    <v-alert
      v-if="lackOfData"
      type="warning"
      class="mt-0"
    >
      {{ 'geo-monitor.lack-of-data'|trans }}
    </v-alert>
    <v-list
      v-if="!lackOfData"
      class="list"
    >
      <v-list-item>
        <strong>{{ 'alert.device'|trans }}:</strong>
        <span>{{ value.device.name }}</span>
      </v-list-item>
    </v-list>
    <div
      id="map"
      class="w-100p h-300"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import { convertTimestamp } from '@utils/date';

export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      lackOfData: false,
      center: {
        lat: 51.919438,
        lon: 19.145136,
      },
      circle: [],
      mapSource: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 5,
      map: null,
      polygonLayer: null,
      bounds: [],
    };
  },
  mounted() {
    if (this.map === null) {
      this.initMap();
      this.drawCircle();
      this.fitToBounds();
    }
  },
  methods: {
    initMap() {
      const { center, zoom, mapSource } = this;

      this.map = L.map('map', { editable: true, attributionControl: false })
        .setView([center.lat, center.lon], zoom);
      L.tileLayer(mapSource).addTo(this.map);
    },
    createCircle(gd, date) {
      return L.circle([gd[0], gd[1]], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: gd[2] || 0,
      }).bindTooltip(this.tooltipContent(date), { permanent: true });
    },
    drawCircle() {
      if (!this.value.data.length) {
        return;
      }

      this.value.data.forEach((position) => {
        const circle = this.createCircle(position.data.gd, position.timestamp);
        this.map.addLayer(circle);
        this.circle.push(circle);
      });
      this.drawPolygon();
      this.bounds.push(this.circle.map((circle) => circle.getBounds()));
    },
    createPolygonLayer(latlngs) {
      this.polygonLayer = L.polygon(latlngs);
      this.polygonLayer.addTo(this.map);
      this.bounds.push(new L.LatLngBounds(latlngs));
    },
    drawPolygon() {
      if (!this.value.alertRule.id) {
        return;
      }

      this.value.alertRule.conditions.forEach((condition) => {
        this.createPolygonLayer(
          JSON.parse(condition.conditions.geopolygon).map((position) => [position[1], position[0]]),
        );
      });
    },
    fitToBounds() {
      if (this.bounds.length) {
        this.map.fitBounds(this.bounds);
      }
    },
    tooltipContent(date) {
      return `${convertTimestamp(date)}`;
    },
  },
};
</script>
