<template>
  <div>
    <template v-if="alertRule.type === 'work efficiency'">
      {{ 'alert-rule.condition.efficiency'|trans }}
      <template v-if="row.conditions.operator">
        {{ ('alert-rule.condition.operator.' + conditions[row.conditions.operator].name)
          |trans|lower }}
      </template>
      {{ row.conditions.argument }}%
      ({{ `alert-rule.condition.last-${row.conditions.date_interval}`|trans }})
    </template>

    <template v-else-if="alertRule.type === 'effective work time'">
      {{ 'alert-rule.condition.effective-work-time'|trans }}
      <template v-if="row.conditions.operator">
        {{ ('alert-rule.condition.operator.' + conditions[row.conditions.operator].name)
          |trans|lower }}
      </template>
      {{ row.conditions.argument }} {{ 'alert-rule.condition.minutes'|trans }}
      ({{ `alert-rule.condition.last-${row.conditions.date_interval}`|trans }})
    </template>

    <template v-else-if="alertRule.type === 'periodic performance'">
      {{ 'alert-rule.condition.efficiency'|trans }}
      {{ ('alert-rule.condition.operator.' + conditions[row.conditions.operator].name
        + '-without-sign') |trans|lower }}
      {{ row.conditions.argument }}%.
      {{ 'alert-rule.condition.checked'|trans }}
      {{ ('alert-rule.condition.' + conditions[row.conditions.date_interval].name)
        |trans }}
      {{ 'alert-rule.condition.from-hour'|trans }} {{ row.conditions.fromHour }}
      {{ 'alert-rule.condition.to-hour'|trans }} {{ row.conditions.toHour }}.
    </template>

    <template v-else-if="alertRule.type === 'geolocation'">
      {{ 'alert-rule.condition.device-outside-defined-area'|trans }}
    </template>

    <template v-else-if="alertRule.type === 'part replace'">
      {{ 'alert-rule.types.part-replace'|trans }}
      {{ 'alert-rule.condition.every'|trans }}
      {{ row.conditions.argument }} h
    </template>

    <template v-else-if="alertRule.type === 'charging'">
      {{ 'alert-rule.types.charging'|trans }}
      {{ ('alert-rule.condition.operator.' + conditions[row.conditions.operator].name)
        |trans|lower }}
      {{ row.conditions.argument }}
      {{ 'alert-rule.condition.on'|trans }}
      {{ ('alert-rule.condition.' + conditions[row.conditions.date_interval].name)
        |trans|lower }}
    </template>

    <template v-else-if="alertRule.type === 'periodic'">
      {{ 'alert-rule.types.periodic'|trans }}
      {{ 'alert-rule.condition.every'|trans }}
      {{ row.conditions.argument }}
      {{ ('alert-rule.condition.' + conditions[row.conditions.date_interval].name)
        |trans|lower }}
      {{ 'alert-rule.condition.from-hour'|trans }}
      {{ row.conditions.date_origin }}
    </template>

    <template v-else-if="alertRule.type === 'percentage'">
      <template v-if="row.conditions.date_interval">
        {{ ('alert-rule.condition.' + conditions[row.conditions.date_interval].name)
          |trans|lower }}
      </template>
      <template v-else>
        <template v-if="row.conditions.operator">
          {{ ('alert-rule.condition.operator.percentage-'
            + conditions[row.conditions.operator].name)
            |trans|lower }}
        </template>
        {{ row.conditions.argument }} %
      </template>
    </template>

    <template v-else>
      <template v-if="row.conditions.date_interval">
        {{ ('alert-rule.condition.' + conditions[row.conditions.date_interval].name)
          |trans|lower }}
      </template>
      <template v-else>
        {{ row.conditions.argument }}
      </template>
    </template>
  </div>
</template>

<script>
import conditions from '@globals/alerts/conditions';

export default {
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    archived() {
      return this.alertRule.status === 'h';
    },
    conditions() {
      return conditions;
    },
  },
};
</script>
