<template>
  <v-simple-table

    class="efficiency-report block"
  >
    <thead>
      <tr v-if="deviceGroups.length">
        <th>
          {{ 'efficiency-report.device-group'|trans }}
        </th>
        <th>
          {{ 'efficiency-report.device'|trans }}
        </th>
        <th>
          {{ 'device-efficiency-report.active-time'|trans }}
        </th>
        <th>
          {{ 'device-efficiency-report.work-time'|trans }}
        </th>
        <th>
          {{ 'device-efficiency-report.report-counter'|trans }}
        </th>
        <th
          v-for="header in headers"
          :key="header"
          class="center-align"
        >
          <template v-if="interval === 'year'">
            {{ header|date('YYYY') }}
          </template>
          <template v-else-if="interval === 'month'">
            {{ header|date('YYYY-MM') }}
          </template>
          <template v-else-if="interval === 'shifts'">
            {{ header|date('YYYY-MM-DD HH:mm') }}
          </template>
          <template v-else>
            {{ header|date }}
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(deviceGroup, index) in deviceGroups">
        <template v-if="deviceGroup.devices.length">
          <tr
            v-for="(device, deviceIndex) in deviceGroup.devices"
            :key="deviceIndex"
          >
            <td class="wmin-200">
              {{ deviceGroup.name }}
            </td>
            <td class="wmin-200">
              {{ device.name }}
            </td>
            <td class="wmin-200">
              {{ device.summaryActive }}
            </td>
            <td class="wmin-200">
              {{ device.summaryWork }}
            </td>
            <td class="wmin-200">
              {{ device.reportCounter }}
            </td>
            <td
              v-for="(efficiency, efficiencyIndex) in device.efficiency"
              :key="efficiencyIndex"
              class="center-align w-150"
            >
              {{ efficiency.efficiency }}% / {{ efficiency.counter }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr :key="index">
            <td class="wmin-200">
              {{ deviceGroup.name }}
            </td>
            <td class="wmin-200">
              -
            </td>
            <td
              v-for="header in headers"
              :key="header"
              class="center-align w-150"
            >
              -
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    deviceGroups: {
      required: true,
      type: Array,
    },
    headers: {
      required: true,
      type: Array,
    },
    interval: {
      required: false,
      type: String,
      default: 'day',
    },
  },
};
</script>
