<template>
  <indicator :state="state">
    <bkf-icon-battery
      :level="roundedValue"
      :state="state"
    />
    <template slot="description">
      <template v-if="state === null">
        {{ 'device.indicator.no-data'|trans }}
      </template>
      <template v-else-if="state === 'incorrect'">
        {{ 'device.indicator.incorrect-data'|trans }}
      </template>
      <template v-else>
        {{ roundedValue + '%' }}
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: Number,
      default: null,
    },
  },
  computed: {
    state() {
      const { value } = this;

      if (value === null || value === undefined) {
        return null;
      }

      if (value > 100) {
        return 'incorrect';
      }

      if (value >= 20) {
        return 'positive';
      }

      return 'negative';
    },
    roundedValue() {
      const { value } = this;

      if (value !== null && value !== undefined) {
        return Math.round(value);
      }

      return value;
    },
  },
};
</script>
