<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <bkf-floating-button-add
          v-if="isMobile"
          :tooltip="$trans('device-operator-group.create')"
          @click.native="create"
        />
        <v-btn
          v-else
          color="primary"
          tile
          @click.native="create"
        >
          {{ 'device-operator-group.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="deviceOperatorGroups.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="deviceOperatorGroups"
            :item-class="rowClass"
            :items-per-page="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template
              v-for="h in columns"
              #[`header.${h.value}`]
            >
              <v-tooltip
                v-if="h.tooltip"
                :key="h.value"
                top
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ h.text }}</span>
                </template>
                <span>{{ h.tooltip }}</span>
              </v-tooltip>
              <template v-else>
                {{ h.text }}
              </template>
            </template>
            <template #[`item.diagnostics.active`]="{ item }">
              {{ getGroupActiveTime(item)|human }}
            </template>
            <template #[`item.diagnostics.work`]="{ item }">
              {{ getGroupWorkTime(item)|human }}
            </template>
            <template #[`item.diagnostics.productivity`]="{ item }">
              {{ item.diagnostics.productivity }}%
            </template>
            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state v-else />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-operator-group.create')"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import { mapGetters } from 'vuex';

export default {
  mixins: [
    pageMixin,
    listMixin,
    settingsMixin,
    sizeMixin,
  ],
  data() {
    return {
      search: '',
      settings: {
        size: 10,
      },
      deviceOperatorGroups: [],
      columns: [{
        value: 'name',
        text: t`device-operator-group.name`,
      },
      {
        value: 'device_operators.length',
        text: t`device-operator-group.devices-operator-number`,
      },
      {
        value: 'diagnostics.active',
        text: t`device-operator-group.today-activity`,
        tooltip: t`device-operator-group.average-activity-time`,
      },
      {
        value: 'diagnostics.work',
        text: t`device-group.today-work`,
        tooltip: t`device-operator-group.average-work-time`,
      },
      {
        value: 'diagnostics.productivity',
        text: t`device-group.today-productivity`,
        tooltip: t`device-operator-group.average-productivity-time`,
      },
      {
        value: 'actions',
        sortable: false,
        filterable: false,
        exportable: false,
      }],
    };
  },
  computed: {
    ...mapGetters({
      deviceOperatorGroupDiagnostics: 'deviceoperators/deviceOperatorGroupDiagnostics',
    }),
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'deviceOperatorGroup',
      });

      const tmpOperatorGroups = [];

      this.deviceOperatorGroups.forEach((group) => {
        const diagnostics = {
          active: this.averageGroupActivity(group.id, group.device_operators.length),
          work: this.averageGroupWork(group.id, group.device_operators.length),
          productivity: this.groupProductivity(group.id, group.device_operators.length),
        };
        group.diagnostics = diagnostics;

        tmpOperatorGroups.push(group);
      });

      this.deviceOperatorGroups = tmpOperatorGroups;
    },
    averageGroupActivity(id, operatorCount) {
      if (!this.deviceOperatorGroupDiagnostics(id)) {
        return 0;
      }
      return operatorCount !== 0
        ? (this.deviceOperatorGroupDiagnostics(id).active / operatorCount) : 0;
    },
    averageGroupWork(id, operatorCount) {
      if (!this.deviceOperatorGroupDiagnostics(id)) {
        return 0;
      }
      return operatorCount !== 0
        ? (this.deviceOperatorGroupDiagnostics(id).work / operatorCount) : 0;
    },
    groupProductivity(id, operatorCount) {
      const activity = this.averageGroupActivity(id, operatorCount);

      if (activity === 0) {
        return 0;
      }

      return Math.round(this.averageGroupWork(id, operatorCount) / activity * 100);
    },
    getGroupActiveTime(row) {
      return row?.diagnostics?.active;
    },
    getGroupWorkTime(row) {
      return row?.diagnostics?.work;
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-operator-group', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
