<template>
  <div>
    <template v-if="field.type === 'text'">
      <template
        v-if="field.label === 'alert-rule.condition.from-hour'
          || field.label === 'alert-rule.condition.to-hour'"
      >
        <v-menu
          ref="menu"
          v-model="timePickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="bindValue"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="bindValue"
              :label="$trans(field.label)"
              readonly
              v-bind="attrs"
              :error="hasError"
              :error-messages="getErrorMessage"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="timePickerMenu"
            v-model="bindValue"
            full-width
            format="24hr"
            @click:minute="$refs.menu.save(bindValue)"
          />
        </v-menu>
      </template>
      <template v-else-if="field.label === 'alert-rule.condition.date'">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"

          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="bindValue"
              :label="$trans('alert-rule.condition.first-alarm-on')"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="bindValue"
            no-title
            @input="menu = false"
          />
        </v-menu>
      </template>
      <template v-else>
        <v-text-field
          v-model="bindValue"
          :label="$trans(field.label)"
          :disabled="disabled"
          :required="field.required"
          :error="hasError"
          :error-messages="getErrorMessage"
        />
      </template>
    </template>

    <template v-else-if="field.type === 'number'">
      <template
        v-if="field.label === 'alert-rule.condition.efficiency-in-percents'
          || field.label === 'alert-rule.condition.repeat-alarm-argument'
          || field.label === 'alert-rule.condition.percentage-difference'"
      >
        <v-text-field
          v-model="bindValue"
          :label="$trans(field.label)"
          :disabled="disabled"
          :required="field.required"
          :min="1"
          :max="100"
          :step="1"
          type="number"
          :error="hasError"
          :error-messages="getErrorMessage"
        />
      </template>
      <template v-else>
        <v-text-field
          v-model="bindValue"
          :label="$trans(field.label)"
          :disabled="disabled"
          :required="field.required"
          type="number"
          :error="hasError"
          :error-messages="getErrorMessage"
        />
      </template>
    </template>

    <template v-else-if="field.type === 'email'">
      <v-text-field
        v-model="bindValue"
        :label="$trans(field.label)"
        :disabled="disabled"
        :required="field.required"
        type="email"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
    </template>

    <template
      v-else-if="field.type === 'entity'"
    >
      <v-autocomplete
        v-if="field.label === 'alert-rule.parameter'
          || field.label === 'alert-rule.part-parameter'"
        v-model="bindValue"
        :label="$trans(field.label)"
        :clearable="!field.required"
        :multiple="field.multiple"
        :items="filetParameter"
        :item-text="getSelectItemText"
        item-value="id"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
      <v-autocomplete
        v-else
        v-model="bindValue"
        :label="$trans(field.label)"
        :clearable="!field.required"
        :multiple="field.multiple"
        :items="choices"
        :item-text="getSelectItemText"
        :item-value="getSelectItemValue"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
    </template>

    <template v-else-if="field.type === 'choice'">
      {{ field.expanded }}

      <v-select
        v-model="bindValue"
        :items="sortedChoices"
        :clearable="!field.required"
        :multiple="field.multiple"
        :label="$trans(field.label)"
        :disabled="field.label === 'report.field.types'"
        :error="hasError"
        :error-messages="getErrorMessage"
      >
        <template
          #item="data"
        >
          {{ `${data.item.label}`|trans }}
        </template>
        <template
          #selection="data"
        >
          {{ `${data.item.label}`|trans }}
        </template>
      </v-select>
    </template>

    <template v-else-if="field.type === 'textarea'">
      <v-textarea
        v-model="bindValue"
        :name="name"
        :label="$trans(field.label)"
        :disabled="disabled"
        no-resize
        rows="3"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
    </template>

    <template v-else-if="field.type === 'checkbox'">
      <v-checkbox
        v-model="bindValue"
        :name="name"
        :disabled="disabled"
        :label="$trans(field.label)"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
    </template>

    <template v-else-if="field.type === 'switch'">
      <v-switch
        v-model="bindValue"
        :name="name"
        :disabled="disabled"
        :label="$trans(field.label)"
        :error="hasError"
        :error-messages="getErrorMessage"
      />
    </template>

    <template v-else-if="field.type === 'datetime'">
      <v-menu
        v-model="datePickerMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="bindValue"
            readonly
            v-bind="attrs"
            :disabled="disabled"
            :label="$trans(field.label)"
            :error="hasError"
            :error-messages="getErrorMessage"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-if="datePickerMenu"
          v-model="bindValue"
          no-title
          first-day-of-week="1"
          @click:date="datePickerMenu = false"
        />
      </v-menu>
    </template>

    <template v-else-if="isGeopolygon">
      {{ field.label|trans }}{{ field.required ? ' *' : '' }}
      <bkf-geo-polygon-field
        v-model="bindValue"
        @editing="polygonEditing"
      />
    </template>

    <div
      v-else-if="field.type ==='radio'"
    >
      <v-radio-group
        v-model="bindValue"
        :mandatory="true"
        row
        dense
      >
        <v-radio
          v-for="choice in choices"
          :key="choice.label"
          :label="$trans(choice.label)"
          :value="choice.value"
          :error="hasError"
          :error-messages="getErrorMessage"
        />
      </v-radio-group>
    </div>
    <!-- <template v-else>
      not supported: {{ field.type }}
    </template> -->
  </div>
</template>

<script>
import { equals, sort } from 'ramda';
import parameterTrans from '@mixins/misc/parameterTrans';
import { format } from 'date-fns';
import BkfGeoPolygonField from '../../fields/GeoPolygon.vue';

export default {
  components: {
    BkfGeoPolygonField,
  },
  mixins: [parameterTrans],
  props: {
    name: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    choices: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Array,
      required: false,
      default: null,
    },
    /* eslint-disable vue/require-prop-types */
    value: {
      required: true,
      default: null,
    },
    fetching: {
      type: Boolean,
      required: true,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    bindValue: null,
    startValue: null,
    timePickerMenu: false,
    datePickerMenu: false,
    checkErrors: true,
    menu: false,
  }
  ),
  computed: {
    changed() {
      return !equals(this.startValue, this.bindValue);
    },
    disabled() {
      return this.field.disabled || this.fetching;
    },
    sortedChoices() {
      const { choices, $trans } = this;
      const sortByLabel = (a, b) => ($trans(a.label) >= $trans(b.label));
      const sorted = sort(sortByLabel, choices);
      if (this.field.label === 'alert-rule.alert-type') {
        sorted.sort((a, b) => ($trans(a.label) >= $trans(b.label) ? 1 : -1));
      }
      return sorted;
    },
    isGeopolygon() {
      return this.field.type === 'geopolygon';
    },
    filetParameter() {
      if (this.options.type !== 'charging') {
        return this.choices.filter((choice) => choice.id !== 10000);
      }
      return this.choices.filter((choice) => choice.id === 10003);
    },
    hasError() {
      if (this.errors === null || this.checkErrors === false) {
        return false;
      }

      return this.errors.length > 0;
    },
    getErrorMessage() {
      if (this.errors === null || this.checkErrors === false) {
        return '';
      }
      return this.$trans(this.errors[0]);
    },
  },
  watch: {
    bindValue(value) {
      if (this.field.label === 'alert-rule.condition.efficiency-in-percents'
          && value.length !== 0) {
        this.bindValue = parseInt(value, 10);
      }
      if (this.field.changeToUpperCase) {
        this.bindValue = value.toUpperCase();
      }
      this.$emit('input', value);
    },
    // If errors are changed, it means that form was submitted
    // and we need to reset start value to properly render errors (if new start value was unchanged)
    errors() {
      this.startValue = this.bindValue;
    },
  },
  created() {
    this.bindValue = this.value === undefined || this.value === null ? '' : this.value;
    this.startValue = this.bindValue;

    if (this.field.type === 'datetime') {
      this.bindValue = this.bindValue.substring(0, 10);
    }
    if (this.field.label === 'alert-rule.condition.repeat-alarm-argument'
    || this.field.label === 'alert-rule.condition.percentage-difference') {
      this.bindValue = 1;
    }
    if (this.field.label === 'alert-rule.condition.date') {
      this.bindValue = format(new Date(), 'YYYY-MM-DD');
    }
    if (this.field.label === 'alert-rule.condition.repeat-alarm-every') {
      this.bindValue = { label: 'alert-rule.condition.day', value: 'day' };
    }
    if (this.field.label === 'alert-rule.condition.operator.label-comparison') {
      this.bindValue = { value: '>' };
    }
  },
  methods: {
    polygonEditing() {
      this.bindValue = null;
    },
    getLabel(label) { return this.$trans(label); },
    getSelectItemText(item) { return this.$trans(item.name); },
    getSelectItemValue: (item) => item.id,
  },
};
</script>
