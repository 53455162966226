<template>
  <div>
    <transition name="progress">
      <slot
        v-if="loading"
        name="progress"
      >
        <div
          class="p-20 fs-10r pos-absolute"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </slot>
    </transition>
    <transition
      name="page-loading"
      mode="out-in"
    >
      <slot v-if="!loading && !error" />
      <slot
        v-if="!loading && error"
        name="error"
      >
        <span>
          {{ error }}
        </span>
      </slot>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      required: false,
      type: Boolean,
      default: true,
    },
    error: {
      required: false,
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="stylus" src="./Page.styl"></style>
