<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
    @input="v => v || submit()"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'widget.activity-summary.settings.title'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-radio-group
          v-model="context"
          :mandatory="true"
          row
          dense
        >
          <v-radio
            :label="$trans('widget.activity-summary.settings.all-devices')"
            value="devices"
          />
          <v-radio
            :label="$trans('widget.activity-summary.settings.selected-device-groups')"
            value="deviceGroups"
          />
        </v-radio-group>
        <div
          v-if="context === 'deviceGroups'"
        >
          <v-autocomplete
            v-model="value.deviceGroups"
            :items="deviceGroups"
            :multiple="true"
            chips
            small-chips
            deletable-chips
            item-text="name"
            item-value="id"
            :label="$trans('widget.activity-summary.settings.device-groups')"
          />
        </div>
        <v-checkbox
          v-model="value.showAdditionalInfo"
          :label="$trans('widget.activity-summary.settings.additional-info')"
        />
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          @click="submit"
        >
          {{ 'widget.activity-summary.settings.save-settings'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      context: 'devices',
      showAdditionalInfo: true,
      showDialog: false,
    };
  },
  created() {
    if (this.value.deviceGroups.length) {
      this.context = 'deviceGroups';
    }
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      if (this.value.deviceGroups.length === 0) {
        this.context = 'devices';
      }
      if (this.context === 'devices') {
        this.value.deviceGroups = [];
      }
      this.$emit('submit', this.value);
      this.close();
    },
  },
};
</script>
