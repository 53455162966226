<template>
  <section
    ref="rootEl"
    class="faq"
  >
    <div class="faq-wrapper">
      <Transition
        name="accordion-fade-slide"
        mode="out-in"
      >
        <div
          v-if="showAccordion"
          class="accordion"
        >
          <div
            v-for="(item, i) in items"
            :key="`accordion-item-${i}`"
            class="accordion__item"
          >
            <div
              :class="generateQuestionClasses(i)"
              @click="makeActive(i)"
            >
              <p class="fw-500">
                {{ item.title }}
              </p>
              <button :class="generateButtonClasses(i)" />
            </div>
            <transition
              name="fade"
            >
              <div
                v-if="i === activeQuestionIndex"
                class="md-list-expand-container"
              >
                <p class="mt-0">
                  {{ item.value }}
                </p>
              </div>
            </transition>
          </div>
        </div>
      </Transition>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAccordion: true,
      activeQuestionIndex: null,
    };
  },
  methods: {
    makeActive(itemIndex) {
      if (this.activeQuestionIndex === itemIndex) {
        this.activeQuestionIndex = null;
      } else {
        this.activeQuestionIndex = itemIndex;
      }
    },
    generateButtonClasses(buttonIndex) {
      return [
        'accordion__toggle-button',
        this.activeQuestionIndex === buttonIndex
          ? 'accordion__toggle-button_active'
          : null,
      ];
    },
    generateQuestionClasses(questionIndex) {
      return [
        'accordion__title',
        this.activeQuestionIndex === questionIndex
          ? 'accordion__title_active'
          : null,
      ];
    },
  },
};
</script>

<style scoped lang="stylus" src="./Accordion.styl"></style>
