<template>
  <bkf-widget>
    <template slot="title">
      {{ 'sensor.assigned-to-device'|trans }}
    </template>
    <v-list
      v-if="device"
      dense
    >
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.name'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          <device-button
            v-if="device"
            :device="device"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.type'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ device.deviceType.name }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.group'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          <span
            v-for="(deviceGroup, index) in device.deviceGroups"
            :key="deviceGroup.id"
          ><template v-if="index">,</template>{{
            deviceGroup.name }}</span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-alert
      v-else
      type="warning"
      tile
      class="mt-0"
    >
      {{ 'sensor.no-assigned-to-device'|trans }}
    </v-alert>
  </bkf-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import buttonsMixin from '@mixins/domain/device/buttons';

export default {
  mixins: [buttonsMixin],
  props: {
    deviceId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      model: null,
      device: null,
      entry: null,
    };
  },
  computed: {
    ...mapGetters({
      deviceById: 'devices/deviceById',
    }),
  },
  watch: {
    deviceId: {
      immediate: true,
      handler(to) {
        this.reload(to);
      },
    },
  },
  methods: {
    redirect({ id }) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async reload(deviceId) {
      if (deviceId === null) {
        this.device = null;
        return;
      }

      this.device = this.deviceById(deviceId);
    },
  },
};
</script>
