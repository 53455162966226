<template>
  <div>
    <v-alert
      type="info"
      class="mt-0"
    >
      <template v-if="$props.isReport">
        {{ 'alert.rules.report-info'|trans }}
      </template>
      <template v-else>
        {{ 'alert.rules.user-info'|trans }}
      </template>
    </v-alert>
    <v-autocomplete
      v-model="value"
      :items="users"
      :multiple="true"
      chips
      small-chips
      deletable-chips
      item-text="name"
      item-value="id"
      :label="$trans('report.field.users')"
      @input="emitInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    users: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Array,
    },
    isReport: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    usersIds() {
      return this.value.map((group) => group.id);
    },
  },
  methods: {
    emitInput(usersIds) {
      const items = usersIds.map((id) => this.users.find((item) => item.id === id));
      this.$emit('input', items);
    },
  },
};
</script>
