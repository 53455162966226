<template>
  <v-autocomplete
    :value="value"
    :items="deviceOperatorsGroup"
    :multiple="true"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="$trans('report.field.device-operators-groups')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    deviceOperatorsGroup: {
      required: true,
      type: Array,
    },
  },
  methods: {
    emitInput(deviceOperatorsGroupIds) {
      this.$emit('input', deviceOperatorsGroupIds);
    },
  },
};
</script>
