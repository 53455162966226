<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
    @input="v => v || submit()"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'widget.devices-attention.settings'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-checkbox
          v-model="value.deviceGroups"
          :label="$trans('widget.devices-attention.show-device-groups')"
        />
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          @click="close"
        >
          {{ 'dialog.close'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>
