<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device.technical-parameters.title'|trans }}
    </template>
    <template
      v-if="!noFields"
      slot="actions"
    >
      <v-dialog
        v-model="showDialog"
        max-width="40%"
        :fullscreen="$vuetify.breakpoint.mobile"
        eager
        transform-origin="bottom center"
      >
        <template #activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
        </template>
        <v-card class="d-flex flex-column">
          <v-toolbar
            dark
            color="primary"
            class="flex-grow-0"
          >
            <v-toolbar-title>{{ 'device.technical-parameters.title'|trans }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              dark
              @click="showDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-4 flex-grow-1">
            <v-row
              v-for="field in fields"
              :key="field.id"
            >
              <v-col>
                <v-text-field
                  :label="
                    $trans(field.parameter.name_translation)"
                  disabled
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="field.value"
                  :label="$trans('device.technical-parameters.parameter-value')"
                >
                  <span
                    v-if="field.unit"
                    slot="append"
                  >{{ field.unit.short_name || '' }}</span>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer />
          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              tile
              color="primary"
              :loading="submitting"
              @click="submit"
            >
              {{ 'dialog.update'|trans }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-alert
      v-if="noFields"
      type="info"
      class="mt-0"
    >
      {{ 'device.technical-parameters.no-parameters'|trans }}
    </v-alert>
    <v-list dense>
      <v-list-item
        v-for="field in fields"
        :key="field.id"
        class="hmin-30"
      >
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ field.parameter.name_translation|trans }}:
        </v-list-item-content>
        <v-list-item-content
          v-if="field.value"
          class="flex-grow-1 text-body-2 py-1"
        >
          <span>
            {{ field.value }} <span v-if="field.unit">{{ field.unit.short_name }}</span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </bkf-widget>
</template>

<script>

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: null,
      showDialog: false,
      submitting: false,
      noFields: false,
      fields: [],
      values: [],
    };
  },
  computed: {
    pluralized() {
      return `devices/${this.device.id}/work/required`;
    },
    options() {
      return {
        pluralized: this.pluralized,
        model: this.model,
      };
    },
  },
  async created() {
    this.model = await this.$model('Device');
  },
  async mounted() {
    await this.fetchParameters(this.$props.device.id);
  },
  methods: {
    edit() {
      this.$refs.updateDialog.open(this.model, this.options);
    },
    edited() {
      this.$emit('refresh');
    },
    async submit() {
      const payload = this.fields;
      this.submitting = true;
      const response = await this.$http.post(this.$url(`/api/devices/${this.$props.device.id}/technical_parameters`), payload);
      if (response.status === 200) {
        this.submitting = false;
        this.showDialog = false;
      }
    },
    async fetchParameters(deviceId) {
      const { status, data } = await this.$http.get(this.$url(`/api/devices/${deviceId}/technical_parameters`));
      if (status === 200) {
        // eslint-disable-next-line prefer-destructuring
        this.values = data[1]; // some weird eslint behavior

        const newFields = [];
        data[0].forEach((field) => {
          const v = this.values.filter((value) => value.parameter.name === field.parameter.name)[0];

          if (v !== undefined) {
            newFields.push({ ...field, value: v.value });
          } else {
            newFields.push({ ...field, value: '' });
          }
        });
        this.fields = newFields;
        if (this.fields.length === 0) {
          this.noFields = true;
        }
      }
    },
  },
};
</script>
