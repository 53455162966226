<template>
  <div class="activity-summary">
    <svg
      width="500"
      height="320"
      viewBox="15 0 200 160"
    >
      <g class="total">
        <text
          class="label"
          x="120"
        >
          <tspan
            y="81"
            text-anchor="middle"
          >
            {{ 'widget.activity-summary.total'|trans }}:
          </tspan>
        </text>
        <text
          class="number"
          x="120"
        >
          <tspan
            y="90"
            text-anchor="middle"
          >
            {{ values.total }}
          </tspan>
        </text>
      </g>
      <g
        class="legend"
        transform="translate(115, 17)"
      >
        <text
          class="working"
          y="0"
        >
          {{ 'widget.activity-summary.devices-working'|trans }}: {{ values.working }}
        </text>
        <text
          class="online"
          y="10"
        >
          {{ 'widget.activity-summary.modules-online'|trans }}: {{ values.online }}
        </text>
        <text
          class="offline"
          y="20"
        >
          {{ 'widget.activity-summary.modules-offline'|trans }}: {{ values.offline }}
        </text>
      </g>
      <g
        class="background"
        transform="rotate(-90 64 64) translate(48, 120)"
      >
        <circle
          r="66"
          :stroke-dasharray="6.282 * 66 * 0.8"
        />
        <circle
          r="56"
          :stroke-dasharray="6.282 * 56 * 0.8"
        />
        <circle
          r="46"
          :stroke-dasharray="6.282 * 46 * 0.8"
        />
        <circle
          class="working"
          r="66"
          :stroke-dasharray="dash.working"
        />
        <circle
          class="online"
          r="56"
          :stroke-dasharray="dash.online"
        />
        <circle
          class="offline"
          r="46"
          :stroke-dasharray="dash.offline"
        />
      </g>
      <g
        class="scale"
        transform="translate(120, 80)"
      >
        <template v-if="anim.working">
          <text
            class="scale working"
            :x="calcX(anim.working,78)"
            :y="calcY(anim.working,78)"
          >
            {{ Math.round(anim.working * 100) }}%
          </text>
          <line
            class="working"
            :x1="calcX(anim.working,62)"
            :y1="calcY(anim.working,62)"
            :x2="calcX(anim.working,72)"
            :y2="calcY(anim.working,72)"
          />
        </template>
        <template v-if="anim.online">
          <text
            class="scale online"
            :x="calcX(anim.online,24)"
            :y="calcY(anim.online,24)"
          >
            {{ Math.round(anim.online * 100) }}%
          </text>
          <line
            class="online"
            :x1="calcX(anim.online,60)"
            :y1="calcY(anim.online,60)"
            :x2="calcX(anim.online,30)"
            :y2="calcY(anim.online,30)"
          />
        </template>
        <template v-if="anim.offline">
          <text
            class="scale offline"
            :x="calcX(anim.offline,23)"
            :y="calcY(anim.offline,24)"
          >
            {{ Math.round(anim.offline * 100) }}%
          </text>
          <line
            class="offline"
            :x1="calcX(anim.offline,50)"
            :y1="calcY(anim.offline,50)"
            :x2="calcX(anim.offline,30)"
            :y2="calcY(anim.offline,30)"
          />
        </template>
      </g>
    </svg>
  </div>
</template>

<script>
import anime from 'animejs';

const dash = (r, p) => r * p * Math.PI * 1.6;
const calc = (value, total) => Math.min(value, total) / (total || 1);

export default {
  props: {
    values: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      anim: {
        total: 0,
        working: 0,
        enabled: 0,
        online: 0,
        offline: 0,
      },
    };
  },
  computed: {
    dash() {
      const {
        working, online, offline,
      } = this.anim;

      return {
        working: `${dash(66, working)} 1000`,
        online: `${dash(56, online)} 1000`,
        offline: `${dash(46, offline)} 1000`,
      };
    },
  },
  watch: {
    values: {
      deep: true,
      immediate: true,
      handler(to) {
        this.animate(to);
      },
    },
  },
  methods: {
    animate(to) {
      anime({
        targets: this.anim,
        working: calc(to.working, to.total),
        online: calc(to.online, to.total),
        offline: calc(to.offline, to.total),
        easing: 'easeInOutQuad',
      });
    },
    calcX(percent, length) {
      return Math.sin(Math.PI * 1.6 * percent) * length;
    },
    calcY(percent, length) {
      return -Math.cos(Math.PI * 1.6 * percent) * length;
    },
  },
};
</script>

<style scoped lang="stylus">
working-color = #61a5f4
enabled-color = #FF9800
online-color = #5fd15c
offline-color = #F44336
grey-color = #ddd
dark-text-color = #757575

.activity-summary
  .background
    fill: none
    stroke: grey-color
    stroke-width: 8
  .scale
    font-size: 5px
    font-family: Roboto
    font-weight: 600
    font-style: normal
    text-anchor: middle
    alignment-baseline: middle
    fill: darken(grey-color, 10%)
  .total
    .label
      font-size: 8px
    .number
      font-size: 8px
    stroke: none
    fill: dark-text-color
  line
    stroke-width: 1px
    &.working
      stroke: working-color
    &.enabled
      stroke: enabled-color
    &.online
      stroke: online-color
    &.offline
      stroke: offline-color
  text
    font-size: 8px
    font-style: normal
    font-weight: 500
    font-family: Roboto
    text-anchor: end

    &.working
      fill: working-color
    &.enabled
      fill: enabled-color
    &.online
      fill: online-color
    &.offline
      fill: offline-color
  circle
    &.working
      stroke: working-color
    &.enabled
      stroke: enabled-color
    &.online
      stroke: online-color
    &.offline
      stroke: offline-color
</style>
