<template>
  <v-row>
    <v-col>
      <bkf-page
        v-if="isActivePage"
        :error="error"
        :loading="loading"
        class="pt-3"
      >
        <div>
          <bkf-floating-button-add
            v-if="isTablet"
            :tooltip="$trans('report.add')"
            @click.native="configurator"
          />
          <predefined-reports :reports="reportsType" />
          <report-configurator
            :reports-type="reportsType"
            :models="model"
            :reports-configurations="reportsConfigurations"
          />
        </div>
      </bkf-page>
      <router-view />
      <bkf-crud
        v-if="isTablet"
        ref="crud"
        :create-submit-label="$trans('report.action.create')"
        :update-title="$trans('report.action.edit')"
        :delete-title="$trans('report.action.delete')"
        :reports-type="reportsType"
      />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import PredefinedReports from './Predefined';
import ReportConfigurator from './Configurator';

export default {
  components: {
    PredefinedReports,
    ReportConfigurator,
  },
  mixins: [
    pageMixin,
    settingsMixin,
    sizeMixin,
    listMixin,
  ],
  data() {
    return {
      reportsType: [
        // {
        //   name: 'alerts-report',
        //   title: t`report.predefined.alerts.title`,
        //   description: t`report.predefined.alerts.description`,
        // },
        {
          name: 'efficiency-report',
          title: t`report.predefined.efficiency.title`,
          description: t`report.predefined.efficiency.description`,
        },
        {
          name: 'device-operators-report',
          title: t`report.predefined.device-operators.title`,
          description: t`report.predefined.device-operators.description`,
        },
        {
          name: 'device-operators-detailed-report',
          title: t`report.predefined.device-operators-detailed.title`,
          description: t`report.predefined.device-operators-detailed.description`,
        },
        {
          name: 'shifts-report',
          title: t`report.predefined.shifts.title`,
          description: t`report.predefined.shifts.description`,
        },
        // {
        //   name: 'device-efficiency-report',
        //   title: t`report.predefined.device-efficiency.title`,
        //   description: t`report.predefined.device-efficiency.description`,
        // },
      ],
      model: null,
      refs: this.$refs,
      reportsConfigurations: [],
    };
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'ReportsConfiguration',
      });
    },
  },
};
</script>
