<template>
  <bkf-widget :flat="true">
    <template slot="actions">
      <bkf-action-edit
        @click="edit"
      />
    </template>
    <v-list
      dense
    >
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.required-work.active'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getActivity }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.required-work.work'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getWork }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.required-work.days'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getWeekdays }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device.required-work.alarm'|trans }}
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getActive }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <bkf-dialog-entity-update
      ref="updateDialog"
      :model="model"
      :title="$trans('device.required-work.title')"
      :submit-label="$trans('dialog.add')"
      @updated="edited"
    />
  </bkf-widget>
</template>

<script>

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    pluralized() {
      return `devices/${this.device.id}/work/required`;
    },
    options() {
      return {
        pluralized: this.pluralized,
        model: this.model,
      };
    },
    Empty() {
      return this.device.required_activity_and_work === null;
    },
    getWeekdays() {
      if (this.Empty) {
        return '-';
      }
      if (this.device.required_activity_and_work.requiredWeekdays.length === 0) {
        return '-';
      }

      const days = [];

      this.device.required_activity_and_work.requiredWeekdays.forEach((element) => {
        days.push(this.$trans(`datetime.day.${element}`));
      });

      return days.join(', ');
    },
    getActivity() {
      if (this.Empty) {
        return '-';
      }
      return this.device.required_activity_and_work.requiredDailyActivity || '-';
    },
    getWork() {
      if (this.Empty) {
        return '-';
      }
      return this.device.required_activity_and_work.requiredDailyWork || '-';
    },
    getActive() {
      if (this.Empty) {
        return '-';
      }
      return this.device.required_activity_and_work.active ? this.$trans('yes') : this.$trans('no');
    },
  },
  async created() {
    this.model = await this.$model('Device');
  },
  methods: {
    edit() {
      this.$refs.updateDialog.open(this.model, this.options);
    },
    edited() {
      this.$emit('refresh');
    },
  },
};
</script>
