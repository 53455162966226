<template>
  <v-simple-table
    dense
  >
    <template #default>
      <thead>
        <tr>
          <th>{{ 'alerts-report.alert.device'|trans }}</th>
          <th>{{ 'alerts-report.alert.creation-time'|trans }}</th>
          <th>{{ 'alerts-report.alert.definition'|trans }}</th>
          <th>{{ 'alerts-report.alert.occurrences'|trans }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="device in devices">
          <tr
            v-for="alarm in device.allAlarms"
            :key="alarm.id"
          >
            <td>{{ device.name }}</td>
            <td>{{ alarm.ctime|date }}</td>
            <td>{{ alarm.alertRule.name }}</td>
            <td>{{ alarm.counter }}</td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    devices: {
      required: true,
      type: Array,
    },
  },
};
</script>
