<script>
import settingsMixin from '@mixins/misc/settings';

export default {
  mixins: [
    settingsMixin,
  ],
  props: {
    /* eslint-disable vue/require-prop-types */
    settings: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      oldSettings: [],
    };
  },
  computed: {
    settingName() {
      return `page-${this.$route.name}`;
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(val) {
        // Diagnostic widgets
        if ((val.widgets !== undefined && this.oldSettings.widgets !== undefined)
          && Object.entries(val).toString() !== Object.entries(this.oldSettings).toString()) {
          this.save(this.settings);
          this.setOldSettings();
          return;
        }

        // Devices location
        if ((val.devices !== undefined && this.oldSettings.devices !== undefined)
          && Object.entries(val).toString() !== Object.entries(this.oldSettings).toString()) {
          this.save(this.settings);
          this.setOldSettings();
          return;
        }

        // Devices list
        if ((val.moduleActivity !== undefined && this.oldSettings.moduleActivity !== undefined)
          && Object.entries(val).toString() !== Object.entries(this.oldSettings).toString()) {
          this.save(this.settings);
          this.setOldSettings();
          return;
        }

        // Device Operators list
        if ((val.workInterval !== undefined && this.oldSettings.workInterval !== undefined)
          && Object.entries(val).toString() !== Object.entries(this.oldSettings).toString()) {
          this.save(this.settings);
          this.setOldSettings();
          return;
        }

        // Diagnostic monitors
        if ((val.monitors !== undefined && this.oldSettings.monitors !== undefined)) {
          this.save(this.settings);
        }
      },
    },
  },
  created() {
    this.$emit('loading', false);
    const settings = this.getSetting(this.settingName);
    if (settings !== undefined) {
      this.$emit('update:settings', settings);
    }

    this.$emit('loaded', settings);
  },
  mounted() {
    this.$nextTick(() => {
      this.setOldSettings();
    });
  },
  methods: {
    setOldSettings() {
      this.oldSettings = { ...this.settings };
    },
    async save(to) {
      this.$emit('updating', true);
      await this.setSetting(this.settingName, to);
      this.$emit('updated', false);
    },
  },
  render: () => null,
};
</script>
