<template>
  <bkf-form
    v-if="show"
    ref="form"
    class="form"
    :model="model"
    :entry="alertRule"
    @fetching="onFetching"
    @fetched="onFetched"
    @fetch-error="onFetchError"
    @submitting="onSubmitting"
    @submitted="onSubmitted"
    @submitError="onSubmitError"
    @validate-error="onValidateError"
  />
</template>

<script>
export default {
  props: {
    model: {
      type: Function,
      required: false,
      default: null,
    },
    alertRule: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    show: false,
    fetching: false,
    entries: [],
    conditions: [],
    config: {
      multiSelect: false,
    },
  }),
  mounted() {
    this.fetching = true;
    this.show = true;
    this.onEditing();
  },
  methods: {
    onFetching() {
      this.fetching = true;
      this.$emit('fetching');
    },
    onFetched() {
      this.fetching = false;
      this.$emit('fetched');
    },
    onFetchError(payload) {
      this.fetching = false;
      this.show = false;
      this.$emit('fetch-error', payload);
    },
    onSubmitting() {
      this.submitting = true;
    },
    async onSubmitted(payload) {
      this.$emit('submitted', payload);
    },
    async onSubmitError(payload) {
      this.$emit('create-error', payload);
    },
    onValidateError() {
      this.submitting = false;
      this.$emit('validate-error');
    },
    onEditing() {
      if (this.alertRule) {
        this.$emit('editing');
      }
    },
  },
};
</script>
