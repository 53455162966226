import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

import pl from 'vuetify/es5/locale/pl';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue,
      },
    },
  },
  lang: {
    locales: { pl, en },
    current: 'pl',
  },
};

export default new Vuetify(opts);
