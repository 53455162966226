import trans from '../utils/trans';

/**
 * Translation method plugin
 */
export default {
  install(Vue) {
    Vue.prototype.$bkf.trans = trans;
    Vue.prototype.$trans = trans;
  },
};
