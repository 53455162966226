<template>
  <v-autocomplete
    :value="value"
    :items="deviceGroups"
    :multiple="true"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="$trans('monitor.device-groups')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
  },
  methods: {
    /**
     * Emit input current state
     * @param {array} deviceGroupsIds Array of deviceGroups ids
     */
    emitInput(deviceGroupsIds) {
      this.$emit('input', deviceGroupsIds);
    },
  },
};
</script>
