import human from '@utils/human';

/**
 * Convert seconds to human readable format
 * @param {int} seconds
 * @param {{}} options
 */
export default function (seconds, options) {
  return human(seconds, options);
}
