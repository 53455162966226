<script>
export default {
  props: {
    speed: {
      type: Number,
      required: false,
      default: 1,
    },
    pause: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  watch: {
    pause(to) {
      if (to) {
        this.stop();
      } else {
        this.start();
      }
    },
  },
  destroyed() {
    this.stop();
  },
  methods: {
    /**
     * Start autoinvoking
     */
    start() {
      this.timer = setInterval(this.invoke, this.speed * 1000);
    },
    /**
     * Invoke callback
     */
    invoke() {
      if (!this.pause) {
        this.$emit('refresh');
      }
    },
    /**
     * Stop autoinvoking
     */
    stop() {
      clearInterval(this.timer);
    },
  },
  render(h) {
    return h();
  },
};
</script>
