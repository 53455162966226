export default {
  methods: {
    startGuide(name) {
      this.$root.$children[0].$refs.guide.start(name);
    },
    registerGuide(guide) {
      this.$root.$children[0].$refs.guide.register(guide);
    },
  },
};
