<template>
  <indicator :state="state">
    <bkf-icon-sensor
      :state="state"
      :last-sync="lastSync"
    />
    <template #description>
      <template v-if="onlineStatus == null">
        <template v-if="state === 'positive'">
          {{ 'device.indicator.active'|trans }}
        </template>
        <template v-else-if="state ==='negative'">
          {{ 'device.indicator.inactive'|trans }}
        </template>
        <template v-else>
          {{ 'device.indicator.no-data'|trans }}
        </template>
      </template>
      <template v-else>
        <template v-if="onlineStatus === 'ok'">
          {{ 'device.indicator.active'|trans }}
        </template>
        <template v-else-if="onlineStatus === 'error'">
          {{ 'device.indicator.inactive'|trans }}
        </template>
        <template v-else>
          -
        </template>
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: Boolean,
      default: null,
    },
    lastSync: {
      required: false,
      type: String,
      default: null,
    },
    onlineStatus: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    state() {
      if (this.onlineStatus !== null) {
        return `icon-${this.onlineStatus}`;
      }

      if (this.value === true) {
        return 'positive';
      }

      if (this.value === false) {
        return 'negative';
      }

      return null;
    },
  },
};
</script>
