<template>
  <bkf-widget>
    <template slot="title">
      {{ 'alert-rule.details'|trans }}
    </template>
    <template
      v-if="!archived"
      slot="actions"
    >
      <bkf-tooltip
        :content="switchTooltip()"
      >
        <v-switch
          v-model="alertRule.enabled"
          dense
          hide-details
          class="mt-1 w40"
          @change="setEnabledFlag"
        />
      </bkf-tooltip>
      <bkf-action-edit @click="edit">
        edit
      </bkf-action-edit>
      <bkf-action-delete @click="remove" />
    </template>
    <v-list>
      <v-list-item class="hmin-30 pl-0">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'alert-rule.name'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ alertRule.name }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30 pl-0">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'alert-rule.description'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ alertRule.description || '-' }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30 pl-0">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'alert-rule.type'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ `alert-rule.types.${alertRule.type.split(' ').join('-')}`|trans }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30 pl-0">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'alert-rule.creation-time'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ alertRule.ctime|dateTime }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </bkf-widget>
</template>

<script>
export default {
  props: {
    archived: {
      required: true,
      type: Boolean,
    },
    alertRule: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
    });
  },
  methods: {
    switchTooltip() {
      if (this.alertRule.enabled) {
        return this.$trans('alert.rules.tooltip-active');
      }
      return this.$trans('alert.rules.tooltip-deactivate');
    },
    async setEnabledFlag() {
      const { id, enabled } = this.alertRule;
      const action = enabled ? 'disable' : 'enable';
      const { status } = await this.$http.patch(`/alertrules/${id}/${action}`);

      if (status === 204) {
        this.$notify.success(enabled ? 'alert.rules.disabled' : 'alert.rules.enabled');
      }
    },
    edit() {
      this.$emit('edit');
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>
