import { pluralize } from 'inflection';
import { get as getApi } from '@services/api';

const symbols = {
  client: Symbol('$client'),
  connector: Symbol('$connector'),
  contexts: Symbol('$contexts'),
  data: Symbol('$data'),
  entries: Symbol('$entries'),
  name: Symbol('$name'),
  pluralized: Symbol('$pluralized'),
};

const queryMappers = {
  context: 'context',
  limit: 'page_size',
  filter: 'filter',
  sort: 'sort',
  page: 'page',
  perPage: 'perPage',
  search: 'search',
};

function defineFields(target, data) {
  Reflect.ownKeys(data).forEach((name) => {
    target[symbols.data][name] = data[name];

    Reflect.defineProperty(target, name, {
      enumerable: true,
      get() {
        return target[symbols.data][name];
      },
      set(newValue) {
        target[symbols.data][name] = newValue;
      },
    });
  });
}

/**
 * Connector to backend
 */
export class Connector {
  [symbols.client] = null;

  constructor({ client }) {
    this[symbols.client] = client;
  }

  /**
   * Get Client assigned to Connector
   */
  get $client() {
    return this[symbols.client];
  }

  /**
   * Get entry
   *
   * @param {string} string Pluralized Pluralized name of model
   * @param {{}} payload Payload (id, contexts, etc.)
   */
  async get(pluralized, payload) {
    let id = '';
    let params = '';
    let action = '';

    if (typeof payload !== 'object') {
      id = payload;
    }

    if (typeof payload === 'object' && payload !== null) {
      if (payload.id) {
        ({ id } = payload);
      }

      if (payload.contexts) {
        params = `${params}&context=${payload.contexts.join(',')}`;
      }

      if (payload.action) {
        action = id ? `/${payload.action}` : payload.action;
      }

      if (payload.requestId) {
        params = `${params}&requestId=${payload.requestId}`;
      }
    }

    if (params) {
      params = `?${params}`;
    }

    const { data, headers, status } = await this.$client.get(`${pluralized}/${id}${action}${params}`);

    if (status === 401) {
      return {
        status,
      };
    }

    return {
      data,
      status,
      contexts: headers['x-entries-contexts'].split(','),
    };
  }

  /**
   * Query for entries
   *
   * @param {string} pluralized Pluralized name of model
   * @param {{}} payload Payload (id, contenxts etc.)
   */
  async query(pluralized, payload) {
    const params = [];
    let action = '';

    if (typeof payload === 'object' && payload !== null) {
      if (Reflect.has(payload, 'contexts')) {
        params.push(`${params}&context=${payload.contexts.join(',')}`);
      }

      if (Reflect.has(payload, 'filter')) {
        params.push(`${queryMappers.filter}=${JSON.stringify(payload.filter)}`);
      }

      if (Reflect.has(payload, 'limit')) {
        params.push(`${queryMappers.limit}=${payload.limit}`);
      }

      if (Reflect.has(payload, 'perPage')) {
        params.push(`${queryMappers.perPage}=${payload.perPage}`);
      }

      if (Reflect.has(payload, 'search')) {
        params.push(`${queryMappers.search}=${payload.search}`);
      }

      if (Reflect.has(payload, 'page')) {
        params.push(`${queryMappers.page}=${payload.page}`);
      }

      if (Reflect.has(payload, 'sort')) {
        const fields = Reflect.ownKeys(payload.sort).map((name) => {
          const order = payload.sort[name] === 'desc' ? '-' : '';
          return `${order}${name}`;
        });

        params.push(`${queryMappers.sort}=${fields.join(',')}`);
      }

      if (Reflect.has(payload, 'action')) {
        action = `/${payload.action}`.replace('//', '/');
      }

      if (Reflect.has(payload, 'requestId')) {
        params.push(`requestId=${payload.requestId}`);
      }
    }

    try {
      const query = `${pluralized}${action}?${params.join('&')}`;
      const { data, headers, status } = await this.$client.get(query);
      if (status === 401) {
        return {
          status,
        };
      }

      let entries = data;

      if (data.data !== undefined) {
        entries = data.data;
      }

      if (status === 204) {
        entries = [];
      }

      const response = {
        entries,
      };

      if (headers['x-entries-contexts'] !== undefined) {
        response.contexts = headers['x-entries-contexts'].split(',');
      }

      if (headers['x-pager-page-entries'] !== undefined) {
        response.pageEntries = Number(headers['x-pager-page-entries']);
      }

      if (headers['x-pager-page-number'] !== undefined) {
        response.pageNumber = Number(headers['x-pager-page-number']);
      }

      if (headers['x-pager-total-entries'] !== undefined) {
        response.totalEntries = Number(headers['x-pager-total-entries']);
      }

      if (headers['x-pager-total-pages'] !== undefined) {
        response.totalPages = Number(headers['x-pager-total-pages']);
      }

      if (headers['x-request-id'] !== undefined) {
        response.requestId = Number(headers['x-request-id']);
      }

      return response;
    } catch (e) {
      return null;
    }
  }

  /**
   * Synchronize entry with backend (sync from backend to frontend)
   *
   * @param {string} Pluralized Pluralized name of model
   * @param {{}} payload Payload (id, contexts, itd.)
   * @returns {{}} data with state
   */
  async sync(pluralized, payload) {
    const { data, status } = await this.get(pluralized, payload);
    let state = null;

    if (status === 200) {
      state = 'updated';
    }

    if (status === 404) {
      state = 'deleted';
    }

    return {
      data,
      state,
    };
  }
}

/**
 * Model responsible for creating models (collections) and entries (objects)
 */
export class BaseModel {
  constructor({ contexts, data }) {
    Reflect.defineProperty(this, symbols.contexts, {
      value: new Set(),
    });

    Reflect.defineProperty(this, symbols.data, {
      value: {},
    });

    contexts.forEach((context) => {
      this[symbols.contexts].add(context);
    });

    defineFields(this, data);
  }

  /**
   * Load new data to entry
   * @param {{}} data
   */
  load(data) {
    Reflect.ownKeys(data).forEach((key) => {
      this[key] = data[key];
    });
  }

  /**
   * One way sync (backend to frontend) entry
   */
  async sync() {
    const { $connector, $pluralized } = this.constructor;

    const { data, state } = await $connector.sync($pluralized, {
      id: this.$data.id,
      contexts: [...this.$contexts],
    });

    if (state === 'updated') {
      this.load(data);
    }

    return {
      data,
      state,
    };
  }

  /**
   * Get contexts assigned to entry
   */
  get $contexts() {
    return this[symbols.contexts];
  }

  /**
   * Get data of entry
   */
  get $data() {
    return this[symbols.data];
  }

  /**
   * Get model of entry
   */
  get $model() {
    return this.constructor;
  }

  /**
   * Get model of entry
   */
  get $name() {
    return this.constructor.$name;
  }

  /**
   * Connector instance of model
   */
  static [symbols.connector] = null;

  /**
   * Get connector of model
   */
  static get $connector() {
    return this[symbols.connector];
  }

  /**
   * Get entries of model
   */
  static get $entries() {
    return this[symbols.entries];
  }

  /**
   * Get name of model
   */
  static get $name() {
    return this[symbols.name];
  }

  /**
   * Get pluralized name of model
   */
  static get $pluralized() {
    return this[symbols.pluralized];
  }

  /**
   * Get entry of model with given payload
   * @param {{}} payload
   * @return {BaseModel} entry instance
   */
  static async get(payload) {
    const { $connector, $pluralized } = this;
    const alwaysGetFromApi = ['devices', 'deviceoperators'];
    let entry;
    let id;
    let reload = false;
    let force = false;

    if (typeof payload !== 'object') {
      id = payload;
    }

    if (payload.id) {
      ({ id } = payload);
    }

    if (payload.force) {
      ({ force } = payload);
    }

    if (!alwaysGetFromApi.includes($pluralized)) {
      if (this.$entries.has(id) && !force) {
        entry = this.$entries.get(id);

        if (payload.contexts) {
          payload.contexts.forEach((context) => {
            if (!entry.$contexts.has(context)) {
              reload = true;
            }
          });
        }

        if (!reload) {
          return this.$entries.get(id);
        }
      }
    }

    if (reload && !force) {
      const { data } = await $connector.get($pluralized, payload);
      defineFields(entry, data);

      return entry;
    }

    entry = new this(await $connector.get($pluralized, payload));
    this.$entries.set(id, entry);

    return entry;
  }

  /**
   * Get entries of model with given payload
   * @param {{}} payload
   * @return {Array}
   */
  static async query(payload) {
    const { $connector, $pluralized } = this;
    const response = await $connector.query($pluralized, payload);
    const entries = [];

    response.entries.forEach((instance) => {
      const entry = new this({ contexts: response.contexts, data: instance });
      this.$entries.set(entry.id, entry);
      entries.push(entry);
    });

    return {
      entries,
      contexts: response.contexts,
      pageEntries: response.pageEntries,
      pageNumber: response.pageNumber,
      totalEntries: response.totalEntries,
      totalPages: response.totalPages,
      requestId: response.requestId,
    };
  }

  /**
   * Set connector of model
   * @param {Connector} connector
   */
  static setConnector(connector) {
    this[symbols.connector] = connector;

    return this;
  }

  /**
   * Create model by extending BaseModel with some data
   * @param {{}} payload
   */
  static extend({ name }) {
    const Model = class extends BaseModel {
      static [symbols.entries] = new Map();

      static [symbols.name] = name;

      static [symbols.pluralized] = pluralize(name).toLowerCase();
    };

    return Model;
  }
}

export const mutations = {
  MODELS_CREATE: 'MODELS_CREATE',
};

export default {
  namespaced: true,
  state: {
    connector: null,
    models: new Map(),
  },
  getters: {
    models: (s) => s.models,
  },
  mutations: {
    [mutations.MODELS_CREATE](state, name) {
      const { apiClient } = state;
      const model = BaseModel.extend({ name, apiClient });
      state.models.set(name, model);
    },
  },
  actions: {
    /**
     * Get model with given name
     * @param {function} commit
     * @param {{}} getters
     * @param {string} name
     * @return {BaseModel}
     */
    get({ commit, getters }, name) {
      // Set connector if not exists
      if (!BaseModel.$connector) {
        BaseModel.setConnector(new Connector({
          client: getApi(),
        }));
      }

      // create model if not exists
      if (!getters.models.has(name)) {
        commit(mutations.MODELS_CREATE, name);
      }
      return getters.models.get(name);
    },
  },
};
