<template>
  <div
    class="add-chart"
  >
    <svg
      :class="monitorClass"
      viewBox="0 0 480 610"
      @click="onClicked('monitor')"
      @mouseover="activeMonitor = true"
      @mouseout="activeMonitor = false"
    >
      <!-- eslint-disable max-len -->
      <g stroke-width="10">
        <g fill="none">
          <rect
            ry="36"
            y="5"
            x="5"
            height="600"
            width="470"
          />
          <rect
            ry="6"
            y="85"
            x="139"
            height="140"
            width="200"
          />
        </g>
        <!-- chart -->
        <g
          stroke-width="8"
          fill="none"
          transform="translate(179,100)"
        >
          <path d="M8,77l35,-22m13,2l18,18m13,0l32,-37" />
          <circle
            cx="0"
            cy="82"
            r="9"
          />
          <circle
            cx="50"
            cy="52"
            r="9"
          />
          <circle
            cx="79"
            cy="82"
            r="9"
          />
          <circle
            cx="124"
            cy="32"
            r="9"
          />
        </g>
        <g stroke="none">
          <!-- hood -->
          <rect
            ry="8"
            y="76"
            x="125"
            height="18"
            width="230"
          />
          <!-- stand circles -->
          <g transform="translate(157,314)">
            <circle
              cx="0"
              cy="0"
              r="9"
            />
            <circle
              cx="83"
              cy="0"
              r="9"
            />
            <circle
              cx="166"
              cy="0"
              r="9"
            />
          </g>
        </g>
        <g
          stroke-width="9"
          fill="none"
        >
          <!-- stand legs -->
          <g transform="translate(240,225)">
            <path d="m0,0v86m0,-40l81,40m-81,-40l-78,40" />
          </g>
          <!-- plus -->
          <g transform="translate(90,240)">
            <path d="m0,0v50m-25,-25h50" />
          </g>
        </g>
      </g>
      <g
        transform="translate(240,420)"
        letter-spacing="2"
      >
        <text
          font-family="Roboto"
          font-size="38px"
          font-weight="bold"
          text-anchor="middle"
          font-style="normal"
          stroke="none"
        >
          <tspan
            v-for="(text,index) in addMonitorText.split('\\n')"
            :key="text"
            :y="index * 45"
            x="0"
          >
            {{ text }}
          </tspan>
        </text>
      </g>
    </svg>
    <svg
      :class="deviceOperatorMonitorClass"
      viewBox="0 0 480 610"
      @click="onClicked('deviceOperatorMonitor')"
      @mouseover="activeDeviceOperatorMonitor = true"
      @mouseout="activeDeviceOperatorMonitor = false"
    >
      <g stroke-width="10">
        <g fill="none">
          <rect
            ry="36"
            y="5"
            x="5"
            height="600"
            width="470"
          />
          <rect
            ry="6"
            y="85"
            x="139"
            height="140"
            width="200"
          />
        </g>
        <!-- chart -->
        <g
          stroke-width="2"
          fill="none"
          transform="translate(191,110)"
        >
          <g transform="scale(4)">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </g>
        </g>
        <g stroke="none">
          <!-- hood -->
          <rect
            ry="8"
            y="76"
            x="125"
            height="18"
            width="230"
          />
          <!-- stand circles -->
          <g transform="translate(157,314)">
            <circle
              cx="0"
              cy="0"
              r="9"
            />
            <circle
              cx="83"
              cy="0"
              r="9"
            />
            <circle
              cx="166"
              cy="0"
              r="9"
            />
          </g>
        </g>
        <g
          stroke-width="9"
          fill="none"
        >
          <!-- stand legs -->
          <g transform="translate(240,225)">
            <path d="m0,0v86m0,-40l81,40m-81,-40l-78,40" />
          </g>
          <!-- plus -->
          <g transform="translate(90,240)">
            <path d="m0,0v50m-25,-25h50" />
          </g>
        </g>
      </g>
      <g
        transform="translate(240,420)"
        letter-spacing="2"
      >
        <text
          font-family="Roboto"
          font-size="38px"
          font-weight="bold"
          text-anchor="middle"
          font-style="normal"
          stroke="none"
        >
          <tspan
            v-for="(text,index) in addDeviceOperatorMonitorText.split('\\n')"
            :key="text"
            :y="index * 45"
            x="0"
          >
            {{ text }}
          </tspan>
        </text>
      </g>
    </svg>
    <svg
      :class="geoMonitorClass"
      viewBox="0 0 480 610"
      @click="onClicked('geoMonitor')"
      @mouseover="activeGeoMonitor = true"
      @mouseout="activeGeoMonitor = false"
    >
      <g
        stroke-width="10"
        fill="none"
      >
        <rect
          ry="36"
          y="5"
          x="5"
          height="600"
          width="470"
        />
        <rect
          ry="6"
          y="85"
          x="139"
          height="140"
          width="200"
        />
      </g>
      <!-- marker -->
      <g transform="translate(240,160)">
        <g
          stroke-width="8"
          fill="none"
        >
          <circle r="25" />
          <path d="M0,-25v-12m0,62v12m25-37h12m-60,0h-13" />
        </g>
        <circle
          r="12"
          stroke="none"
        />
      </g>
      <g stroke="none">
        <!-- hood -->
        <rect
          ry="8"
          y="76"
          x="125"
          height="18"
          width="230"
        />
        <!-- stand circles -->
        <g transform="translate(157,314)">
          <circle
            cx="0"
            cy="0"
            r="9"
          />
          <circle
            cx="83"
            cy="0"
            r="9"
          />
          <circle
            cx="166"
            cy="0"
            r="9"
          />
        </g>
      </g>
      <g
        stroke-width="9"
        fill="none"
      >
        <!-- stand legs -->
        <g transform="translate(240,225)">
          <path d="m0,0v86m0,-40l81,40m-81,-40l-78,40" />
        </g>
        <!-- plus -->
        <g transform="translate(90,240)">
          <path d="m0,0v50m-25,-25h50" />
        </g>
      </g>
      <g
        transform="translate(240,420)"
        letter-spacing="2"
      >
        <text
          font-family="Roboto"
          font-size="38px"
          font-weight="bold"
          text-anchor="middle"
          font-style="normal"
          stroke="none"
        >
          <tspan
            v-for="(text,index) in addGeoMonitorText.split('\\n')"
            :key="text"
            :y="index * 45"
            x="0"
          >
            {{ text }}
          </tspan>
        </text>
      </g>
    </svg>
    <svg
      :class="monitorClass"
      viewBox="0 0 480 610"
      @click="onClicked('efficiencyMonitor')"
      @mouseover="activeMonitor = true"
      @mouseout="activeMonitor = false"
    >
      <!-- eslint-disable max-len -->
      <g stroke-width="10">
        <g fill="none">
          <rect
            ry="36"
            y="5"
            x="5"
            height="600"
            width="470"
          />
          <rect
            ry="6"
            y="85"
            x="139"
            height="140"
            width="200"
          />
        </g>
        <!-- chart -->
        <g
          stroke-width="8"
          fill="none"
          width="140"
          height="200"
          transform="translate(179,100)"
        >
          <path d="m 10 80 l 40 -29 l 30 20 l 41 -30" />
        </g>
        <g stroke="none">
          <!-- hood -->
          <rect
            ry="8"
            y="76"
            x="125"
            height="18"
            width="230"
          />
          <!-- stand circles -->
          <g transform="translate(157,314)">
            <circle
              cx="0"
              cy="0"
              r="9"
            />
            <circle
              cx="83"
              cy="0"
              r="9"
            />
            <circle
              cx="166"
              cy="0"
              r="9"
            />
          </g>
        </g>
        <g
          stroke-width="9"
          fill="none"
        >
          <!-- stand legs -->
          <g transform="translate(240,225)">
            <path d="m0,0v86m0,-40l81,40m-81,-40l-78,40" />
          </g>
          <!-- plus -->
          <g transform="translate(90,240)">
            <path d="m0,0v50m-25,-25h50" />
          </g>
        </g>
      </g>
      <g
        transform="translate(240,420)"
        letter-spacing="2"
      >
        <text
          font-family="Roboto"
          font-size="38px"
          font-weight="bold"
          text-anchor="middle"
          font-style="normal"
          stroke="none"
        >
          <tspan
            v-for="(text,index) in addEfficiencyMonitorText.split('\\n')"
            :key="text"
            :y="index * 45"
            x="0"
          >
            {{ text }}
          </tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeMonitor: false,
      activeDeviceOperatorMonitor: false,
      activeGeoMonitor: false,
      activeEfficiencyMonitor: false,
      monitorClass: null,
      deviceOperatorMonitorClass: null,
      geoMonitorClass: null,
    };
  },
  computed: {
    addMonitorText() {
      return t`diagnostics.click-to-add-monitor`;
    },
    addDeviceOperatorMonitorText() {
      return t`diagnostics.click-to-add-device-operator-monitor`;
    },
    addGeoMonitorText() {
      return t`diagnostics.click-to-add-geomonitor`;
    },
    addEfficiencyMonitorText() {
      return t`diagnostics.click-to-add-efficiency-monitor`;
    },
  },
  watch: {
    activeMonitor() {
      this.monitorClass = this.getClasses(this.activeMonitor);
    },
    activeDeviceOperatorMonitor() {
      this.deviceOperatorMonitorClass = this.getClasses(this.activeDeviceOperatorMonitor);
    },
    activeGeoMonitor() {
      this.geoMonitorClass = this.getClasses(this.activeGeoMonitor);
    },
    activeEfficiencyMonitor() {
      this.monitorClass = this.getClasses(this.activeEfficiencyMonitor);
    },
  },
  mounted() {
    this.monitorClass = this.getClasses(false);
    this.deviceOperatorMonitorClass = this.getClasses(false);
    this.geoMonitorClass = this.getClasses(false);
  },
  methods: {
    onClicked(type) {
      this.$emit('click', type);
    },
    getClasses(state) {
      return {
        transitioned: true,
        'add-chart__icon': true,
        'background-contrast-fill': !state,
        'primary-color-fill': state,
        'background-contrast-stroke': !state,
        'primary-color-stroke': state,
      };
    },
  },
};
</script>

<style scoped lang="stylus" src="./AddMonitors.styl"></style>
