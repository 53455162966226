export default {
  name: 'widget-attention',
  steps: [
    {
      id: 1,
      target: '.widget-attention > header',
      content: 'widget.devices-attention.guide.step-1',
      header: {
        title: 'widget.devices-attention.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 2,
      target: '.widget-attention > header',
      content: 'widget.devices-attention.guide.step-2',
      header: {
        title: 'widget.devices-attention.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
  ],
};
