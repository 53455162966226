<template>
  <indicator :state="state">
    <bkf-icon-enabled-time-last-active
      :last-sync="lastSync"
      :state="state"
    />
    <template slot="description">
      <template v-if="state !== null">
        -
      </template>
      <template v-else>
        test
        {{ 'device.indicator.no-data'|trans }}
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    isActive: {
      required: false,
      type: Boolean,
      default: null,
    },
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
  },
  computed: {
    state() {
      const { isActive } = this;

      if (isActive === null || isActive === undefined) {
        return null;
      }

      if (isActive === true) {
        return 'positive';
      }

      return 'negative';
    },
  },
};
</script>
