import deep from './deep';
import format from './format';

// Translations dictionary
let translations = {};

/**
 * Translate message via Translator
 * @param {string} message
 * @param {array} values
 * @returns {string}
 */
export default function trans(message, ...values) {
  if (values.length) {
    return format(deep(translations, message) || message, values);
  }

  return deep(translations, message) || message;
}

/**
 * Translator via tag for string templates
 * @param {array} strings
 * @param {array} values
 * @returns {string}
 */
export function tag(strings, ...values) {
  return trans(strings.reduce((prev, next) => prev.concat(values.shift(), next)));
}

/**
 * Set translations by providing dictionary
 * @param {{}} dictionary
 */
export function setTranslations(dictionary) {
  translations = dictionary;
}
