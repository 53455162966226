import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      settingsUrl: '/users/current/settings',
    };
  },
  computed: {
    ...mapGetters({ userSettings: 'settings/settings' }),
  },
  methods: {
    /**
     * Get setting with given key
     * @param {string} key
     * @returns {*|undefined}
     */
    getSetting(key) {
      return this.$deep(this.userSettings, key);
    },
    /**
     * Set setting with given key
     * @param {string} key
     * @param {*} value
     */
    async setSetting(key, value) {
      const setting = { key, value };
      await this.setUserSettings(setting);
    },
    ...mapActions({ setUserSettings: 'settings/set' }),
  },
};
