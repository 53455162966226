<template>
  <v-row>
    <v-col>
      <div
        ref="dashboard"
        class="page-dashboard"
      >
        <portal to="page-actions">
          <v-btn
            v-if="!$vuetify.breakpoint.mobile"
            tile
            color="primary"
            @click="openSettings"
          >
            {{ 'dashboard.configure-dashboard'|trans }}
          </v-btn>
          <v-btn
            v-else
            icon
            @click="openSettings"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </portal>
        <transition
          name="page-switch"
          mode="out-in"
        >
          <bkf-page
            v-if="isActivePage && loadedSettings"
            :loading="!(loadedPage && loadedSettings)"
            :error="error"
            class="pt-3"
          >
            <div
              v-if="settings.widgets.length"
              class="grid-container"
            >
              <draggable
                v-model="settings.widgets"
                v-bind="$vuetify.breakpoint.mobile ? {delay: 300} : {}"
                :touch-start-threshold="3"
                :handle="dragMobile"
                class="grid"
                @start="drag = true"
                @end="drag = false"
              >
                <component
                  :is="widget"
                  v-for="widget in settings.widgets"
                  :key="widget"
                  class="grid-item header-grab user-selection"
                  :device-groups="deviceGroups"
                  @resize-masonry-item="resizeMasonryItem"
                />
              </draggable>
            </div>
            <configuration-message
              v-else
            />
          </bkf-page>
        </transition>
        <settings
          ref="settings"
          v-model="settings.widgets"
          @submit="onSubmitSettings"
        />
        <bkf-page-settings
          :settings.sync="settings"
          @loaded="onLoadedSettings"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import draggable from 'vuedraggable';
import settingsMixin from '@mixins/misc/settings';
import ActivitySummary from '@components/widgets/ActivitySummary';
import EfficiencySummary from '@components/widgets/EfficiencySummary';
import OperatorSummary from '@components/widgets/OperatorSummary';
import Attention from '@components/widgets/Attention';
import WorkEfficiency from '@components/widgets/EffectiveWork';
import ConfigurationMessage from './ConfigurationMessage';
import Settings from './Settings.vue';

export default {
  components: {
    draggable,
    ActivitySummary,
    Attention,
    ConfigurationMessage,
    EfficiencySummary,
    OperatorSummary,
    WorkEfficiency,
    Settings,
  },
  mixins: [
    pageMixin,
    settingsMixin,
  ],
  data() {
    return {
      loadedSettings: false,
      loadedPage: false,
      // alerts: [],
      deviceGroups: [],
      settings: {
        widgets: [
          'activity-summary',
          'efficiency-summary',
          'operator-summary',
          'work-efficiency',
          'attention',
        ],
      },
    };
  },
  computed: {
    dragMobile() {
      return '.v-toolbar';
    },
  },
  methods: {
    async onActivatePage() {
      await this.loadDeviceGroups();
      // await this.loadAlarms();

      this.loadedPage = true;
    },
    async loadDeviceGroups() {
      const DeviceGroup = await this.$model('DeviceGroup');
      const { entries } = await DeviceGroup.query({
        contexts: ['devicegroups'],
        sort: { name: 'asc' },
      });

      this.deviceGroups = entries.map((entry) => entry.$data);
    },
    // async loadAlarms() {
    //   const alerts = await this.$model('alert');
    //   const { entries } = await alerts.query({});
    //
    //   this.alerts = entries.map((entry) => entry.$data);
    // },
    onLoadedSettings() {
      this.loadedSettings = true;
    },
    onSubmitSettings(widgets) {
      this.settings.widgets = widgets;
    },
    openSettings() {
      this.$refs.settings.open();
    },
    resizeMasonryItem(item) {
      const grid = this.$el.getElementsByClassName('grid')[0];
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
      const rowHeight = parseFloat(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);

      if (item.querySelector('.content')) {
        const rowSpan = Math.ceil(
          (item.querySelector('.content').getBoundingClientRect().height + 60) / (rowHeight + rowGap) + 2,
        );
        item.querySelector('.v-toolbar').style.cursor = 'default';
        item.style.gridRowEnd = `span ${rowSpan}`;
        if (!this.isMobile) {
          item.querySelector('.v-toolbar').style.cursor = 'grab';
        }
      }
    },
    resizeAllMasonryItems() {
      const allItems = this.$el.getElementsByClassName('grid-item');
      allItems.forEach((item) => {
        this.resizeMasonryItem(item);
      });
    },
    addGrabCursor() {
      document.querySelector('.header').style.cursor = 'grab';
    },
  },
};
</script>

<style lang="stylus" scoped src="./Dashboard.styl"></style>
