<template>
  <v-avatar
    :size="size"
    color="teal"
  >
    <img
      v-if="avatar"
      :src="avatar"
    >
    <span
      v-else
      class="white--text"
    >
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object,
    },
    size: {
      required: false,
      type: Number,
      default: 38,
    },
  },
  computed: {
    avatar() {
      return this.user.avatar;
    },
    initials() {
      return `${this.user.firstname} ${this.user.lastname}`.split(' ').map((n) => n[0]).join('');
    },
  },
};
</script>
