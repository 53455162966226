<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="shiftName"
        :label="$trans('shifts.name')"
        hide-details
      />
    </v-col>
    <v-col>
      <v-menu
        :ref="`menuStart${index}`"
        v-model="shiftStartMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="shiftStart"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="shiftStart"
            :label="$trans('shifts.start')"
            readonly
            v-bind="attrs"
            hide-details
            v-on="on"
          />
        </template>
        <v-time-picker
          v-if="shiftStartMenu"
          v-model="shiftStart"
          :allowed-minutes="[0]"
          full-width
          format="24hr"
          @click:hour="$refs[`menuStart${index}`].save(shiftStart)"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        :ref="`menuEnd${index}`"
        v-model="shiftEndMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="shiftEnd"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="shiftEnd"
            :label="$trans('shifts.stop')"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-if="shiftEndMenu"
          v-model="shiftEnd"
          :allowed-minutes="[0]"
          full-width
          format="24hr"
          @click:hour="$refs[`menuEnd${index}`].save(shiftEnd)"
        />
      </v-menu>
    </v-col>
    <v-col>
      <v-btn
        icon
        class="mt-4"
        @click="remove"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
      <v-icon
        v-if="error"
        color="warning"
      >
        warning
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    range: {
      required: true,
      type: Object,
    },
    error: {
      required: false,
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      id: this.range.id,
      shiftName: this.range.name,
      shiftStart: this.range.timeStart,
      shiftEnd: this.range.timeEnd,
      index: this.range.index,
      shiftStartMenu: false,
      shiftEndMenu: false,
    };
  },
  watch: {
    shiftName(shiftName) {
      this.$emit('change', {
        id: this.id,
        index: this.index,
        timeStart: this.shiftStart,
        timeEnd: this.shiftEnd,
        name: shiftName,
      });
    },
    shiftStart(start) {
      this.$emit('change', {
        id: this.id,
        index: this.index,
        timeStart: start,
        timeEnd: this.shiftEnd,
        name: this.shiftName,
      });
    },
    shiftEnd(end) {
      this.$emit('change', {
        id: this.id,
        index: this.index,
        timeStart: this.shiftStart,
        timeEnd: end,
        name: this.shiftName,
      });
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.index);
    },
  },
};
</script>

<style scoped lang="stylus" src="./ShiftRange.styl"></style>
