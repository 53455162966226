<template>
  <v-dialog
    ref="dialog"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="40%"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="error"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="error"
          :disabled="disabled || submitting"
          @click="submit"
        >
          {{ 'dialog.delete'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { or } from 'ramda';

// Http response statuses as states
const states = {
  200: 'submitted',
  404: 'submitError',
};

// Statuses as events
const events = {
  submitting: 'deleting',
  submitted: 'deleted',
  submitError: 'deleteError',
};

/**
 * Convert status to event
 *
 * @param {Number} status Status
 * @returns {String|null} Event or null if not known
 */
const statusToEvent = (status) => or(events[states[status]], null);

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    showDialog: false,
    submitting: false,
    entry: null,
  }),
  methods: {
    /**
     * Open dialog with given entry
     * @param {Object} entry
     */
    open(entry) {
      // Reset state
      this.submitting = false;
      this.entry = entry;
      // Open dialog
      this.showDialog = true;
    },
    /**
     * Close dialog
     */
    close() {
      // Remove reference
      this.entry = null;
      this.showDialog = false;
    },
    /**
     * Submit dialog
     */
    async submit() {
      // Prepare data for submitting
      const { entry } = this;
      const model = entry.$model;
      const url = `/${model.$pluralized}/${entry.id}`;

      // Emit event submitting
      this.submitting = true;
      this.$emit(events.submitting, entry);

      // Submit
      const response = await model.$connector.$client.delete(url);
      // Emit event submitted
      this.close();
      this.$emit(statusToEvent(response.status), { entry, response });
    },
  },
};
</script>
