<template>
  <v-dialog
    ref="modal"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="400"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>
          {{ 'device-operator-group.assign-device-operators'|trans }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-form
          @keyup.native.enter="submit"
        >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="value"
                :label="$trans('device-group.device')"
                :multiple="true"
                :items="devices"
                :item-text="getSelectItemText"
                :item-value="getSelectItemValue"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :disabled="isEmpty"
          @click="submit"
        >
          {{ 'dialog.add'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    devices: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      showDialog: false,
      hasFocus: false,
      value: [],
    };
  },
  computed: {
    isEmpty() {
      return this.value.length === 0;
    },
  },
  methods: {
    focus(state) {
      this.hasFocus = state;
    },
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    async submit() {
      this.$emit('submit', this.value);
      this.value = [];
      this.close();
    },
    getSelectItemText(item) { return item.name; },
    getSelectItemValue: (item) => item.id,
  },
};
</script>
