<template>
  <div>
    <v-container class="d-flex">
      <v-spacer />
      <v-switch
        v-model="confirmedAlertsSwitch"
        label="Pokaż potwierdzone alarmy"
        @change="filterConfirmedAlerts"
      />
    </v-container>
    <v-data-table
      :headers="columns"
      :items="alerts"
      :item-class="rowClass"
      :items-per-page="10"
      @click:row="handleClick"
    >
      <template #[`item.ctime`]="{ item }">
        <bkf-tooltip :content="getImportanceTooltip(item.importance)">
          <bkf-alert />
        </bkf-tooltip>
        {{ item.ctime|date('YYYY-MM-DD HH:mm') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import importances from '@globals/alerts/importances';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmedAlertsSwitch: false,
      alerts: [],
      columns: [{
        value: 'ctime',
        text: t`alert.creation-time`,
      }, {
        value: 'counter',
        text: t`alert.counter`,
      }, {
        value: 'alertRule.name',
        text: t`alert.rule`,
      }],
    };
  },
  computed: {
    ...mapGetters({
      allAlerts: 'alerts/alerts',
    }),
  },
  mounted() {
    this.alerts = this.allAlerts.filter((alert) => alert.device.id === this.value.id);
  },
  methods: {
    getImportanceClass: (level) => importances[level].name,
    getImportanceTooltip: (level) => t`alert.weight.${importances[level].name}`,
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'alert', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
    async filterConfirmedAlerts() {
      const { data } = await this.$http.get(`/alerts?confirmed=${this.confirmedAlertsSwitch}`);
      this.alerts = data.filter((alert) => alert.device.id === this.value.id);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .critical
    fill: #f44336;
  .warning
    fill: #fbc02d;
  .info
    fill: #61a5f4;
</style>
