export default [
  {
    id: '5min',
    name: 'five-minute',
    format: 'YYYY-MM-DD HH:mm',
    trans: 'YYYY-MM-DD HH:mm',
  },
  {
    id: 'hour',
    name: 'hourly',
    format: 'YYYY-MM-DD HH:mm',
    trans: 'YYYY-MM-DD HH:mm',
  },
  {
    id: 'day',
    name: 'daily',
    format: 'YYYY-MM-DD dddd',
    trans: 'YYYY-MM-DD [datetime.day.]dddd',
  },
  {
    id: 'month',
    name: 'monthly',
    format: 'YYYY MMMM',
    trans: 'YYYY-MM-DD [datetime.month.]MMMM',
  },
];
