import { mapActions, mapGetters } from 'vuex';

/**
 * Injects menuVisible property
 */
export default {
  computed: {
    ...mapGetters({
      menuVisible: 'menu/visible',
    }),
  },
  methods: {
    ...mapActions({
      toggleMenu: 'menu/toggle',
      showMenu: 'menu/show',
      hideMenu: 'menu/hide',
    }),
  },
};
