<template>
  <v-text-field
    v-model="bindValue"
    :label="$trans('report.field.name')"
    @input="emitInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      bindValue: '',
      hasFocus: false,
    };
  },
  watch: {
    value() {
      if (this.value === '') {
        this.bindValue = '';
      }
    },
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
