const symbols = {
  key: Symbol('$key'),
  locale: Symbol('$locale'),
  instance: Symbol('$instance'),
};

/**
 * Locale manager
 */
export default class LocaleManager {
  [symbols.key] = null;

  [symbols.locale] = null;

  static [symbols.instance] = null;

  constructor({ key = 'fm-locale' } = {}) {
    this[symbols.key] = key;
    this[symbols.locale] = localStorage.getItem(this[symbols.key]) || window.navigator.language;

    this.setLocale(this[symbols.locale]);
  }

  /**
   * Set locale and write to storage
   * @param {string} locale
   * @return {Locale}
   */
  setLocale(locale) {
    this[symbols.locale] = locale;

    // Set locale in localStorage
    localStorage.setItem(this[symbols.key], locale);

    // Set locales language in special cookie (backend api specific)
    document.cookie = `_locale=${this.$language}`;

    return this;
  }

  /**
   * Get locale string
   * @return {string}
   */
  getLocale() {
    return this[symbols.locale];
  }

  /**
   * Get locale string
   * @return {string}
   */
  get $locale() {
    return this.getLocale();
  }

  /**
   * Get language string
   * @return {string}
   */
  getLanguage() {
    return this[symbols.locale].substring(0, 2);
  }

  /**
   * Get language string
   * @return {string}
   */
  get $language() {
    return this.getLanguage();
  }

  /**
   * Get instance
   * @return {LocalManager}
   */
  static get(payload) {
    LocaleManager[symbols.instance] = LocaleManager[symbols.instance] || new LocaleManager(payload);

    return LocaleManager[symbols.instance];
  }
}
