<template>
  <bkf-widget
    class="widget-attention"
  >
    <template slot="title">
      {{ 'widget.devices-attention.title'|trans }}
    </template>
    <template
      v-if="loaded"
      slot="actions"
    >
      <v-menu
        left
        min-width="250"
      >
        <template #activator="{ on: menu, attrs }">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <span> {{ 'action-button.more'|trans }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item @click="openSettings">
            <v-list-item-icon>
              <v-icon>
                settings
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.settings'|trans }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="startGuide('widget-attention')">
            <v-list-item-icon>
              <v-icon>
                help
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.guide'|trans }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-if="showAlarms">
      <v-data-table
        v-if="loaded"
        :headers="columns"
        :items="devices"
        :item-class="rowClass"
        :items-per-page="10"
        :hide-default-footer="true"
        @click:row="handleClick"
      >
        <template #[`item.alarms`]="{ item }">
          <bkf-tooltip :content="getImportanceTooltip('critical')">
            <bkf-alert-count
              v-if="item.alertsInfo[2]"
              :value="item.alertsInfo[2]"
              type="error"
            />
          </bkf-tooltip>
          <bkf-tooltip :content="getImportanceTooltip('warning')">
            <bkf-alert-count
              v-if="item.alertsInfo[1]"
              :value="item.alertsInfo[1]"
              type="warning"
            />
          </bkf-tooltip>
          <bkf-tooltip :content="getImportanceTooltip('info')">
            <bkf-alert-count
              v-if="item.alertsInfo[0]"
              :value="item.alertsInfo[0]"
              type="info"
            />
          </bkf-tooltip>
        </template>
      </v-data-table>
      <div
        v-else
        class="pv-50 center-align"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </template>
    <bkf-empty-state
      v-else
      :show-button="false"
      :show-widget="false"
      :title="$trans('widget.devices-attention.empty')"
    />
    <settings
      ref="settings"
      v-model="settings"
      @submit="saveSettings"
    />
  </bkf-widget>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  keys, descend, prop, sort,
} from 'ramda';
import ResizeObserver from 'resize-observer-polyfill';
import guideMixin from '@mixins/misc/guide';
import settingsMixin from '@mixins/misc/settings';
import Settings from './Settings.vue';
import guide from './guide';

export default {
  components: {
    Settings,
  },
  mixins: [
    guideMixin,
    settingsMixin,
  ],
  data() {
    return {
      settings: {
        deviceGroups: true,
        criticals: true,
        warnings: true,
        infos: true,
      },
      loaded: false,
      entries: [],
      devices: [],
      columns: [
        {
          value: 'name',
          text: t`device.name`,
          sortable: false,
        },
        {
          value: 'deviceGroups[0].name',
          text: t`device.group`,
          sortable: false,
        },
        {
          value: 'alarms',
          text: t`device.alerts`,
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      alertsInfo: 'alerts/info',
      alertsCount: 'alerts/total',
    }),
    showAlarms() {
      return this.alertsCount > 0;
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          const payload = this.$el;
          this.$emit('resize-masonry-item', payload);
        });
      },
    },
    alertsInfo: {
      deep: true,
      handler() {
        this.generateList();
      },
    },
  },
  async created() {
    this.generateList();
    this.registerGuide(guide);
  },
  mounted() {
    this.$nextTick(() => {
      this.detectComponetResize();
    });
  },
  updated() {
    this.detectComponetResize();
  },
  methods: {
    async generateList() {
      if (this.showAlarms) {
        const model = await this.$model('Device');
        const { entries } = await model.query({
          contexts: ['devices_alarms'],
          filter: keys(this.alertsInfo),
        });

        const settings = await this.getSetting('devices-attention');
        this.settings = settings || this.settings;

        this.devices = entries;
        this.sortDevices();
      }
      this.loaded = true;
    },
    openSettings() {
      this.$refs.settings.open();
    },
    async saveSettings() {
      await this.setSetting('devices-attention', this.settings);
    },
    detectComponetResize() {
      const resizeObserver = new ResizeObserver((items) => {
        this.$emit('resize-masonry-item', items[0].target);
      });
      if (this.$el instanceof Element) {
        resizeObserver.observe(document.querySelector('.widget-attention'));
      }
    },
    sortDevices() {
      this.devices.forEach((device) => {
        device.alertsInfo = this.alertsInfo[device.id];
        device.order = this.calculateOrder(device.alertsInfo);
      });

      this.devices = sort(descend(prop('order')), this.devices);
    },
    calculateOrder(alerts) {
      if (alerts.length !== 3) return 1;
      return (alerts[0] * 1 + alerts[1] * 2 + alerts[2] * 3);
    },
    getImportanceTooltip: (level) => t`alert.weight.${level}`,
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
