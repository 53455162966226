<template>
  <v-app>
    <v-alert
      v-if="(error || exception) && !unauthorized"
      type="error"
      tile
    >
      <div class="title">
        {{ 'error.title'|trans }}
      </div>
      <div class="debug">
        <div class="code">
          {{ 'error.code'|trans }}: {{ error }}
        </div>
        <div class="browser">
          {{ 'error.browser'|trans }}: {{ browser.name }} {{ browser.version }} ({{ browser.os }})
        </div>
        <div class="exception">
          {{ 'error.exception'|trans }}: {{ exception }}
        </div>
      </div>
    </v-alert>
    <div
      v-else-if="initialized"
      class="application"
    >
      <bkf-guide ref="guide" />
      <bkf-v-header
        @toggle-menu="toggleMenu"
        @guide="$refs.guide.start('global')"
      />
      <bkf-v-navmenu
        slot="menu"
        ref="menu"
      />
      <v-main>
        <v-container
          class="bkf-container pa-0"
          fluid
        >
          <bkf-v-breadcrumb />
          <v-col
            cols="12"
            class="py-0"
          >
            <router-view />
          </v-col>
        </v-container>
      </v-main>
    </div>
    <bkf-loader v-else />
  </v-app>
</template>

<script>
import browserMixin from '@mixins/store/browser';
import errorMixin from '@mixins/store/error';
import initalizedMixin from '@mixins/store/initialized';
import unauthorizedMixin from '@mixins/store/unauthorized';

export default {
  mixins: [
    browserMixin,
    errorMixin,
    initalizedMixin,
    unauthorizedMixin,
  ],
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    toggleMenu() {
      this.$refs.menu.toggleMenu();
    },
  },
};
</script>

<style scoped lang="stylus" src="./Application.styl"></style>
