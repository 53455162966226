import timespan from './timespan';

/**
 * Converts seconds or timepsan to human readable format
 *
 * @param {{}|number} payload that can be seconds timespan literal object
 * @param {{}} options like "full" names
 */
export default function human(payload, {
  full = false,
  maxHours = false,
  ceilHours = false,
  labels = {},
} = {}) {
  let data = payload;

  if (payload === null) {
    return '-';
  }

  if (payload !== Object) {
    data = timespan(payload);
  }

  const { days, minutes, seconds } = data;
  let { hours } = data;
  let buffer = '';

  const labelsFinal = {
    ...{
      days: full ? ' days' : 'd',
      hours: full ? ' hours' : 'h',
      minutes: full ? ' minutes' : 'm',
      seconds: full ? ' seconds' : 's',
    },
    ...labels,
  };

  const daysToHours = maxHours || ceilHours;

  if (days) {
    if (daysToHours) {
      hours += days * 24;
    } else {
      buffer = `${days}${labelsFinal.days}`;
    }
  }

  if (ceilHours && (hours || minutes || seconds)) {
    hours += 1;
  }

  if (hours) {
    buffer = `${buffer} ${hours}${labelsFinal.hours}`;
  }

  if (ceilHours) {
    // We just want to show full hours, without minutes or seconds
    return buffer || '-';
  }

  if (minutes) {
    buffer = `${buffer} ${minutes}${labelsFinal.minutes}`;
  }

  if (seconds) {
    buffer = `${buffer} ${seconds}${labelsFinal.seconds}`;
  }

  if (!buffer && seconds === 0) {
    buffer = `${seconds}${labelsFinal.seconds}`;
  }

  return buffer || '-';
}
