<template>
  <v-autocomplete
    :value="value"
    :items="deviceOperatorsGroups"
    :multiple="true"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="$trans('report.field.device-operators-groups')"
    @change="emitInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    deviceOperatorsGroups: {
      required: true,
      type: Array,
    },
  },
  computed: {
    deviceOperatorsGroupsIds() {
      return this.value.map((group) => group.id);
    },
  },
  methods: {
    emitInput(deviceOperatorsGroupsIds) {
      const items = deviceOperatorsGroupsIds.map(
        (id) => this.deviceOperatorsGroups.find((item) => item.id === id),
      );
      this.$emit('input', items);
    },
  },
};
</script>
