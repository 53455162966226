import Action from './Action';
import Add from './Add';
import All from './All';
import ArchiveAll from './ArchiveAll';
import Close from './Close';
import Delete from './Delete';
import DropDown from './DropDown';
import Edit from './Edit';
import Expand from './Expand';
import Export from './Export';
import Filter from './Filter';
import Info from './Info';
import Launch from './Launch';
import More from './More';
import NextPage from './NextPage';
import Options from './Options';
import PreviousPage from './PreviousPage';
import Redirect from './Redirect';
import Refresh from './Refresh';

export default {
  install(Vue) {
    Vue.use(Action);
    Vue.use(Add);
    Vue.use(All);
    Vue.use(ArchiveAll);
    Vue.use(Close);
    Vue.use(Delete);
    Vue.use(DropDown);
    Vue.use(Edit);
    Vue.use(Expand);
    Vue.use(Export);
    Vue.use(Filter);
    Vue.use(Info);
    Vue.use(Launch);
    Vue.use(More);
    Vue.use(NextPage);
    Vue.use(Options);
    Vue.use(PreviousPage);
    Vue.use(Redirect);
    Vue.use(Refresh);
  },
};
