<template>
  <bkf-action
    :tooltip="content"
    @click="$emit('click')"
  >
    <v-icon>
      info_outline
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    content: {
      required: false,
      type: String,
      default: null,
    },
    placement: {
      required: false,
      type: String,
      default: 'bottom',
    },
    trigger: {
      required: false,
      type: String,
      default: 'hover',
    },
    width: {
      required: false,
      type: Number,
      default: 270,
    },
  },
};
</script>
