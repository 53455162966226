import { dasherize, pluralize, underscore } from 'inflection';

export default {
  methods: {
    edit() {
      if (this.$refs.crud) {
        this.$refs.crud.update(this.entry);
      }
    },
    remove() {
      if (this.$refs.crud) {
        this.$refs.crud.delete(this.entry);
      }
    },
    async updated() {
      this.$notify.success(t`${dasherize(underscore(this.model.$name))}.edited`);
      await this.entry.sync();
    },
    deleted() {
      this.$notify.success(t`${dasherize(underscore(this.model.$name))}.deleted`);
      this.$router.push(`/manage/${dasherize(underscore(pluralize(this.model.$name)))}`);
    },
  },
};
