import colors from './colors';
import menu from './menu';
import models from './models';
import oauth from './oauth';
import proxy from './proxy';
import routes from './routes';
import size from './size';

export default {
  colors,
  menu,
  models,
  oauth,
  routes,
  proxy,
  size,
};
