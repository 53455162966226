<template>
  <span class="flex flex-justify-left flex-align-items-center fs-09r">
    <bkf-action-previous-page
      :tooltip="$trans('widget.effective-work.previous-day')"
      @click="previousInterval"
    />
    <span class="widget-effective-work-datetime">
      <v-menu
        v-model="menu"
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="dateString"
            class="centered-input w-120"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateString"
          no-title
          first-day-of-week="1"
          @input="menu2 = false"
        />
      </v-menu>
    </span>
    <bkf-action-next-page
      :tooltip="$trans('widget.effective-work.next-day')"
      @click="nextInterval"
    />
  </span>
</template>

<script>
import {
  addDays, subDays, parse, format,
} from 'date-fns';

export default {
  props: {
    value: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateString: {
      get() {
        return format(this.value, 'YYYY-MM-DD');
      },
      // setter
      set(newValue) {
        this.$emit('input', parse(newValue));
      },
    },
  },
  methods: {
    previousInterval() {
      this.$emit('input', subDays(this.value, 1));
    },
    nextInterval() {
      this.$emit('input', addDays(this.value, 1));
    },
  },
};
</script>

<style scoped lang="stylus">
.centered-input >>> input
  text-align center
</style>
