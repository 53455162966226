<template>
  <v-stepper v-model="stepIndex">
    <v-stepper-header>
      <v-stepper-step
        :complete="stepIndex > 1"
        step="1"
      >
        {{ 'productionview.step1'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="stepIndex > 2"
        step="2"
      >
        {{ 'productionview.step2'|trans }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step step="3">
        {{ 'productionview.step3'|trans }}
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <production-view-configurator-step1
          ref="ProductionViewConfiguratorStep1"
          :prodviewtype="prodviewtype"
          @setstep1values="step1Values"
        />
        <v-row class="mt-4">
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              @click.prevent="nextStep"
            >
              {{ 'productionview.next'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="2">
        <production-view-configurator-step2
          ref="ProductionViewConfiguratorStep2"
          :devices="devices"
          :device-types="deviceTypes"
          @setstep2values="step2Values"
        />
        <v-row class="mt-4">
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              @click.prevent="nextStep"
            >
              {{ 'productionview.next'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click.prevent="previousStep"
            >
              {{ 'productionview.back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <production-view-configurator-step3
          ref="ProductionViewConfiguratorStep3"
          @setstep3values="step3Values"
        />
        <v-row class="mt-4">
          <v-col>
            <v-btn
              tile
              color="primary"
              class="float-right ml-2"
              @click.prevent="nextStep"
            >
              {{ 'productionview.send'|trans }}
            </v-btn>
            <v-btn
              tile
              text
              class="float-right"
              @click.prevent="previousStep"
            >
              {{ 'productionview.back'|trans }}
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductionViewConfiguratorStep1 from './steps/ProductionViewConfiguratorStep1.vue';
import ProductionViewConfiguratorStep2 from './steps/ProductionViewConfiguratorStep2.vue';
import ProductionViewConfiguratorStep3 from './steps/ProductionViewConfiguratorStep3.vue';

export default {
  components: {
    ProductionViewConfiguratorStep1,
    ProductionViewConfiguratorStep2,
    ProductionViewConfiguratorStep3,
  },
  props: {
    prodviewtype: {
      type: Array,
      required: false,
      default: () => [],
    },
    editProdView: {
      type: Boolean,
      default: false,
    },
    editedProductionView: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      stepIndex: 1,
      deviceTypes: [],
      step1: {
        prodviewType: '',
        prodviewName: '',
      },
      step2: {
        lineParams: [],
      },
      step3: {
        resetHours: [],
        file: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      devices: 'devices/devices',
    }),
  },
  mounted() {
    this.getDevicesTypes();
    if (this.editProdView) {
      this.$refs.ProductionViewConfiguratorStep1.setValues(
        this.editedProductionView.name, this.editedProductionView.view_type,
      );
      this.$refs.ProductionViewConfiguratorStep2.setValues(this.editedProductionView.lines);
      this.$refs.ProductionViewConfiguratorStep3.setValues(
        this.editedProductionView.reset_time,
        this.editedProductionView.logo,
      );
    }
  },
  methods: {
    step1Values(prodviewType, prodviewName) {
      this.step1.prodviewType = prodviewType;
      this.step1.prodviewName = prodviewName;
    },
    step2Values(lineParams) {
      this.step2.lineParams = lineParams;
    },
    step3Values(resetHours, file) {
      this.step3.resetHours = resetHours;
      this.step3.file = file;
      const params = {
        prodview_type: this.step1.prodviewType,
        prodview_name: this.step1.prodviewName,
        lineParams: this.step2.lineParams,
        reset_hours: this.step3.resetHours.join('","'),
        file: this.step3.file,
      };
      this.$emit('send-parameters', params);
    },
    changeStep(forward) {
      const stepNumber = this.stepIndex;
      if (stepNumber === 1) { // get step 1 values
        if (this.$refs.ProductionViewConfiguratorStep1.stepTwo()) {
          if (forward) {
            this.stepIndex += 1;
          }
        }
      }
      if (stepNumber === 2) { // get step 2 values
        if (this.$refs.ProductionViewConfiguratorStep2.stepThree()) {
          if (forward) {
            this.stepIndex += 1;
          }
        }
      }
      if (stepNumber === 3) { // get step 3 values
        if (forward) {
          this.$refs.ProductionViewConfiguratorStep3.save();
        }
      }
    },
    nextStep() {
      this.changeStep(true);
    },
    previousStep() {
      this.changeStep(false);
      this.stepIndex -= 1;
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    async getDevicesTypes() {
      const DeviceType = await this.$model('DeviceType');
      const [deviceTypes] = await
      Promise.all([
        DeviceType.query({
          contexts: ['devicetypes_monitor'],
          sort: { name: 'asc' },
        }),
      ]);
      this.deviceTypes = deviceTypes.entries;
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress
  position: absolute
  width: 100%
  height: 100%
  left: 20%
  top: -20%
  transform : translateX(-20%)
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
.form
  margin-bottom 20px
.caption >>> .v-stepper__label
  text-align center
</style>
