<template>
  <v-select
    :value="value ? value.id : null"
    :items="intervals"
    :label="$trans('monitor.interval')"
    item-value="id"
    @change="emitInput"
  >
    <template
      #item="{ item }"
    >
      {{ `monitor.intervals.${item.name}`|trans }}
    </template>
    <template
      #selection="{ item }"
    >
      <span class="text-body-2">{{ `monitor.intervals.${item.name}`|trans }}</span>
    </template>
  </v-select>
</template>

<script>
import settings from '@mixins/misc/settings';
import intervals from '../../internals/intervals';

export default {
  mixins: [
    settings,
  ],
  props: {
    intervals: {
      type: Array,
      required: false,
      default: () => intervals,
    },
    shifts: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      required: true,
      type: Object,
    },
    checkDateRange: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitInput(id) {
      let interval = this.intervals.find((i) => i.id === id);

      if (!interval) {
        interval = this.shifts.find((s) => s.index === id);
      }

      if (interval) {
        this.$emit('input', interval);
      }
    },
  },
};
</script>
