<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="false"
        :error="error"
        class="pt-3"
      >
        <bkf-widget>
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0 pt-7"
            >
              <bkf-action-refresh
                @click="refresh"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0 pt-7"
            >
              <bkf-action-add
                @click="add()"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="!siteLoading"
            :loading="siteLoading"
            :headers="columns"
            :items="administrationDevices"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
          >
            <template #[`item.deviceStatus`]="{ item }">
              <module-activity-indicator
                class="my-2"
                :value="item.deviceStatus.online"
                :last-sync="$deep(item, 'deviceStatus.lastOnline')"
              />
              <battery-voltage-indicator
                v-if="$deep(item.deviceStatus, 'battery') !== null"
                class="my-2"
                :value="item.deviceStatus.battery"
                :battery-status="item.deviceStatus.batteryStatus"
              />
              <operator-last-active-indicator
                v-if="item.deviceStatus.lastWorker"
                :name="$deep(item, 'deviceStatus.lastWorker.name')"
                :active="$deep(item, 'deviceStatus.workerActive')"
                :last-sync="$deep(item, 'deviceStatus.lastWorkerActive')"
              />
              <enabled-time-last-active-indicator
                class="my-2"
                :is-active="item.deviceStatus.active"
                :last-sync="$deep(item, 'deviceStatus.lastActive')"
              />
              <work-time-last-active-indicator
                class="my-2"
                :value="$deep(item, 'deviceStatus.working')"
                :last-work="$deep(item, 'deviceStatus.lastWork')"
                :work-time="$deep(item, 'stats.workTime')"
              />
            </template>

            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>

            <template
              #expanded-item="{ headers, item }"
              class="expand-table"
            >
              <td
                :colspan="headers.length"
                class="py-2"
              >
                <v-card
                  class="px-3 py-3"
                  outlined
                  color="blue-grey lighten-5"
                >
                  <h3 class="mb-3">
                    {{ 'menu.sensors'|trans }}
                  </h3>
                  <v-simple-table
                    v-if="item.sensors.length > 0"
                    dense
                  >
                    <template #default>
                      <tbody>
                        <tr>
                          <td
                            class="text-sm-start"
                          >
                            {{ 'id'|trans }}
                          </td>
                          <td
                            class="text-sm-end"
                          >
                            {{ 'sensor.name'|trans }}
                          </td>
                        </tr>
                        <tr
                          v-for="sensor in item.sensors"
                          :key="sensor.id"
                        >
                          <td class="text-sm-start">
                            {{ sensor.id }}
                          </td>
                          <td class="text-sm-end">
                            {{ sensor.name }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>

                <details-table
                  v-if="item.ai !== null && Object.keys(item.ai).length > 0"
                  :key="`device-ai-${item.id}`"
                  :data="item.ai"
                  :header-text="$trans(`additional-info`)"
                />
              </td>
            </template>
          </v-data-table>
          <edit-device-modal
            ref="device-edit-modal"
            :device-id="editDeviceId"
            :title="$trans('device.edit')"
            @on-change="refresh()"
          />
          <add-device-modal
            ref="device-add-modal"
            :title="$trans('device.add')"
            @on-change="refresh()"
          />
          <v-progress-circular
            v-if="siteLoading"
            color="primary"
            class="mb-8"
            size="60"
            indeterminate
          />
        </bkf-widget>
      </bkf-page>
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import debounce from 'lodash/debounce';
import indicatorsMixin from '@mixins/domain/device/indicators';
import DetailsTable from './tabs/DetailsTable.vue';
import AddDeviceModal from '../../components/dialogs/deviceAdd/AddDeviceModal.vue';
import EditDeviceModal from '../../components/dialogs/deviceEdit/EditDeviceModal.vue';

export default {
  components: {
    DetailsTable,
    AddDeviceModal,
    EditDeviceModal,
  },
  mixins: [
    pageMixin,
    listMixin,
    indicatorsMixin,
  ],
  data() {
    return {
      editDeviceId: null,
      expanded: [],
      siteLoading: true,
      search: null,
      settings: {
        size: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      totalItems: 13,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      administrationDevices: [],
      columns: [
        {
          value: 'id',
          text: t`id`,
        },
        {
          value: 'name',
          text: t`device.name`,
        },
        {
          value: 'owner.name',
          text: t`sensor.owner`,
        },
        {
          value: 'deviceType.name',
          text: t`device.type`,
        },
        {
          value: 'deviceStatus',
          text: t`device.status`,
        },
        {
          value: 'actions',
          text: t`table.edit`,
          align: 'right',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.siteLoading = true;
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  mounted() {
    this.siteLoading = true;
    this.getData();
  },
  methods: {
    async edit(item) {
      this.editDeviceId = item.id;
      this.$refs['device-edit-modal'].showDialog = true;
    },
    async add() {
      this.$refs['device-add-modal'].showDialog = true;
    },
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `admin/devices?${urlParams}`,
      )
        .then(
          (response) => {
            this.administrationDevices = response.data;
            this.totalItems = parseInt(response.headers['x-pager-total-entries'], 10);
            this.siteLoading = false;
          },
        );
    },
    async refresh() {
      await this.getData();
    },
    async onActivatePage() {
      this.siteLoading = true;
      // await this.getData();
    },
  },
};
</script>

<style scoped lang="stylus">
.expand-table
  box-shadow: inset 0 0px 0px 0px !important;
</style>
