<template>
  <span
    v-if="!isNaN(value)"
    :class="colorClass"
  >
    <span class="fw-500">
      {{ plusMinus }}{{ value }}%
    </span>
    <v-icon
      :class="colorClass"
      class="fs-13r"
    >
      {{ icon }}
    </v-icon>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    colorClass() {
      if (this.value < 0) return 'text-red';
      return 'text-green';
    },
    plusMinus() {
      if (this.value > 0) return '+';
      return '';
    },
    icon() {
      if (this.value < 0) return 'trending_down';
      return 'trending_up';
    },
  },
};
</script>

<style scoped>
  .text-green {
    color: #00c853;
  }
  .text-red {
    color: #ff5252;
  }
</style>
