import format from 'date-fns/format';
import parse from 'date-fns/parse';

/**
 * Reformat dateString to given format
 * @param {string} dateString
 * @param {string} formatString
 */
export default function (dateString, formatString = 'YYYY-MM-DD HH:mm') {
  return format(parse(dateString), formatString);
}

/**
 * Reformat timestamp to given format
 * @param {array} dateString
 * @param {array} formatString
 * @returns {string}
 */
export function convertTimestamp(dateString, formatString = 'YYYY-MM-DD HH:mm') {
  return format(parse(dateString * 1000), formatString);
}
