<template>
  <div>
    <v-form
      ref="stepOneForm"
      v-model="valid"
    >
      <v-select
        v-model="prodviewType"
        :items="prodviewtype"
        :label="$trans('productionview.prodviewtype')"
        :rules="typeRule"
        required
        item-text="text"
        item-value="value"
      />
      <v-text-field
        v-model="prodviewName"
        :label="$trans('productionview.name')"
        :rules="nameRule"
        required
      />
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    prodviewtype: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data: () => ({
    prodviewType: [],
    prodviewName: '',
    valid: true,
    typeRule: [
      (v) => !!v || t`productionview.required`,
      (v) => (v && v.length > 0) || t`productionview.required`,
    ],
    nameRule: [
      (v) => !!v || t`productionview.required`,
      (v) => (v && v.length >= 3) || t`productionview.minlength3`,
      (v) => (v && v.length < 128) || t`productionview.maxlength128`,
    ],
  }),
  methods: {
    stepTwo() {
      if (this.$refs.stepOneForm.validate()) {
        this.$emit('setstep1values', this.prodviewType, this.prodviewName);
        return true;
      }
      return false;
    },
    setValues(name, viewType) {
      this.prodviewName = name;
      this.prodviewType = viewType;
    },
  },
};
</script>
