export default function (isMobile) {
  return {
    name: 'widget-activity-summary',
    steps: [
      {
        id: 1,
        target: '.widget-activity-summary > header',
        content: 'widget.activity-summary.guide.step-1',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: 'bottom',
        },
      },
      {
        id: 2,
        target: '.widget-activity-summary .legend > .working',
        content: 'widget.activity-summary.guide.step-2',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: isMobile ? 'bottom' : 'right',
        },
      },
      {
        id: 3,
        target: '.widget-activity-summary .legend > .online',
        content: 'widget.activity-summary.guide.step-3',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: isMobile ? 'bottom' : 'right',
        },
      },
      {
        id: 4,
        target: '.widget-activity-summary .legend > .offline',
        content: 'widget.activity-summary.guide.step-4',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: isMobile ? 'bottom' : 'right',
        },
      },
      {
        id: 5,
        target: '.widget-activity-summary .total',
        content: 'widget.activity-summary.guide.step-5',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: isMobile ? 'bottom' : 'right',
        },
      },
      {
        id: 6,
        target: '.widget-activity-summary .add-info',
        content: 'widget.activity-summary.guide.step-6',
        header: {
          title: 'widget.activity-summary.guide.header',
        },
        params: {
          placement: isMobile ? 'bottom' : 'right',
        },
      },
    ],
  };
}
