<template>
  <v-autocomplete
    :value="value"
    :items="devices"
    :multiple="false"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="$trans('monitor.devices')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Number,
    },
    devices: {
      required: true,
      type: Array,
    },
  },
  methods: {
    /**
     * Emit input current state
     * @param {array} devicesIds Array of devices ids
     */
    emitInput(device) {
      this.$emit('input', device);
    },
  },
};
</script>
