import { mapGetters } from 'vuex';

/**
 * Injects isMobile property
 */
export default {
  computed: {
    ...mapGetters({
      isMobile: 'size/isMobile',
      isTablet: 'size/isTablet',
      isComputer: 'size/isComputer',
      sizeName: 'size/name',
    }),
  },
};
