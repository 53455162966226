export default {
  name: 'widget-effective-work',
  steps: [
    {
      id: 1,
      target: '.widget-effective-work > header',
      content: 'widget.effective-work.guide.step-1',
      header: {
        title: 'widget.effective-work.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 2,
      target: '.widget-effective-work-datetime',
      content: 'widget.effective-work.guide.step-2',
      header: {
        title: 'widget.effective-work.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 3,
      target: '.widget-effective-work-active-label',
      content: 'widget.effective-work.guide.step-3',
      header: {
        title: 'widget.effective-work.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 4,
      target: '.widget-effective-work-working-label',
      content: 'widget.effective-work.guide.step-4',
      header: {
        title: 'widget.effective-work.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 5,
      target: '.widget-effective-work-working-time',
      content: 'widget.effective-work.guide.step-5',
      header: {
        title: 'widget.effective-work.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
  ],
};
