<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <bkf-floating-button-add
          v-if="isMobile"
          :tooltip="$trans('device-operator.create')"
          @click.native="create"
        />
        <v-btn
          v-else
          color="primary"
          tile
          @click.native="create"
        >
          {{ 'device-operator.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="deviceOperators.length">
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0"
            >
              <v-btn
                icon
                class="mt-4"
                @click="$refs.settings.open()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="deviceOperators"
            :item-class="rowClass"
            :items-per-page="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template #[`item.device.name`]="{ item }">
              <device-button
                v-if="item.device"
                :device="item.device"
              />
            </template>
            <template #[`item.diagnostics.workTime`]="{ item }">
              {{ workData(item)|human({ maxHours: true }) }}
            </template>
            <template #[`item.diagnostics.activeTime`]="{ item }">
              {{ activeData(item)|human({ maxHours: true }) }}
            </template>
            <template #[`item.diagnostics.device`]="{ item }">
              <bkf-action-launch
                v-if="showDevice(item)"
                tooltip="device.go-to-device"
                @click="onDeviceClick(item.diagnostics.device)"
              >
                {{ deviceNameById(item.diagnostics.device) }}
              </bkf-action-launch>
            </template>

            <template #[`item.actions`]="{ item }">
              <bkf-action-edit @click="edit(item)" />
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state
          v-else
        />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('device-operator.create')"
      />
      <bkf-page-settings
        ref="pageSettings"
        :settings.sync="settings"
        @loaded="onLoadedSettings"
      />
      <settings
        ref="settings"
        v-model="settings"
        @submit="onSettings"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import Settings from './Settings.vue';

export default {
  components: {
    Settings,
  },
  mixins: [pageMixin, listMixin, settingsMixin, sizeMixin],
  data() {
    return {
      search: '',
      settings: {
        size: 10,
        workInterval: 'month',
      },
    };
  },
  computed: {
    workTimeLabel() {
      const { workInterval } = this.settings;
      return t`device-operator.work-time-in-current-${workInterval}`;
    },
    activeTimeLabel() {
      const { workInterval } = this.settings;
      return t`device-operator.active-time-in-current-${workInterval}`;
    },
    ...mapGetters({
      deviceOperators: 'deviceoperators/deviceOperators',
      deviceNameById: 'devices/deviceNameById',
      deviceById: 'devices/deviceById',
    }),
    columns() {
      return [
        {
          text: t`device-operator.full-name`,
          value: 'name',
        },
        {
          text: t`device-operator.rfid`,
          value: 'rfid',
        },
        {
          text: this.workTimeLabel,
          value: 'diagnostics.workTime',
        },
        {
          text: this.activeTimeLabel,
          value: 'diagnostics.activeTime',
        },
        {
          text: t`device-operator.login-on-device`,
          value: 'diagnostics.device',
        },
        {
          text: t`device-operator.company-name`,
          value: 'company_name',
        },
        {
          name: 'actions',
          value: 'actions',
          align: 'right',
          sortable: false,
          filterable: false,
          exportable: false,
        },
      ];
    },
  },
  methods: {
    async onActivatePage() {
      this.pauseLoading = true;

      await this.initVuex({
        modelName: 'DeviceOperator',
      });
      this.loading = false;
    },
    onDeviceClick(id) {
      this.$router.push({ name: 'device', params: { id } });
    },
    openSettings() {
      this.$refs.settings.open();
    },
    activeData({ diagnostics }) {
      const intervalName = `${this.settings.workInterval}_data`;
      return diagnostics[intervalName] ? diagnostics[intervalName].active : 0;
    },
    workData({ diagnostics }) {
      const intervalName = `${this.settings.workInterval}_data`;
      return diagnostics[intervalName] ? diagnostics[intervalName].work : 0;
    },
    showDevice({ diagnostics }) {
      if (diagnostics.device === null) {
        return false;
      }

      if (!this.deviceById(diagnostics.device)) {
        return false;
      }

      return diagnostics.device !== null;
    },
    async onSettings({ workInterval }) {
      this.settings.workInterval = workInterval;
    },
    onLoadedSettings() {
      const { settings } = this;

      if (settings.workInterval === undefined) {
        this.$set(this.settings, 'workInterval', 'month');
      }
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'device-operator', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
