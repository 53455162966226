/**
 * This mixin provides workflow with Page.vue component
 * Defines necessary data and methods.
 */
export default {
  /**
   * Default data provided by mixin
   * @returns {*}
   */
  data() {
    return {
      // Page loading state (for example data fetching)
      loading: false,
      // Error message (will be showed instead of page)
      error: null,
      // Prevent automatically switch loading state
      pauseLoading: false,
    };
  },
  computed: {
    isActivePage() {
      const { matched } = this.$route;
      const currentRoute = matched[matched.length - 1];

      return currentRoute.instances.default === this;
    },
  },
  watch: {
    $route() {
      this.activatePage();
    },
  },
  created() {
    this.activatePage();
  },
  methods: {
    async activatePage() {
      // Call onActivatePage function if exists in current Page component
      if (this.isActivePage && typeof this.onActivatePage === 'function') {
        this.loading = true;
        try {
          await this.onActivatePage();
        } catch (e) {
          this.$notify.error(e.message);
        } finally {
          if (this.pauseLoading === false) {
            this.loading = false;
          }
        }
      }
    },
  },
};
