/**
 * @param {number} total amount of seconds
 * @return {{}} Literal object with fields: days, hours, minutes, seconds
 */
export default function timespan(total) {
  const days = Math.trunc(total / 86400);
  const hours = Math.trunc((total - (days * 86400)) / 3600);
  const minutes = Math.trunc((total - ((days * 86400) + (hours * 3600))) / 60);
  const seconds = Math.trunc(total % 60);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
