<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <bkf-floating-button-add
          v-if="isMobile"
          :tooltip="$trans('alert-rule.create')"
          :created="created"
          @click.native="configurator"
        />
        <v-btn
          v-else
          tile
          color="primary"
          @click.native="configurator"
        >
          {{ 'alert-rule.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-6"
      >
        <bkf-widget
          v-if="alertRules.length"
          class="mt-n2"
        >
          <v-card-title class="px-0">
            <v-col
              cols="auto"
              class="ml-auto dflex"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="alertRules"
            :item-class="rowClass"
            :items-per-page="settings.size"
            :search="search"
            @click:row="handleClick"
          >
            <template #[`item.device.name`]="{ item }">
              <device-button
                v-if="item.device"
                :device="item.device"
              />
            </template>
            <template #[`item.type`]="{ item }">
              {{ `alert-rule.types.${item.type.split(' ').join('-')}`|trans }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row>
                <v-spacer />
                <v-col
                  class="pr-0"
                >
                  <v-tooltip
                    bottom
                    :activator="$refs['switch_' + item.id]"
                  >
                    <span>{{ switchTooltip(item) }}</span>
                  </v-tooltip>
                  <v-switch
                    :ref="'switch_' + item.id"
                    v-model="item.enabled"
                    dense
                    hide-details
                    class="mt-1 w40"
                    @change="setEnabledFlag(item)"
                    @click.stop
                  />
                </v-col>
                <v-col
                  class="pl-0"
                >
                  <bkf-action-edit @click="edit(item)" />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state
          v-else
          class="mt-n2 pb-4"
        />
      </bkf-page>
      <router-view />
      <bkf-crud
        ref="crud"
        :create-submit-label="$trans('alert-rule.creating')"
        :new-alarm="true"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';

export default {
  mixins: [pageMixin, listMixin, settingsMixin, sizeMixin],
  data() {
    return {
      model: this.model,
      search: '',
      settings: {
        size: 10,
      },
      alertRules: [],
      columns: [
        {
          value: 'name',
          text: t`alert-rule.name`,
        },
        {
          value: 'description',
          text: t`alert-rule.description`,
        },
        {
          value: 'type',
          text: t`alert-rule.alert-type`,
        },
        {
          value: 'actions',
          align: 'right',
          sortable: false,
          filterable: false,
          exportable: false,
        },
      ],
    };
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'AlertRule',
      });
    },
    created(payload) {
      const { response } = payload;
      const { id } = response.data;

      if (response.ok === true) {
        this.$router.push({ name: 'alert-rule', params: { id } });
        this.$notify.success(t`alert-rule.created`);
      }
    },
    async setEnabledFlag(row) {
      const { id, enabled } = row;
      const action = enabled ? 'enable' : 'disable';
      const { status } = await this.$http.patch(`/alertrules/${id}/${action}`);

      if (status === 204) {
        this.$notify.success(enabled ? 'alert.rules.enabled' : 'alert.rules.disabled');
      }
    },
    preventRowClick(e) {
      e.cancelBubble = true;
    },
    switchTooltip(row) {
      if (row.enabled) {
        return this.$trans('alert.rules.tooltip-active');
      }
      return this.$trans('alert.rules.tooltip-deactivate');
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'alert-rule', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>

<style lang="stylus" scoped>
  .top
    z-index 100
  .right
    margin-right 0
  #switch
    margin 0
    padding-right 0
    padding-left 0
    min-width 50px
    overflow visible
    &:hover
      background-color #eee
  .edit
    opacity 0
  .show
    &:hover
      .edit
        opacity 1
</style>
