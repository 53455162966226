var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',{staticClass:"widget-operator-summary"},[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('widget.operator-summary.title'))+"\n  ")]),_vm._v(" "),_c('template',{slot:"actions"},[_c('v-menu',{attrs:{"left":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("sort")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('widget.operator-summary.sort.sort')))])])]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeSort('work','asc')}}},[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.operator-summary.sort.work-asc'))+"\n          ")])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.changeSort('active','asc')}}},[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.operator-summary.sort.active-asc'))+"\n          ")])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.changeSort('work','desc')}}},[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.operator-summary.sort.work-desc'))+"\n          ")])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.changeSort('active','desc')}}},[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.operator-summary.sort.active-desc'))+"\n          ")])],1)],1)],1),_vm._v(" "),_c('v-menu',{attrs:{"left":"","min-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm._f("trans")('action-button.more')))])])]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{on:{"click":_vm.openSettings}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("\n              settings\n            ")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.settings'))+"\n          ")])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.startGuide('widget-operator-summary')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("\n              help\n            ")])],1),_vm._v(" "),_c('v-list-item-title',[_vm._v("\n            "+_vm._s(_vm._f("trans")('widget.guide'))+"\n          ")])],1)],1)],1)],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"progress"},[_c('v-progress-circular',{attrs:{"size":60,"color":"primary","indeterminate":""}})],1):_vm._e(),_vm._v(" "),_c('div',{class:{'onMobile': _vm.isMobile}},[_c('interval',{staticClass:"datepicker mb-10",attrs:{"editable":!_vm.loading,"clearable":false,"placeholder":"","type":"date","value":_vm.date},on:{"input":_vm.setDate}})],1),_vm._v(" "),_c('div',{staticClass:"widget-content"},[(_vm.checkIfEmptyData)?_c('chart',{attrs:{"data":_vm.operators,"counters":_vm.counters,"settings":_vm.settings}}):_c('bkf-empty-state',{attrs:{"show-widget":false,"show-button":false}})],1),_vm._v(" "),(_vm.initialized)?_c('settings',{ref:"settings",attrs:{"device-groups":_vm.deviceGroups},on:{"submit":_vm.saveSettings},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}}):_vm._e(),_vm._v(" "),_c('bkf-refresher',{attrs:{"speed":60,"pause":_vm.loading},on:{"refresh":_vm.refresh}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }