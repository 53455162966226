export default function (items, key) {
  const set = new Set();
  const flatten = (records) => {
    records.forEach((record) => {
      set.add(record);

      if (record[key]) {
        flatten(record[key]);
      }
    });
  };

  flatten(items);

  return [...set];
}
