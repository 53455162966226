import { mapGetters } from 'vuex';

/**
 * Inject property with initialized application state
 */
export default {
  computed: {
    ...mapGetters({
      error: 'error',
      exception: 'exception',
    }),
  },
};
