<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      absolute
      :position-y="65"
      :max-width="340"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mr-2 header-user-button"
          v-on="on"
        >
          <bkf-avatar :user="user" />
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <bkf-avatar :user="user" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ user.firstname }} {{ user.lastname }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list
          nav
          class="pa-0"
        >
          <v-list-item class="mb-0">
            <v-list-item-content class="pa-0">
              <v-btn
                text
                class="normal-text"
                @click.native="$refs.profileSettings.open()"
              >
                {{ 'settings.profile-settings'|trans }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item class="mb-0">
            <v-list-item-content class="pa-0">
              <v-btn
                text
                class="normal-text"
                @click.native="$refs.companySettings.open()"
              >
                {{ 'settings.company-settings'|trans }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            class="mb-0"
          >
            <v-list-item-content class="pa-0">
              <v-btn
                text
                class="normal-text"
                @click="$emit('guide')"
              >
                {{ 'profile.guide'|trans }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <template v-if="user.extras.impersonate">
            <v-list-item
              class="mb-0"
            >
              <v-list-item-content
                class="pa-0"
              >
                <v-btn
                  text
                  class="normal-text"
                  @click.native="$refs.impersonate.impersonate()"
                >
                  {{ 'profile.impersonate'|trans }}
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item
              class="mb-0"
            >
              <v-list-item-content
                class="pa-0"
              >
                <v-list-item-subtitle class="text-caption text-center">
                  {{ 'application.version'|trans }} {{ version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption text-center">
                  Proxy: {{ proxyConnected ? 'połączono' : 'niepołączono' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            @click.native="logout"
          >
            {{ 'profile.logout'|trans }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <Impersonate
      v-if="user.extras.impersonate"
      ref="impersonate"
    />
    <UserSettings ref="profileSettings" />
    <CompanySettings ref="companySettings" />
  </div>
</template>

<script>
import configMixin from '@mixins/store/config';
import userMixin from '@mixins/store/user';
import { mapGetters } from 'vuex';
import Impersonate from './Impersonate';
import UserSettings from './UserSettings';
import CompanySettings from './CompanySettings';

export default {
  components: {
    Impersonate,
    UserSettings,
    CompanySettings,
  },
  mixins: [configMixin, userMixin],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters({
      proxyConnected: 'proxy/connected',
    }),
    version: () => VERSION,
  },
  methods: {
    async logout() {
      localStorage.removeItem('fm-oauth');
      await this.$http.get(this.$url('/api/logout'));

      window.location.assign(this.config.url.login);
    },
  },
};
</script>

<style scoped lang="stylus" src="./ProfileMenu.styl"></style>
