import { tag } from '@utils/trans';

export default {
  /**
   * Register globals to given global variable
   * @param global
   */
  registerTo(global) {
    global.t = tag;
  },
};
