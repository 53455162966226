<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeString"
        readonly
        v-bind="attrs"
        :label="$trans('report.field.date-range')"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="dates"
      range
      no-title
      first-day-of-week="1"
    />
  </v-menu>
</template>

<script>
import { format, isAfter } from 'date-fns';

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
    };
  },
  computed: {
    dateRangeString() {
      return this.dates.join(' - ');
    },
  },
  watch: {
    dates(newValue) {
      if (newValue.length !== 2) return;

      if (isAfter(new Date(newValue[0]), new Date(newValue[1]))) {
        newValue.reverse();
      }

      this.menu = false;
      this.$emit('input', this.dates);
    },
  },
  created() {
    this.dates = this.value.map((date) => format(date, 'YYYY-MM-DD'));
  },
};
</script>
