import sleep from 'es7-sleep';

/**
 * Sleep method plugin
 */
export default {
  install(Vue) {
    Vue.prototype.$bkf.sleep = async (milliseconds) => sleep(milliseconds);
    Vue.prototype.$sleep = Vue.prototype.$bkf.sleep;
  },
};
