import Vue from 'vue';

function copy(source, target, checkExists = true) {
  const keys = Reflect.ownKeys(source);
  keys.forEach((key) => {
    if (!checkExists || Reflect.has(target, key)) {
      Vue.set(target, key, source[key]);
    }
  });
}

/**
 * Copy field names from one object to another plugin
 */
export default {
  install(VueInstance) {
    VueInstance.prototype.$bkf.copy = copy;
    VueInstance.prototype.$copy = copy;
  },
};
