<template>
  <div>
    <table class="w-100p">
      <tr>
        <th class="w-60p left-align ph-16 pv-10 fs-09r">
          {{ 'alert.data.last-occurence-time'|trans }}
        </th>
        <th
          v-if="alert.alertRule.type === 'periodic performance'"
          class="w-40p left-align ph-16 pv-10 fs-09r"
        >
          {{ 'alert.data.value'|trans }}
        </th>
        <th
          v-else-if="alert.alertRule.type !== 'work without operator'
            && alert.alertRule.type !== 'periodic'"
          class="w-40p left-align ph-16 pv-10"
        >
          {{ 'alert.data.value'|trans }}
        </th>
      </tr>
      <tr
        v-for="(row, index) in alarmData"
        :key="index"
      >
        <td class="w-50p ph-16 pv-5">
          {{ convertTimestamp(row.timestamp) }}
        </td>
        <template v-if="alert.alertRule.type === 'work efficiency'">
          <div
            v-for="rowData in row.data"
            :key="rowData.efficiency"
          >
            {{ rowData.efficiency }}%
          </div>
        </template>
        <template v-else-if="alert.alertRule.type === 'periodic performance'">
          <div
            v-for="rowData in row.data"
            :key="rowData.efficiency"
            class="ph-16"
          >
            {{ rowData }}%
          </div>
        </template>
        <template v-else-if="alert.alertRule.type === 'effective work time'">
          <td class="ph-16">
            <div
              v-for="rowData in row.data"
              :key="rowData.seconds"
            >
              {{ Math.ceil(rowData.seconds / 60) }}
            </div>
          </td>
        </template>
        <template v-else-if="alert.alertRule.type === 'lastsync'">
          <td class="ph-16">
            <div
              v-for="rowData in row.data"
              :key="rowData.lastSyncHours"
              class="ph-16"
            >
              {{ rowData.lastSyncHours }}
            </div>
          </td>
        </template>
        <template v-else-if="alert.alertRule.type === 'authentication'">
          <td class="ph-16">
            <div
              v-for="rowData in row.data"
              :key="rowData.rfid"
              class="ph-16"
            >
              {{ rowData }}
            </div>
          </td>
        </template>
        <template v-else-if="alert.alertRule.type === 'part replace'">
          <td class="ph-16">
            {{ convertToFullHours(row.data.value) }} h
          </td>
        </template>
        <template v-else-if="alert.alertRule.type === 'periodic'" />
        <template v-else-if="alert.alertRule.type === 'percentage'">
          <td class="ph-16">
            {{ row.data.value }} %
          </td>
        </template>
        <template v-else>
          <td class="w-50p ph-16 pv-5">
            {{ row.data }}
          </td>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
import { convertTimestamp } from '@utils/date';

export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    alarmData() {
      const reversed = this.data;
      return reversed.reverse().slice(0, 10);
    },
  },
  methods: {
    convertTimestamp: (time) => convertTimestamp(time),
    convertToFullHours: (seconds) => Math.floor(seconds / 3600),
  },
};
</script>

<style lang="stylus" scoped src="./AlertData.styl"></style>
