<template>
  <v-autocomplete
    :value="selectedid"
    :items="devices"
    :multiple="false"
    :chips="false"
    :small-chips="false"
    :deletable-chips="false"
    :item-text="item => item.name ? item.name.slice(0, 30) : `name`"
    item-value="id"
    :label="$trans('monitor.device')"
    :single-line="true"
    :rules="devicesRule"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    devices: {
      required: true,
      type: Array,
    },
    selectedid: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    devicesRule: [
      (v) => !!v || t`productionview.required`,
    ],
  }),
  methods: {
    emitInput(devicesIds) {
      this.$emit('input', [devicesIds]);
    },
  },
};
</script>
