<template>
  <bkf-dialog-entity-delete
    ref="delete"
    :title="title"
    :disabled="!!conditions.length"
    v-on="$listeners"
  >
    <div
      v-if="conditions.length"
      class="w-100p"
    >
      {{ 'device-operator-group.delete-conditions.unresolved'|trans }}:
      <div class="flex flex-justify-center flex-align-items-center mb-20">
        <bkf-action-previous-page
          :disabled="progress"
          @click="previousPage"
        />
        <div class="mt-2">
          {{ 'device-operator-group.delete-conditions.condition-of'|trans(page, pages) }}
        </div>
        <bkf-action-next-page
          :disabled="progress"
          @click="nextPage"
        />
      </div>
      <template v-if="condition.type === 'deviceOperator'">
        {{ 'device-operator-group.delete-conditions.device.message'|trans }}:
        <span class="ml-5 fw-500">{{ condition.deviceOperator.name }}</span>
        <div class="flex flex-justify-center">
          <bkf-tooltip
            :content="$trans('device-operator-group.delete-conditions.device.unassign-tip')"
          >
            <v-btn
              color="primary"
              tile
              :disabled="progress"
              @click="unlinkDeviceOperator(condition.deviceOperator)"
            >
              {{ 'device-operator-group.delete-conditions.device.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
        :class="{active: progress}"
      />
    </div>
    <div v-else>
      {{ 'device-operator-group.delete-confirm'|trans }}?
    </div>
  </bkf-dialog-entity-delete>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      deviceOperators: [],
      deviceOperatorGroup: null,
      progress: false,
    };
  },
  computed: {
    title() {
      return t`device-operator-group.deleting`;
    },
    conditions() {
      const { deviceOperators } = this;
      const conditions = [];

      deviceOperators.forEach((deviceOperator) => {
        conditions.push({
          type: 'deviceOperator',
          deviceOperator,
        });
      });

      return conditions;
    },
    condition() {
      const { conditions } = this;

      if (!conditions.length) {
        return null;
      }

      return conditions[this.index];
    },
    page() {
      return this.index + 1;
    },
    pages() {
      return this.conditions.length;
    },
  },
  methods: {
    open(deviceOperatorGroup, options = {}) {
      this.deviceOperatorGroup = deviceOperatorGroup;
      this.deviceOperators = deviceOperatorGroup.device_operators;
      this.progress = false;
      this.index = 0;
      this.$refs.delete.open(deviceOperatorGroup, options);
    },
    previousPage() {
      this.index = this.index ? this.index - 1 : this.conditions.length - 1;
    },
    nextPage() {
      this.index = this.index === this.conditions.length - 1 ? 0 : this.index + 1;
    },
    async unlinkDeviceOperator(deviceOperator) {
      const { deviceOperatorGroup } = this;
      this.progress = true;
      await this.$http.patch(`/deviceoperatorgroups/${deviceOperatorGroup.id}/deviceoperators/${deviceOperator.id}/unassign`);
      this.index = Math.max(this.index - 1, 0);
      await deviceOperatorGroup.sync();
      this.deviceOperators = deviceOperatorGroup.device_operators;
      this.$notify.success(t`device-group.delete-conditions.device.unassigned`);
      this.$emit('device-operator-unlinked', deviceOperator);
      this.progress = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  opacity: 0
  transition: opacity 300ms linear

.progress.active
  opacity: 1
</style>
