<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
    @input="v => v || submit()"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'alert-rule.assign-devices-and-device-groups'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-radio-group
          v-model="settings.byGroups"
          :mandatory="true"
          row
          dense
        >
          <v-radio
            :label="$trans('alert-rule.device')"
            :value="false"
          />
          <v-radio
            :label="$trans('alert-rule.device-groups')"
            :value="true"
          />
        </v-radio-group>
        <devices-field
          v-show="!settings.byGroups"
          v-model="settings.devices"
          :devices="devices"
        />
        <device-groups-field
          v-show="settings.byGroups"
          v-model="settings.deviceGroups"
          :device-groups="deviceGroups"
        />
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :disabled="isEmpty"
          @click="submit"
        >
          {{ 'dialog.add'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceGroupsField from './Fields/DeviceGroups/DeviceGroups.vue';
import DevicesField from './Fields/Devices/Devices.vue';

export default {
  components: {
    DeviceGroupsField,
    DevicesField,
  },
  props: {
    deviceGroups: {
      required: true,
      type: Array,
    },
    devices: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      showDialog: false,
      settings: {
        devices: [],
        deviceGroups: [],
        byGroups: false,
      },
    };
  },
  computed: {
    isEmpty() {
      return this.settings.devices.length === 0 && this.settings.deviceGroups.length === 0;
    },
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    async submit() {
      await this.$emit('submit', this.settings);
      this.close();
      this.settings = {
        devices: [],
        deviceGroups: [],
        byGroups: false,
      };
    },
  },
};
</script>
