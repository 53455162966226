var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[(_vm.isActivePage)?_c('portal',{attrs:{"to":"page-actions"}}):_vm._e(),_vm._v(" "),(_vm.isActivePage)?_c('bkf-page',{staticClass:"pt-3",attrs:{"loading":false,"error":_vm.error}},[_c('bkf-widget',[_c('v-card-title',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"font-weight-regular",attrs:{"append-icon":"search","label":_vm.$trans('table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{staticClass:"pr-0 pt-7",attrs:{"cols":"auto"}},[_c('bkf-action-refresh',{on:{"click":_vm.refresh}})],1)],1),_vm._v(" "),(!_vm.siteLoading)?_c('v-data-table',{attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.administrationUsers,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"expanded":_vm.expanded,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.rfid",fn:function(ref){
var item = ref.item;
return [(item.rfid !== null)?[_vm._v("\n              "+_vm._s(item.rfid)+"\n            ")]:[_vm._v("\n              -\n            ")]]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("dateTime")(item.lastLogin))+"\n          ")]}},{key:"item.userStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"my-2 d-flex"},[_c('work-time-last-active-indicator',{attrs:{"status":_vm.$deep(item, 'userStatus.lastActiveStatus'),"last-work":_vm.$deep(item, 'userStatus.lastWork'),"work-time":_vm.$deep(item, 'stats.workTime')}}),_vm._v(" "),_c('operator-last-active-indicator',{attrs:{"name":((_vm.$deep(item, 'firstname')) + " " + (_vm.$deep(item, 'lastname'))),"status":_vm.$deep(item, 'userStatus.lastActiveStatus'),"last-sync":_vm.$deep(item, 'userStatus.lastActive')}})],1)]}},{key:"item.trend",fn:function(ref){
var item = ref.item;
return [_c('work-trend-indicator',{attrs:{"value":_vm.$deep(item.stats, 'trend')}})]}},{key:"item.userStatus.lastDevice.name",fn:function(ref){
var item = ref.item;
return [(item.userStatus.lastDevice !==null)?_c('bkf-action-launch',{attrs:{"tooltip":"device.go-to-device"},on:{"click":function($event){return _vm.onDeviceClick(item.userStatus.lastDevice.id)}}},[_vm._v("\n              "+_vm._s(item.userStatus.lastDevice.name)+"\n            ")]):[_vm._v("\n              -\n            ")]]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.siteLoading)?_c('v-progress-circular',{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('router-view'),_vm._v(" "),_c('bkf-crud',{ref:"crud",attrs:{"create-submit-label":_vm.$trans('device.create')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }