<template>
  <v-card
    class="px-3 py-3"
    outlined
    color="blue-grey lighten-5"
  >
    <v-expansion-panels
      v-if="users.length > 0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h4>
            {{ headerText }}: {{ users.length }}
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table
            v-if="users.length > 0"
            dense
          >
            <template #default>
              <thead>
                <tr>
                  <th
                    class="text-sm-start bold"
                  >
                    {{ 'users.id'|trans }}
                  </th>
                  <th
                    class="text-sm-start"
                  >
                    {{ 'users.firstname'|trans }}
                  </th>
                  <th
                    class="text-sm-start"
                  >
                    {{ 'users.lastname'|trans }}
                  </th>
                  <th
                    class="text-sm-end"
                  >
                    {{ 'users.email'|trans }}
                  </th>
                  <th
                    class="text-sm-end"
                  >
                    {{ 'users.last_login'|trans }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in users"
                  :key="user.id"
                >
                  <td class="text-sm-start">
                    {{ user.id }}
                  </td>
                  <td class="text-sm-start">
                    {{ user.firstname }}
                  </td>
                  <td class="text-sm-start">
                    {{ user.lastname }}
                  </td>
                  <td class="text-sm-end">
                    {{ user.email }}
                  </td>
                  <td class="text-sm-end">
                    {{ user.lastLogin|dateTime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

export default {
  name: 'DetailsTableUsers',
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    headerText: {
      type: String,
      default: () => t`fiscal_transactions.details.heading`,
      required: false,
    },
  },
  computed: {
    users() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      return this.data;
    },
  },
};
</script>
