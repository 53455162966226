<template>
  <v-autocomplete
    :value="value"
    :items="parameters"
    chips
    small-chips
    item-text="name"
    item-value="number"
    :label="label"
    @change="emitInput"
  />
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    parameters: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  methods: {
    emitInput(number) {
      const parameter = this.parameters.find((p) => p.number === number);
      this.$emit('input', parameter);
    },
  },
};
</script>
