import { mapGetters } from 'vuex';

/**
 * Injects config property
 */
export default {
  computed: {
    ...mapGetters({
      config: 'config',
    }),
  },
};
