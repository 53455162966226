<template>
  <bkf-icon :class="state">
    <bkf-tooltip :content="name">
      <svg
        viewBox="0 0 32 32"
        width="100%"
      >
        <!-- eslint-disable max-len -->
        <g
          transform="translate(0,30) scale(0.003,-0.003)"
          stroke="none"
        >
          <path
            d="M4725 9799 c-281 -41 -599 -216 -635 -350 -12 -44 -13 -44 -160 -75
              -505 -103 -892 -382 -1156 -830 -162 -275 -265 -637 -310 -1083 -8 -86 -17
              -161 -19 -168 -2 -7 -55 -38 -117 -69 -177 -88 -243 -136 -264 -189 -26 -69
              -15 -144 32 -211 36 -54 36 -54 21 -92 -47 -118 -71 -397 -64 -754 3 -188 10
              -290 22 -358 32 -175 117 -381 204 -497 68 -89 205 -182 310 -208 l44 -12 32
              -99 c69 -210 236 -564 355 -754 490 -779 1202 -1208 1955 -1177 303 13 553 81
              825 224 364 191 673 479 935 873 156 235 273 468 375 750 29 80 56 154 60 166
              6 14 20 23 44 27 53 8 156 62 223 116 95 77 196 239 252 407 54 163 65 276 65
              674 0 384 -6 457 -51 589 l-26 79 26 32 c15 17 33 54 42 82 42 142 1 196 -238
              319 -78 39 -143 72 -143 73 -1 1 -9 71 -18 156 -56 529 -165 883 -366 1190
              -267 407 -626 648 -1110 746 -68 14 -128 27 -132 30 -4 3 -13 24 -20 47 -8 28
              -30 60 -69 98 -99 98 -348 208 -553 244 -95 16 -272 18 -371 4z m331 -410 c32
              -6 101 -27 152 -47 l94 -35 -4 -421 c-3 -407 -4 -423 -25 -469 -29 -63 -123
              -160 -186 -192 -47 -23 -62 -25 -182 -25 -121 0 -135 2 -182 26 -59 29 -160
              131 -188 190 -16 34 -19 77 -26 334 -4 162 -8 352 -8 422 l-1 127 48 21 c129
              59 214 78 348 79 55 1 127 -4 160 -10z m-982 -501 c3 -51 10 -172 16 -268 14
              -242 43 -336 150 -501 28 -41 50 -82 50 -91 0 -9 -24 -30 -55 -47 -70 -40
              -243 -205 -315 -302 -165 -222 -249 -495 -236 -764 10 -192 39 -305 137 -516
              67 -148 72 -165 44 -161 -104 15 -1142 640 -1153 694 -2 9 19 32 48 54 77 58
              87 88 105 309 36 432 95 683 224 951 75 154 143 256 245 362 178 185 386 304
              626 356 114 25 107 29 114 -76z m1791 73 c386 -94 674 -334 856 -714 128 -268
              192 -553 229 -1017 6 -77 17 -148 26 -166 9 -18 40 -52 70 -75 30 -24 54 -49
              54 -56 0 -17 -89 -79 -355 -246 -373 -234 -709 -423 -803 -452 -22 -6 -23 -5
              -17 17 4 12 31 75 61 139 106 231 137 359 137 569 0 252 -65 467 -206 677 -68
              102 -243 279 -334 337 -40 26 -73 49 -73 52 0 3 23 39 51 80 69 103 115 204
              133 295 18 86 36 327 36 477 l0 102 28 0 c15 0 63 -9 107 -19z m-723 -1229
              c100 -27 160 -58 253 -133 150 -121 248 -270 296 -449 32 -119 33 -292 1 -403
              -116 -408 -523 -669 -927 -592 -310 58 -559 286 -647 592 -32 111 -32 305 0
              416 83 288 319 519 597 583 88 20 327 12 427 -14z m-2364 -1351 c699 -442 876
              -527 1242 -601 191 -39 375 -49 885 -49 513 0 694 10 884 49 329 67 506 146
              971 433 423 261 554 336 568 323 13 -14 23 -321 18 -543 -7 -262 -17 -359 -50
              -454 -29 -84 -111 -213 -131 -205 -8 3 -55 8 -104 12 -76 6 -93 4 -120 -12
              -50 -29 -105 -121 -131 -215 -142 -527 -403 -986 -756 -1329 -247 -240 -504
              -393 -782 -465 -192 -51 -511 -53 -710 -5 -432 104 -838 412 -1141 865 -169
              252 -262 452 -386 825 -73 220 -80 238 -119 275 -60 59 -142 79 -215 54 -17
              -6 -43 -8 -57 -5 -36 9 -95 107 -133 221 -45 135 -55 269 -48 647 5 297 11
              348 38 348 6 0 131 -76 277 -169z"
          />
          <path
            d="M2826 3369 c-293 -98 -1639 -652 -1847 -761 -135 -69 -373 -286 -468
              -426 -44 -65 -132 -238 -161 -315 -33 -89 -85 -323 -198 -892 -163 -819 -158
              -790 -138 -831 9 -19 37 -57 62 -85 l47 -49 4776 0 4776 0 38 31 c20 17 49 51
              63 75 27 45 27 46 14 137 -34 258 -203 1105 -296 1487 -43 173 -79 257 -180
              407 -121 182 -306 363 -457 447 -149 83 -1436 616 -1851 767 l-119 43 -41 -22
              c-23 -12 -107 -81 -186 -153 -388 -351 -660 -520 -1040 -648 -254 -86 -412
              -111 -695 -111 -435 0 -695 63 -1085 260 -237 121 -419 251 -670 481 -219 200
              -219 200 -344 158z m110 -501 c272 -245 457 -374 733 -512 299 -150 641 -254
              941 -287 131 -14 516 -6 691 15 263 32 566 132 864 284 233 120 434 263 746
              531 l39 34 0 -1241 c0 -682 -3 -1247 -6 -1256 -6 -15 -195 -16 -2045 -16
              l-2039 0 0 1163 c0 640 -3 1205 -6 1255 -5 57 -3 92 3 92 5 0 41 -28 79 -62z
              m-493 -200 c4 -62 7 -596 7 -1185 l0 -1073 -200 0 -200 0 0 1108 0 1108 43 16
              c23 9 107 44 187 77 80 33 148 61 151 61 3 0 9 -51 12 -112z m5135 31 l182
              -73 0 -1108 0 -1108 -200 0 -200 0 0 1103 c0 1106 3 1272 24 1264 6 -3 93 -38
              194 -78z m-5963 -286 c3 -16 9 -473 12 -1016 l5 -989 -580 4 c-319 1 -583 6
              -587 9 -8 9 173 916 225 1124 46 188 86 294 146 387 53 82 187 219 269 273 73
              48 456 229 496 234 4 1 10 -11 14 -26z m6759 -43 c144 -60 273 -126 341 -175
              62 -44 194 -174 241 -235 42 -56 100 -187 134 -302 54 -187 267 -1224 254
              -1237 -3 -3 -267 -8 -586 -9 l-580 -4 4 1004 c2 551 7 1008 11 1015 9 14 27 8
              181 -57z"
          />
        </g>
      </svg>
    </bkf-tooltip>
  </bkf-icon>
</template>

<script>
export default {
  props: {
    state: {
      default: null,
      required: false,
      type: String,
    },
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
    name: {
      default: null,
      required: false,
      type: String,
    },
  },
};
</script>
