<template>
  <v-card
    tile
    :flat="flat"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
