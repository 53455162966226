<template>
  <bkf-form
    v-if="show"
    ref="form"
    class="form"
    :model="model"
    :entry="entry"
    :options="options"
    @fetching="onFetching"
    @fetched="onFetched"
    @fetchError="onFetchError"
    @submitting="onSubmitting"
    @submitted="onSubmitted"
    @submitError="onSubmitError"
    @validate-error="onValidateError"
  />
</template>

<script>
export default {
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    model: null,
    entry: null,
    show: false,
    fetching: false,
    entries: [],
    conditions: [],
    config: {
      multiSelect: false,
    },
  }),
  computed: {
    pluralized() {
      return `alertrules/${this.alertRule.id}/conditions`;
    },
    options() {
      return {
        pluralized: this.pluralized,
        model: this.model,
        type: this.type,
      };
    },
  },
  created() {
    if (this.edit) {
      const { conditions } = this.alertRule;
      const [condition] = conditions;
      this.entry = condition;
      this.model = this.alertRule.$model;
      this.$emit('editing');
    } else {
      this.model = this.alertRule.$model;
      this.conditions = this.alertRule.$data.conditions;
    }
  },
  mounted() {
    this.fetching = true;
    this.show = true;
  },
  methods: {
    onFetching() {
      this.fetching = true;
      this.$emit('fetching');
    },
    onFetched() {
      this.fetching = false;
      this.$emit('fetched');
    },
    onFetchError(payload) {
      this.fetching = false;
      this.show = false;
      this.$emit('fetch-error', payload);
    },
    onSubmitting() {
      this.submitting = true;
    },
    async onSubmitted(payload) {
      await this.alertRule.sync();
      this.$emit('submitted', payload);
    },
    async onSubmitError(payload) {
      this.$emit('create-error', payload);
    },
    onValidateError() {
      this.submitting = false;
      this.$emit('validate-error');
    },
  },
};
</script>
