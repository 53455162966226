// Own components
import Actions from './actions';
import Application from './application';
import Cards from './cards';
import Dialogs from './dialogs';
import Forms from './forms';
import Icons from './icons';
import Monitors from './monitors';
import Navigation from './navigation';
import Refreshers from './refreshers';
import Settings from './settings';
import Tooltips from './tooltips';
import User from './user';
import Widgets from './widgets';
import Accordions from './accordions';
import EmptyState from './emptyState';

/**
 * Install global components
 */
export default {
  install: (Vue) => {
    // Own components
    Vue.use(Actions);
    Vue.use(Application);
    Vue.use(Cards);
    Vue.use(Dialogs);
    Vue.use(Forms);
    Vue.use(Icons);
    Vue.use(Monitors);
    Vue.use(Navigation);
    Vue.use(Refreshers);
    Vue.use(Settings);
    Vue.use(Tooltips);
    Vue.use(User);
    Vue.use(Widgets);
    Vue.use(Accordions);
    Vue.use(EmptyState);
  },
};
