<template>
  <v-row>
    <v-col>
      <transition
        name="page-switch"
        mode="out-in"
      >
        <bkf-page
          v-if="isActivePage"
          :loading="loading"
          :error="error"
          class="pt-3"
        >
          <bkf-widget
            v-if="sensors.length"
            class="pt-4"
          >
            <v-card-title>
              <v-col
                cols="auto"
                class="ml-auto"
              >
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$trans('table.search')"
                  single-line
                  hide-details
                  class="font-weight-regular"
                />
              </v-col>
            </v-card-title>
            <v-data-table
              :headers="columns"
              :items="sensors"
              :item-class="rowClass"
              :items-per-page="settings.size"
              :search="search"
              @click:row="handleClick"
            >
              <template #[`item.device.name`]="{ item }">
                <device-button
                  v-if="item.device"
                  :device="item.device"
                />
              </template>
              <template #[`item.active`]="{ item }">
                <module-activity-indicator
                  class="py-2"
                  :value="$deep(item, 'active')"
                  :last-sync="$deep(item, 'lastSynchroTime')"
                />
              </template>
              <template #[`item.actions`]="{ item }">
                <bkf-action-edit @click="edit(item)" />
              </template>
            </v-data-table>
          </bkf-widget>
          <bkf-empty-state v-else />
        </bkf-page>
        <router-view v-else />
      </transition>
      <bkf-crud
        ref="crud"
      />
      <bkf-page-settings :settings.sync="settings" />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import settingsMixin from '@mixins/misc/settings';
import buttonsMixin from '@mixins/domain/device/buttons';
import indicatorsMixin from '@mixins/domain/device/indicators';

export default {
  components: {
  },
  mixins: [
    indicatorsMixin,
    buttonsMixin,
    pageMixin,
    listMixin,
    settingsMixin,
  ],
  data() {
    return {
      search: '',
      settings: {
        size: 10,
      },
      sensors: [],
      columns: [
        {
          value: 'sensorNumber',
          text: t`sensor.serial-number`,
          filtrable: true,
        },
        {
          value: 'name',
          text: t`sensor.name`,
          sort(a, b) {
            return -1 * a.localeCompare(b, 'pl');
          },
        },
        {
          value: 'device.name',
          text: t`sensor.assigned-to-device`,
          sort(a, b) {
            if (a === null) {
              return -1;
            }

            if (b === null) {
              return 1;
            }

            const name1 = a.name.toLowerCase();
            const name2 = b.name.toLowerCase();

            if (name1 < name2) {
              return -1;
            } if (name1 > name2) {
              return 1;
            }

            return 0;
          },
          exportBy: (device) => (device === null ? '' : device.name),
        },
        {
          value: 'sim_card',
          text: t`sensor.sim-card-number`,
          sort(a, b) {
            if (a === null) {
              return -1;
            }

            if (b === null) {
              return 1;
            }

            const name1 = a.toLowerCase();
            const name2 = b.toLowerCase();

            if (name1 < name2) {
              return -1;
            } if (name1 > name2) {
              return 1;
            }

            return 0;
          },
        },
        {
          value: 'active',
          text: t`sensor.active`,
          align: 'center',
          sort(a, b) {
            if (a < b) {
              return -1;
            } if (a > b) {
              return 1;
            }

            return 0;
          },
          exportBy: (active) => (active ? this.$trans('yes') : this.$trans('no')),
        },
        {
          value: 'actions',
          text: t`table.edit`,
          align: 'right',
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  methods: {
    async onActivatePage() {
      await this.init({
        modelName: 'Sensor',
      });
    },
    handleClick(row) {
      const { id } = row;
      this.$router.push({ name: 'sensor', params: { id } });
    },
    rowClass() {
      return 'clickable';
    },
  },
};
</script>
