<template>
  <div>
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        v-if="!$vuetify.breakpoint.mobile"
        :options="filteredDevices"
        placeholder="device.go-to-device"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div v-if="device2">
        <div class="flex flex-wrap flex-justify-space-between flex-align-end">
          <bkf-widget style="flex-basis: 30%">
            <template slot="title">
              {{ 'device.details'|trans }}
            </template>
            <template slot="actions">
              <bkf-action-edit
                @click="edit"
              />
              <bkf-action-delete
                :tooltip="$trans('action-button.assign')"
                @click="remove"
              />
            </template>
            <v-list dense>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.name'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device2.name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.description'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device2.description || '-' }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="device2.technical_status"
                class="hmin-30"
              >
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.technical-status'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ `device.statuses.${device2.technical_status}`|trans }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.group'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <template v-if="device2.deviceGroups && device2.deviceGroups.length">
                    <span
                      v-for="group in device2.deviceGroups"
                      :key="group.id"
                      class="flex flex-wrap"
                    >
                      <bkf-action-launch
                        tooltip="device.go-to-device-group"
                        @click="redirectToDeviceGroup(group.id)"
                      >
                        {{ group.name }}
                      </bkf-action-launch>
                    </span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.type'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ device.deviceType.name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.operator'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <span v-if="device2.deviceStatus.lastWorker">
                    <bkf-action-launch
                      tooltip="device.go-to-operator"
                      @click="redirectToOperator(device2.deviceStatus.lastWorker.id)"
                    >
                      {{ device2.deviceStatus.lastWorker.name }}
                    </bkf-action-launch>
                  </span>
                  <span v-else>
                    -
                  </span>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.last-sync'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  <span v-if="device2.sensors.length">
                    <template v-if="device2.deviceStatus.lastOnline">
                      {{ device2.deviceStatus.lastOnline|dateTime }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                  <span v-else>
                    -
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device.sensors'|trans }}:
                </v-list-item-content>
                <v-list-item-content>
                  <bkf-action-launch
                    v-for="sensor in device2.sensors"
                    :key="sensor.id"
                    tooltip="device.go-to-operator"
                    @click="redirectToSensor(sensor.id)"
                  >
                    {{ sensor.name }}
                  </bkf-action-launch>
                </v-list-item-content>
              </v-list-item>
<!--              <v-list-item v-if="device2.deviceType.id === 468">-->
<!--                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">-->
<!--                  {{ 'device.vts-application-version'|trans }}:-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content class="flex-grow-1 text-body-2 py-1">-->
<!--                  {{ device.sensors[0].software_revision.software || '-' }}-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--              <v-list-item v-if="device2.deviceType.id === 468">-->
<!--                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">-->
<!--                  {{ 'device.vts-software-version'|trans }}:-->
<!--                </v-list-item-content>-->
<!--                <v-list-item-content class="flex-grow-1 text-body-2 py-1">-->
<!--                  {{ device.sensors[0].software_revision.hardware || '-' }}-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
            </v-list>
          </bkf-widget>

          <work-summary
            :device="device"
            class="ml-3"
            style="flex-basis: 30%"
          />

          <technical-parameters
            :device="device"
            class="ml-3"
            style="flex-basis: 30%"
            @refresh="refreshDevice"
          />

          <bkf-widget
            v-if="!isMobile"
            class="mt-3"
          >
            <v-tabs
              @change="tabOpened"
            >
              <v-tab>
                {{ 'device.work-time'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-work
                  v-if="!isMobile && device"
                  :device="device"
                />
                <p v-else>
                  {{ 'device.hide-on-mobile'|trans }}
                </p>
              </v-tab-item>

              <v-tab>
                {{ 'device.alerts'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-alerts v-model="device" />
              </v-tab-item>
              <v-tab>
                {{ 'device.diagnostics'|trans }}
              </v-tab>
              <v-tab-item>
                <bkf-monitor
                  v-if="!isMobile"
                  id="device_monitor"
                  :show-title="false"
                  :show-actions="false"
                  :show-devices="false"
                  :width="tabWidth - 100"
                  :devices="oneDevice"
                  :device-groups="deviceGroups"
                  :shifts="shifts"
                  :monitor="monitor"
                  :device-types="deviceTypes"
                  class="mb-4"
                  :flat="true"
                />
              </v-tab-item>

              <v-tab>
                {{ 'device.gps-position'|trans }}
              </v-tab>
              <v-tab-item>
                <bkf-geo-monitor
                  v-if="!isMobile && showGpsPosition"
                  :show-title="false"
                  :show-actions="false"
                  :show-devices="false"
                  :width="tabWidth - 100"
                  :devices="oneDevice"
                  :device-groups="deviceGroups"
                  :monitor="geoMonitor"
                  :flat="true"
                />
              </v-tab-item>
              <v-tab>
                {{ 'device.work-history'|trans }}
              </v-tab>
              <v-tab-item>
                <tab-history
                  :device="device"
                />
              </v-tab-item>
              <v-tab>
                {{ 'device.required-work.title'|trans }}
              </v-tab>
              <v-tab-item>
                <required-work
                  :device="device"
                  @refresh="refreshDevice"
                />
              </v-tab-item>
            </v-tabs>
          </bkf-widget>
        </div>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
      @deleted="deleted"
    >
      <delete
        slot="delete"
        @deleted="deleted"
      />
    </bkf-crud>
  </div>
</template>

<script>
import indicatorsMixin from '@mixins/domain/device/indicators';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import sizeMixin from '@mixins/store/size';
import settingsMixin from '@mixins/misc/settings';
import { mapGetters } from 'vuex';
import Delete from './Delete.vue';
import TabAlerts from './tabs/Alerts';
import TabWork from './tabs/Work';
import TabHistory from './tabs/History';
import WorkSummary from './WorkSummary.vue';
import RequiredWork from './tabs/RequiredWork';
import TechnicalParameters from './TechnicalParameters.vue';

export default {
  components: {
    Delete,
    TabAlerts,
    TabWork,
    WorkSummary,
    RequiredWork,
    TabHistory,
    TechnicalParameters,
  },
  mixins: [
    indicatorsMixin,
    detailsMixin,
    pageMixin,
    propsMixin,
    sizeMixin,
    settingsMixin,
  ],
  data() {
    return {
      model: null,
      entry: null,
      device: null,
      device2: null,
      oneDevice: [],
      deviceGroups: [],
      showGpsPosition: false,
      // filteredDevices: [],
      deviceTypes: [],
      powerPending: false,
      tabWidth: 100,
      shifts: [],
    };
  },
  computed: {
    monitor() {
      // Fake monitor object for Monitor component
      return {
        id: 0,
        type: 'monitor',
        params: {
          devices: [this.device.id],
          deviceGroups: [],
          parameter: null,
          parameters: [],
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
    geoMonitor() {
      return {
        id: 'device-geomonitor',
        type: 'geoMonitor',
        params: {
          devices: [this.device.id],
          deviceGroups: [],
          edited: true,
          expanded: true,
        },
        save() {},
      };
    },
    ...mapGetters({
      devices: 'devices/devices',
    }),
    filteredDevices() {
      return this.device === null ? []
        : this.devices.filter((device) => device.id !== this.device.id);
    },
  },
  async mounted() {
    await this.getData();
    const userSettings = await this.getSetting('user-settings');

    if (userSettings) {
      this.shifts = userSettings.shifts;
    }
  },
  methods: {
    getData() {
      this.siteLoading = true;
      this.$http.get(
        `/user/device/${this.id}`,
      )
        .then(
          (response) => {
            this.device2 = response.data;
            this.siteLoading = false;
          },
        );
      this.siteLoading = false;
    },
    async onActivatePage() {
      this.map = null;
      this.model = await this.$model('Device');
      this.entry = await this.model.get({
        id: this.id,
        contexts: ['device_details'],
      });
      this.device = this.entry.$data;
      // this.getData();
      //
      // console.log('model', this.model);
      // console.log('device', this.device);

      // console.log('test', this.device);
      this.deviceTypes = [
        {
          id: this.device.deviceType.id,
          parameters: this.device.deviceType.parameters,
        },
      ];
      this.oneDevice = [this.device];
    },
    async tabOpened(tab) {
      if (tab === 3) {
        await this.$sleep(200);
        this.showGpsPosition = true;
      }
    },
    redirectToOperator(id) {
      this.$router.push({ name: 'device-operator', params: { id } });
    },
    redirectToSensor(id) {
      this.$router.push({ name: 'sensor', params: { id } });
    },
    redirectToDeviceGroup(id) {
      this.$router.push({ name: 'device-group', params: { id } });
    },
    async refreshDevice() {
      await this.getData();
      await this.entry.sync();
    },
  },
};
</script>

<style lang="stylus">
#map-device-geomonitor
  height: 300px
#device-monitor
  box-shadow: none
</style>
