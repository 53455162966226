<template>
  <bkf-action
    :tooltip="tooltip"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <v-icon>
      search
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    tooltip: {
      required: false,
      type: String,
      default: 'action-button.filter',
    },
    disabled: {
      required: false,
      type: Boolean,
    },
  },
};
</script>
