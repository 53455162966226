import { throttle } from 'throttle-debounce';

export default {
  namespaced: true,
  state: {
    sizes: [
      { name: 'xxsmall', width: 480 },
      { name: 'xsmall', width: 600 },
      { name: 'small', width: 960 },
      { name: 'medium', width: 1280 },
      { name: 'large', width: 1920 },
      { name: 'xlarge', width: 99999 },
    ],
    name: null,
    width: null,
    mobile: false,
  },
  getters: {
    sizes: (s) => s.sizes,
    name: (s) => s.name,
    width: (s) => s.width,
    isMobile: (s) => ['xxsmall', 'xsmall', 'small'].includes(s.name),
    isTablet: (s) => ['xsmall', 'small'].includes(s.name),
    isComputer: (s, g) => !(g.isTablet || g.isMobile),
  },
  mutations: {
    SIZE_UPDATE(state, { name, width }) {
      state.name = name;
      state.width = width;
    },
  },
  actions: {
    init({ commit, getters }) {
      const find = (value) => getters.sizes.find((size) => value < size.width);

      // Get size on init
      commit('SIZE_UPDATE', find(window.innerWidth));

      window.onresize = throttle(17, ({ target }) => {
        commit('SIZE_UPDATE', find(target.innerWidth));
      });
    },
  },
};
