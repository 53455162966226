<template>
  <v-tooltip
    v-if="tooltip"
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        class="bkf-action-button"
        :class="{'mt-4': exporter}"
        icon
        v-bind="attrs"
        @click.native.stop="$emit(event)"
        @mouseover.native="hover = true"
        @mouseout.native="hover = false"
        v-on="on"
      >
        <slot />
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
  <v-btn
    v-else
    :disabled="disabled"
    class="bkf-action-button"
    @mouseover.native="hover = true"
    @mouseout.native="hover = false"
    @click.native.stop="$emit(event)"
  >
    <slot />
  </v-btn>
</template>

<script>

export default {
  props: {
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
    event: {
      type: String,
      required: false,
      default: 'click',
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    exporter: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style scoped lang="stylus" src="./Action.styl"></style>
