// Alarm conditions
export default {
  '==': { name: 'equal' },
  '>': { name: 'greater-than' },
  '<': { name: 'less-than' },
  '>=': { name: 'greater-or-equal-to' },
  '<=': { name: 'less-or-equal-to' },
  '!=': { name: 'not-equal-to' },
  value: { name: 'value' },
  month: { name: 'month' },
  week: { name: 'week' },
  day: { name: 'day' },
  '12h': { name: '12h' },
  '6h': { name: '6h' },
  '3h': { name: '3h' },
  '2h': { name: '2h' },
  '60m': { name: '60m' },
  '30m': { name: '30m' },
  '15m': { name: '15m' },
  '10m': { name: '10m' },
};
