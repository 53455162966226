<template>
  <indicator>
    <bkf-icon-location
      :last-sync="lastSync"
      :state="state"
    />
    <template slot="description">
      <span> - </span>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
    status: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    state() {
      return `icon-${this.status}`;
    },
  },
};
</script>
