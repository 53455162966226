<template>
  <div class="device-tab-work mb-4">
    <template v-if="!loading">
      <v-data-table
        v-if="history.length"
        :headers="columns"
        :items="history"
        :items-per-page="10"
      >
        <template #[`item.active`]="{ item }">
          <enabled-time-indicator
            :value="item.active"
          />
        </template>
        <template #[`item.work`]="{ item }">
          <work-time-indicator
            :value="item.work"
          />
        </template>
      </v-data-table>
      <p
        v-else
        class="pv-50 center-align"
      >
        {{ 'sensor.no-data'|trans }}
      </p>
    </template>
    <div
      v-else
      name="fade"
      class="pv-50 mv-50 center-align"
    >
      <div
        v-if="loading"
        class="progress"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </div>
  </div>
</template>

<script>
import indicatorsMixin from '@mixins/domain/device/indicators';

export default {
  mixins: [indicatorsMixin],
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          value: 'operator',
          text: t`history.operator`,
        },
        {
          value: 'begin',
          text: t`history.start`,
        },
        {
          value: 'end',
          text: t`history.end`,
        },
        {
          value: 'active',
          text: t`history.active`,
        },
        {
          value: 'work',
          text: t`history.work`,
        },
      ],
      history: [],
      loading: true,
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const url = `/devices/history/${this.device.id}`;
      const { data, status } = await this.$http.get(url);

      if (status === 200) {
        this.history = data;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress
  padding: 100
  position: absolute
  width: 100%
  height: 100%
  left: 20%
  top: -20%
  transform : translateX(-20%)
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
