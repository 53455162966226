/**
 * Availablee mutations
 */
export const mutations = {
  VISIBILITY_SET: 'MENU_VISIBILITY_SET',
};

/**
 * Available actions
 */
export const actions = {
  hide: 'hide',
  show: 'show',
  toggle: 'toggle',
};

export default {
  namespaced: true,
  state: {
    visible: true,
  },
  getters: {
    visible: (s) => s.visible,
  },
  mutations: {
    [mutations.VISIBILITY_SET](state, { visible }) {
      state.visible = visible;
    },
  },
  actions: {
    /**
     * Hide menu
     */
    [actions.hide]({ commit }) {
      commit(mutations.VISIBILITY_SET, { visible: false });
    },
    /**
     * Show menu
     */
    [actions.show]({ commit }) {
      commit(mutations.VISIBILITY_SET, { visible: true });
    },
    /**
     * Toggle menu
     */
    [actions.toggle]({ commit, getters }) {
      commit(mutations.VISIBILITY_SET, { visible: !getters.visible });
    },
  },
};
