import LocaleManager from '@services/locale';

export default {
  methods: {
    translatedParameterName(parameter) {
      if (parameter.name === undefined) {
        return false;
      }

      const localeManager = LocaleManager.get();
      const lang = localeManager.getLanguage();

      if (lang === 'pl') {
        return parameter.name;
      }

      if (parameter.name_translations[lang]) {
        return parameter.name_translations[lang];
      }

      return parameter.name_translations.en ? parameter.name_translations.en : parameter.name;
    },
    translateParameterNameByTag(tags) {
      if (tags.includes('effective work time')) {
        return t`parameter.work`;
      }
      if (tags.includes('device active')) {
        return t`parameter.active`;
      }

      if (tags.includes('efficiency')) {
        return t`parameter.efficiency`;
      }

      return tags[0];
    },
  },
};
