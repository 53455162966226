import Vue from 'vue';

// Import app component
import Application from '@components/application/Application';

/**
 * Factory for creating application
 */
export default {
  /**
   * Create new Application instance
   */
  create: ({
    config, router, store, vuetify,
  }) => (
    // Return new instance of application
    new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      async mounted() {
        // Initialize store modules and wait for results
        await this.$store.dispatch('init', { apiClient: this.$http, config });
      },
      render: (h) => h(Application),
    })
  ),
};
