import random from '@utils/random';

/**
 * Path generation plugin
 */
export default {
  install(Vue) {
    Vue.prototype.$bkf.random = random;
    Vue.prototype.$random = random;
  },
};
