<template>
  <div>
    <v-data-table
      v-if="conditions.length"
      :headers="alertRuleColumns"
      :items="conditions"
      :items-per-page="10"
      :hide-default-footer="true"
    >
      <template #[`item.parameter`]="{ item }">
        <template v-if="item.parameter">
          {{ item.parameter.name }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #[`item.condition`]="{ item }">
        <condition
          :row="item"
          :alert-rule="alertRule"
        />
      </template>
    </v-data-table>
    <template v-else>
      <v-alert
        type="info"
        class="mt-0"
      >
        {{ 'alert-rule.no-conditions'|trans }}
      </v-alert>
    </template>
  </div>
</template>

<script>
import Condition from './Condition.vue';

export default {
  components: {
    Condition,
  },
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    model: null,
    entries: [],
    conditions: [],
    config: {
      multiSelect: false,
    },
    columns: [{
      value: 'parameter',
      text: t`alert-rule.parameter`,
      sortable: false,
    }, {
      value: 'condition',
      text: t`alert-rule.condition.name`,
      sortable: false,
    }],
  }),
  computed: {
    alertRuleColumns() {
      if (this.alertRule.type === 'periodic performance'
      || this.alertRule.type === 'periodic') {
        return [
          {
            value: 'condition',
            text: t`alert-rule.condition.name`,
            sortable: false,
          },
        ];
      }
      return this.columns;
    },
  },
  created() {
    this.model = this.alertRule.$model;
    this.conditions = this.alertRule.$data.conditions;
  },
};
</script>
