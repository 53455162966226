<template>
  <bkf-widget
    class="geo-monitor d-flex flex-column"
    :flat="flat"
  >
    <template
      slot="title"
    >
      <template v-if="showTitle">
        {{ 'diagnostics.title-geomonitor'|trans }}
      </template>
    </template>
    <template
      slot="actions"
    >
      <template v-if="showActions">
        <template v-if="settings.edited">
          <bkf-action-close @click="remove" />
          <bkf-action-expand
            v-if="!isMobile"
            v-model="settings.expanded"
            @click="resize"
          />
          <bkf-action-refresh @click="refresh" />
        </template>
        <bkf-action-options @click="onOptions(!settings.edited)" />
      </template>
    </template>
    <div
      v-if="loading"
      class="progress"
    >
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <template v-if="settings.edited">
      <template v-if="showDevices">
        <v-row>
          <v-col>
            <v-radio-group
              v-model="settings.byGroups"
              :mandatory="true"
              :hide-details="true"
              row
              dense
              class="mb-0 mt-0"
            >
              <v-radio
                :label="$trans('geo-monitor.devices')"
                :value="false"
              />
              <v-radio
                :label="$trans('geo-monitor.device-groups')"
                :value="true"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="auto">
            <v-switch
              v-model="onSwitch"
              class="mx-2 mt-0"
              :disabled="selectedDevices.length === 0 || noData"
              :label="$trans('geo-monitor.show.route')"
              hide-details
              dense
              @change="showRoute"
            />
          </v-col>
        </v-row>

        <field-devices
          v-show="!settings.byGroups"
          v-model="settings.devices"
          :devices="devices"
        />
        <field-device-groups
          v-show="settings.byGroups"
          v-model="settings.deviceGroups"
          :device-groups="deviceGroups"
        />
      </template>
      <div :class="{ 'mx-n4': flat }">
        <field-date-range
          v-model="settings.dateRange"
        />
      </div>
    </template>
    <v-alert
      v-if="message"
      type="warning"
      class="mt-0"
      :class="{ 'mx-n4': flat }"
    >
      {{ message }}
    </v-alert>
    <v-alert
      v-if="noData && !message"
      type="warning"
      class="mt-0"
      :class="{ 'mx-n4': flat }"
    >
      {{ noMessageData() }}
    </v-alert>

    <template
      slot="map"
    >
      <div
        class="pb-4 pt-0 flex-grow-1"
        :class="[mapClass, { 'pa-4': !flat }]"
      >
        <div
          :id="mapId"
          class="w-100p h-100p"
          style="z-index: 1;"
        />
      </div>
      <div
        v-if="settings.edited || slider"
        class="pb-0 pt-0 flex-grow-1 pa-4"
      >
        <v-slider
          v-model="date.slider"
          class="flex-1"
          :min="timestamps.start"
          :max="timestamps.end"
          :step="300000"
          :format-tooltip="formatTooltip"
        >
          <template #prepend>
            <div style="width: max-content">
              {{ settings.dateRange[0]|date }}
            </div>
          </template>
          <template #append>
            <div style="width: max-content">
              {{ settings.dateRange[1]|date }}
            </div>
          </template>
        </v-slider>
      </div>
    </template>
    <bkf-refresher
      :speed="300"
      :pause="loading"
      @refresh="refresh"
    />
  </bkf-widget>
</template>

<script>
import { flatten, uniq } from 'ramda';
import {
  addDays, format, getTime, parse, startOfToday,
} from 'date-fns';
import L from 'leaflet';
import { antPath } from 'leaflet-ant-path';

import sizeMixin from '@mixins/store/size';
import { truthyAll } from '@utils/asserts';

import FieldDateRange from './fields/DateRange';
import FieldDevices from './fields/Devices';
import FieldDeviceGroups from './fields/DeviceGroups';

import colors from './internals/colors';

export default {
  components: {
    FieldDateRange,
    FieldDevices,
    FieldDeviceGroups,

  },
  mixins: [
    sizeMixin,
  ],
  props: {
    monitor: {
      required: true,
      type: Object,
    },
    devices: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showActions: {
      required: false,
      type: Boolean,
      default: true,
    },
    showDevices: {
      required: false,
      type: Boolean,
      default: true,
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    edited: {
      required: false,
      type: Boolean,
      default: true,
    },
    slider: {
      required: false,
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deviceName: '',
      loading: false,
      noData: false,
      circles: [],
      pointers: [],
      paths: [],
      noDataMessage: [],
      gpsRoute: [],
      color: '',
      fillColor: '',
      show: true,
      onSwitch: false,
      mapSource: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: {
        lat: 51.919438,
        lon: 19.145136,
      },
      zoom: 5,
      map: null,
      settings: {
        devices: [],
        deviceGroups: [],
        dateRange: [
          startOfToday(),
          startOfToday(),
        ],
        expanded: false,
        edited: true,
        byGroups: false,
        agps: true,
      },
      date: {
        format: 'YYYY-MM-DD',
        slider: 1,
      },
      data: [],
    };
  },
  computed: {
    timestamps() {
      const [start, end] = this.settings.dateRange;

      return {
        start: getTime(start),
        end: getTime(addDays(end, 1)),
      };
    },
    monitorClass() {
      const { isMobile, settings, themeClass } = this;

      if (isMobile || settings.expanded) {
        return ['widget-expanded'];
      }

      return themeClass;
    },
    mapClass() {
      return this.settings.edited ? 'hmin-300' : 'hmin-500';
    },
    mapId() {
      return `map-${this.monitor.id}`;
    },
    selectedDevices() {
      const { devices, deviceGroups, byGroups } = this.settings;

      if (byGroups) {
        const results = this.deviceGroups
          .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
          .map((deviceGroup) => deviceGroup.devices.map((device) => device.id));
        const filtered = uniq(flatten(results));

        return this.devices.filter((device) => filtered.includes(device.id));
      }

      return this.devices.filter((device) => devices.includes(device.id));
    },
    settingsValid() {
      const { selectedDevices } = this;
      const { dateRange } = this.settings;

      return truthyAll(
        dateRange[0],
        dateRange[1],
        selectedDevices,
      );
    },
    message() {
      const { devices, deviceGroups, byGroups } = this.settings;

      if (!devices.length && !byGroups) {
        return t`geo-monitor.message.no-devices`;
      }

      if (!deviceGroups.length && byGroups) {
        return t`geo-monitor.message.no-device-groups`;
      }

      return '';
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(to, from) {
        // Save settings and resize chart
        this.saveSettings();
        this.resize();

        // Check if device select mode was changed
        if (to.byGroups !== from.byGroups) {
          return;
        }

        // Check if agps was changed
        if (to.agps !== from.agps) {
          return;
        }

        // Edit or expand mode was changed so not fetch Data
        if (to.edited !== from.edited || to.expanded !== from.expanded) {
          return;
        }

        if (this.settingsValid) {
          this.fetchData();
        }
      },
    },
    'timestamps.end': {
      immediate: true,
      handler(to) {
        this.date.slider = to;
      },
    },
    'date.slider': {
      handler() {
        this.redrawMap();
      },
    },
  },
  mounted() {
    const {
      edited, expanded, devices, deviceGroups, byGroups,
    } = this.monitor.params || {
      devices: [],
      deviceGroups: [],
      byGroups: this.byGroups,
      edited: this.edited,
      expanded: this.expanded,
    };

    if (devices.length) {
      this.settings.devices = this.devices
        .filter((device) => devices.includes(device.id))
        .map((device) => device.id);
    }

    if (byGroups) {
      this.settings.byGroups = byGroups;
    }

    if (deviceGroups.length) {
      this.settings.deviceGroups = this.deviceGroups
        .filter((deviceGroup) => deviceGroups.includes(deviceGroup.id))
        .map((deviceGroup) => deviceGroup.id);
    }
    this.onSwitch = false;
    this.initMap();
    this.settings.edited = edited;
    this.settings.expanded = expanded;
    this.onOptions(edited);
  },
  methods: {
    initMap() {
      const {
        center, zoom, mapId, mapSource,
      } = this;
      this.map = L.map(mapId, {
        // turn off small textarea on botton-right corner
        attributionControl: false,
      })
        .setView([center.lat, center.lon], zoom);

      L.tileLayer(mapSource).addTo(this.map);
    },
    async redrawMap() {
      this.circles.forEach((circle) => this.map.removeLayer(circle));
      this.circles = [];

      this.pointers.forEach((pointer) => this.map.removeLayer(pointer));
      this.pointers = [];

      this.paths.forEach((path) => this.map.removeLayer(path));
      this.paths = [];

      const latLngs = [];
      this.noData = false;
      this.listDevice = [];

      this.onSwitch = false;
      this.show = true;

      let value = null;

      this.data.forEach((values, index) => {
        if (value !== null) {
          return;
        }

        if (getTime(parse(values.time)) <= this.date.slider) {
          value = values;
        } else {
          return;
        }

        if (values.length === 0) {
          this.noData = true;
          this.noDataMessage.push(this.deviceName);
        }
        if (value) {
          const {
            time,
            lat,
            lon,
            acc,
          } = value;

          const formattedDate = format(parse(time), 'YYYY-MM-DD HH:mm');
          const color = colors[index % colors.length][900];
          const fillColor = colors[index % colors.length][500];
          const pointer = L.circle([lat, lon], {
            color,
            fillColor,
            fillOpacity: 1,
            radius: 7,
          }).bindTooltip(`${this.deviceName}: ${formattedDate}`, {
            permanent: true,
            opacity: 1,
          });
          pointer.addTo(this.map);
          this.pointers.push(pointer);
          if (this.settings.agps) {
            const circle = L.circle([lat, lon], {
              color: null,
              fillColor,
              fillOpacity: 0.3,
              radius: Math.min(1000, acc),
            });

            circle.addTo(this.map);
            this.circles.push(circle);
          }
          latLngs.push([lat, lon]);
        } else {
          this.listDevice.push(this.deviceName);
          this.noData = true;
        }

        if (latLngs.length) {
          this.map.fitBounds(latLngs, {
            maxZoom: 14,
          });
        }
      });
    },
    async fetchData() {
      const { date, settings, selectedDevices } = this;
      const { dateRange } = settings;
      const from = format(dateRange[0], date.format);
      const to = format(dateRange[1], date.format);

      if (selectedDevices.length === 0) {
        this.data = [];
        this.redrawMap();
        return;
      }

      this.deviceName = selectedDevices[0].name;

      const url = `/user/device/${selectedDevices[0].id}/locations`;

      this.loading = true;

      const { data, status } = await this.$http.get(url, {
        from,
        to,
      });

      this.loading = false;

      if (status === 200) {
        this.data = data;
      }

      this.redrawMap();
    },
    fitToBounds(bounds) {
      if (bounds.length || bounds.isValid()) {
        this.map.fitBounds(bounds);
      }
    },
    remove() {
      this.$emit('remove', this.monitor);
    },
    onOptions(edited) {
      this.settings.edited = edited;
      this.resize();
    },
    async refresh() {
      if (this.settingsValid) {
        await this.fetchData();
      }
    },
    openSettings() {
      this.$refs.settings.open();
    },
    saveSettings() {
      const { monitor } = this;
      const {
        deviceGroups, byGroups, edited, expanded,
      } = this.settings;

      monitor.params = {
        devices: this.settings.devices,
        deviceGroups,
        byGroups,
        edited,
        expanded,
      };

      this.$emit('save');
    },
    async resize() {
      // This is required to invoke after resize map container
      // to recalculate map dimensions
      await this.$sleep(200);
      this.map.invalidateSize();
    },
    formatTooltip(value) {
      return format(new Date(value), 'YYYY-MM-DD HH:mm');
    },
    noMessageData() {
      return this.$trans(
        'map.area.alert',
        this.listDevice.length > 5 ? `${this.listDevice.slice(0, 4).join(', ')} ...` : this.listDevice.join(', '),
      );
    },
    showRoute() {
      if (this.show && this.data.length > 0) {
        const latLngRoute = [];
        const gpsData = this.data.map((array) => [array.lat, array.lon]);

        const color = colors[1 % colors.length][900];
        const pulseColor = colors[1 % colors.length][500];
        const path = antPath(gpsData, {
          delay: 400,
          dashArray: [
            10,
            20,
          ],
          weight: 1,
          color,
          pulseColor,
          paused: false,
          reverse: false,
          hardwareAccelerated: true,
        });
        path.addTo(this.map);
        this.paths.push(path);
        latLngRoute.push(gpsData);

        this.map.fitBounds(latLngRoute, {
          maxZoom: 14,
        });
        this.show = false;
      } else {
        this.paths.forEach((path) => this.map.removeLayer(path));
        this.paths = [];
        this.show = true;
        this.redrawMap();
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.progress {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
