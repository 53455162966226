import Alert from './Alert';
import AlertCount from './AlertCount';
import Battery from './Battery';
import EnabledTime from './EnabledTime';
import EnabledTimeLastActive from './EnabledTimeLastActive';
import Icon from './Icon';
import Location from './Location';
import Sensor from './Sensor';
import TrendIcon from './TrendIcon';
import WorkTime from './WorkTime';
import Operator from './Operator';
import OperatorLastActive from './OperatorLastActive';
import Supply from './Supply';
import WorkTimeLastActive from './WorkTimeLastActive';

export default {
  install(Vue) {
    Vue.use(Alert);
    Vue.use(AlertCount);
    Vue.use(Battery);
    Vue.use(EnabledTime);
    Vue.use(EnabledTimeLastActive);
    Vue.use(Icon);
    Vue.use(Location);
    Vue.use(Sensor);
    Vue.use(TrendIcon);
    Vue.use(WorkTime);
    Vue.use(Operator);
    Vue.use(OperatorLastActive);
    Vue.use(Supply);
    Vue.use(WorkTimeLastActive);
    Vue.use(WorkTimeLastActive);
  },
};
