<template>
  <bkf-dialog-entity-delete
    ref="delete"
    :title="title"
    :disabled="!!conditions.length"
    v-on="$listeners"
  >
    <div
      v-if="conditions.length"
      class="w-100p"
    >
      {{ 'alert-rule.delete-conditions.unresolved'|trans }}:
      <div class="flex flex-justify-center flex-align-items-center mb-20">
        <bkf-action-previous-page
          :disabled="progress"
          @click="previousPage"
        />
        <div class="mt-2">
          {{ 'alert-rule.delete-conditions.condition-of'|trans(page, pages) }}
        </div>
        <bkf-action-next-page
          :disabled="progress"
          @click="nextPage"
        />
      </div><template v-if="condition.type === 'device'">
        {{ 'alert-rule.delete-conditions.device.message'|trans }}:
        <span class="fw-500">
          {{ condition.device.name }}
        </span>
        <div class="flex flex-justify-center">
          <bkf-tooltip
            :content="$trans('alert-rule.delete-conditions.device.unassign-tip')"
          >
            <v-btn
              class="primary"
              tile
              :disabled="progress"
              @click="unlinkDevice(condition.device)"
            >
              {{ 'alert-rule.delete-conditions.device.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <template
        v-else-if="condition.type === 'alertRuleCondition'"
      >
        {{ 'alert-rule.delete-conditions.alert-condition.message'
          |trans(condition.alertRuleConditions.length) }}
        <div class="flex flex-justify-center">
          <bkf-tooltip
            :content="$trans('alert-rule.delete-conditions.alert-condition.remove-tip')"
          >
            <v-btn
              class="primary"
              tile
              :disabled="progress"
              @click="removeAlertConditions"
            >
              {{ 'alert-rule.delete-conditions.alert-condition.remove'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <template v-else-if="condition.type === 'group'">
        {{ 'alert-rule.delete-conditions.device-group.message'|trans }}:
        <span class="fw-500">
          {{ condition.group.name }}
        </span>
        <div class="flex flex-justify-center">
          <bkf-tooltip :content="$trans('alert-rule.delete-conditions.device-group.unassign-tip')">
            <v-btn
              class="primary"
              tile
              :disabled="progress"
              @click="unlinkGroup(condition.group)"
            >
              {{ 'alert-rule.delete-conditions.device-group.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <template
        v-else-if="condition.type === 'user'"
      >
        {{ 'alert-rule.delete-conditions.user.message'|trans }}:
        <span class="fw-500">{{ condition.user.name }}</span>
        <div class="flex flex-justify-center">
          <bkf-tooltip :content="$trans('alert-rule.delete-conditions.user.unassign-tip')">
            <v-btn
              class="primary"
              tile
              :disabled="progress || !isUnlinkable(condition.user)"
              @click="unlinkUser(condition.user)"
            >
              {{ 'alert-rule.delete-conditions.user.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
    </div>
    <div v-else>
      {{ 'alert-rule.delete-confirm'|trans }}?
    </div>
  </bkf-dialog-entity-delete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    devicesBus: {
      type: Object,
      required: true,
    },
    deviceGroupsBus: {
      type: Object,
      required: true,
    },
    usersBus: {
      type: Object,
      required: true,
    },
    conditionsBus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      index: 0,
      alertRule: null,
      progress: false,
      alertRuleConditions: [],
      devices: [],
      deviceGroups: [],
      users: [],
      subordinates: [],
    };
  },
  computed: {
    title() {
      return t`alert-rule.deleting`;
    },
    conditions() {
      /* eslint-disable */
      const { alertRuleConditions, devices, deviceGroups, users } = this;
      const conditions = [];

      devices.forEach((device) => {
        conditions.push({
          type: 'device',
          device,
        });
      });

      deviceGroups.forEach((group) => {
        conditions.push({
          type: 'group',
          group,
        });
      });

      users.forEach((user) => {
        conditions.push({
          type: 'user',
          user,
        });
      });

      if (alertRuleConditions.length > 0) {
        conditions.push({
          type: 'alertRuleCondition',
          alertRuleConditions,
        });
      }

      return conditions;
    },
    condition() {
      const { conditions } = this;
      if (!conditions.length) {
        return null;
      }

      return conditions[this.index];
    },
    page() {
      return this.index + 1;
    },
    pages() {
      return this.conditions.length;
    },
    linkedUsers() {
      return [...this.subordinates];
    },
    ...mapGetters({
      user: 'users/current',
    }),
  },
  async mounted() {
     const { data } = await this.$http.get('users/list');
    if (data) {
      this.subordinates = data;
    }
  },
  methods: {
    open(alertRule, options = {}) {
      this.alertRule = alertRule;
      this.devices = alertRule.devices;
      this.deviceGroups = alertRule.deviceGroups;
      this.users = alertRule.users;
      this.alertRuleConditions = alertRule.conditions;
      this.progress = false;
      this.index = 0;
      this.$refs.delete.open(alertRule, options);
    },
    previousPage() {
      this.index = Math.max(0, this.index - 1);
    },
    nextPage() {
      this.index = Math.min(this.conditions.length - 1, this.index + 1);
    },
    isUnlinkable(user) {
      return this.linkedUsers.some(u => u.id === user.id);
    },
    async unlinkDevice(device) {
      const { alertRule } = this;
      this.progress = true;
      await this.$http.patch(`/alertrules/${alertRule.id}/devices/${device.id}/unassign`);
      this.devicesBus.$emit('unassign-device', device.id);
      await alertRule.sync();
      this.devices = alertRule.devices;
      this.$notify.success(t`alert-rule.delete-conditions.device.unassigned`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
    async unlinkGroup(group) {
      const { alertRule } = this;
      this.progress = true;
      await this.$http.patch(`/alertrules/${alertRule.id}/devicegroups/${group.id}/unassign`);
      this.deviceGroupsBus.$emit('unassign-device-group', group.id);
      await alertRule.sync();
      this.deviceGroups = alertRule.deviceGroups;
      this.$notify.success(t`alert-rule.delete-conditions.device-group.unassigned`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
    async unlinkUser(user) {
      const { alertRule } = this;
      this.progress = true;
      await this.$http.patch(`/alertrules/${alertRule.id}/users/${user.id}/unassign`);
      this.usersBus.$emit('unassign-user', user.id);
      await alertRule.sync();
      this.users = alertRule.users;
      this.$notify.success(t`alert-rule.delete-conditions.user.unassigned`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
    async removeAlertConditions() {
      const { alertRule } = this;
      this.progress = true;
      await this.$http.delete(`/alertrules/${alertRule.id}/conditions`);
      this.conditionsBus.$emit('unassign-conditions');
      await alertRule.sync();
      this.alertRuleConditions = alertRule.conditions;
      this.$notify.success(t`alert-rule.delete-conditions.alert-condition.removed`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
  },
};
</script>