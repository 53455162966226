<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    eager
    max-width="800"
    transform-origin="bottom center"
  >
    <v-card
      class="d-flex flex-column"
      :loading="loading"
    >
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'settings.settings'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-tabs>
          <v-tab>
            {{ 'profile.settings.profile'|trans }}
          </v-tab>
          <v-tab-item>
            <profile-settings
              :notification="settings.emailNotification"
              :validation="errors"
              @change="profileChanged"
            />
          </v-tab-item>
          <v-tab>
            {{ 'notifications.notifications'|trans }}
          </v-tab>
          <v-tab-item>
            <notifications-settings
              :notifications.sync="settings.notifications"
              @change="notificationsChanged"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :loading="loading"
          @click="submit"
        >
          {{ 'settings.save'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from '@mixins/store/user';
import settingsMixin from '@mixins/misc/settings';
import ProfileSettings from './ProfileSettings';
import NotificationsSettings from './NotificationsSettings';

export default {
  components: {
    ProfileSettings,
    NotificationsSettings,
  },
  mixins: [
    userMixin,
    settingsMixin,
  ],
  data() {
    return {
      loading: true,
      settings: {
        notifications: {
          email: true,
          app: true,
          levelInfo: true,
          levelWarning: true,
          levelError: true,
          globalNotifications: true,
        },
      },
      errors: {},
      avatar: null,
      showDialog: false,
    };
  },
  computed: {
    showAvatar() {
      return this.avatar || this.user.avatar;
    },
  },
  methods: {
    async open() {
      this.errors = {};
      this.showDialog = true;

      const { data } = await this.$http.get('/users/current/profile');
      this.settings = data || this.settings;
      this.loading = false;
    },
    close() {
      this.showDialog = false;
    },
    async submit() {
      this.loading = true;

      if (this.user.phone !== null) {
        this.user.phone = this.user.phone.replace(/\s/g, '');
      }

      const { user, settings, avatar } = this;
      const route = '/users/current/profile';
      const { data, status } = await this.$http.patch(route, { user, settings, avatar });

      if (status === 400) {
        this.errors = data.error;
        this.loading = false;
      }

      if (status === 200) {
        const model = await this.$model('User');
        const entry = await model.get({
          id: 'current',
          force: true,
        });
        this.$store.commit('users/USERS_SET_CURRENT', entry);
        this.errors = {};
        this.close();
      }
    },
    notificationsChanged(notifications) {
      this.settings.notifications = notifications.notifications;
    },
    profileChanged(profile) {
      const { avatar } = profile;
      this.avatar = avatar;
    },
  },
};
</script>
