<template>
  <div>
    <v-form
      ref="stepTwoForm"
      v-model="valid"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td width="25%">
                <v-text-field
                  v-model="lineName"
                  :label="$trans('productionview.linename1')"
                  :rules="linenameRule"
                  required
                />
              </td>
              <td width="25%">
                <devices-field
                  ref="DeviceField"
                  v-model="settings.devices"
                  :devices="devices"
                  :selectedid="settings.devices[0]"
                />
              </td>
              <td width="25%">
                <field-parameter
                  ref="ParamField"
                  v-model="settings.parameters"
                  :label="$trans('productionview.parameters')"
                  :disabled="false"
                  :parameters="selectableParameters"
                  :selectedid="settings.parameters[0]"
                />
              </td>
              <td width="25%">
                <v-text-field
                  v-model="goal"
                  :label="$trans('productionview.goal')"
                  :rules="goalRule"
                  required
                  type="number"
                  min="0"
                  max="10000"
                />
              </td>
            </tr>
            <tr>
              <td width="25%">
                <v-text-field
                  v-model="lineName2"
                  :label="$trans('productionview.linename2')"
                  :rules="linenameRule"
                  required
                />
              </td>
              <td width="25%">
                <devices-field
                  ref="DeviceField2"
                  v-model="settings.devices2"
                  :devices="devices"
                  :selectedid="settings.devices2[0]"
                />
              </td>
              <td width="25%">
                <field-parameter
                  ref="ParamField2"
                  v-model="settings.parameters2"
                  :label="$trans('productionview.parameters')"
                  :disabled="false"
                  :parameters="selectableParameters2"
                  :selectedid="settings.parameters2[0]"
                />
              </td>
              <td width="25%">
                <v-text-field
                  v-model="goal2"
                  :label="$trans('productionview.goal')"
                  :rules="goalRule"
                  required
                  type="number"
                  min="0"
                  max="10000"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import {
  flatten, sortBy, prop, uniq, toLower, compose,
} from 'ramda';
import DevicesField from './fields/ProductionView/Devices/Devices.vue';
import FieldParameter from './fields/ProductionView/Parameter/Parameter.vue';

export default {
  components: {
    DevicesField,
    FieldParameter,
  },
  props: {
    devices: {
      required: true,
      type: Array,
    },
    deviceTypes: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    settings: {
      devices: [],
      parameters: [],
      devices2: [],
      parameters2: [],
    },
    lineParams: [],
    lineName: '',
    deviceId: 0,
    parameterId: 0,
    goal: 0,
    lineName2: '',
    goal2: 0,
    valid: true,
    linenameRule: [
      (v) => !!v || t`productionview.required`,
      (v) => (v && v.length < 50) || t`productionview.maxlength50`,
    ],
    goalRule: [
      (v) => !!v || t`productionview.required`,
      (v) => /^\d+$/.test(v) || t`productionview.numbersonly`],

  }),
  computed: {
    selectedDevices() {
      const { devices } = this.settings;
      return this.devices.filter((device) => devices.includes(device.id));
    },
    selectableParameters() {
      const { deviceTypes, selectedDevices } = this;

      const selectedDeviceTypesIds = uniq(selectedDevices.map((device) => device.deviceType.id));
      const selectedDeviceTypes = deviceTypes
        .filter((deviceType) => selectedDeviceTypesIds.includes(deviceType.id));
      const parameters = sortBy(
        compose(toLower, prop('name')),
        uniq(flatten(selectedDeviceTypes.map((deviceType) => deviceType.parameters))),
      );
      return parameters.filter((p) => p.number !== 10000);
    },
    selectedDevices2() {
      const { devices2 } = this.settings;
      return this.devices.filter((device) => devices2.includes(device.id));
    },
    selectableParameters2() {
      const { deviceTypes, selectedDevices2 } = this;

      const selectedDeviceTypesIds = uniq(selectedDevices2.map((device) => device.deviceType.id));
      const selectedDeviceTypes = deviceTypes
        .filter((deviceType) => selectedDeviceTypesIds.includes(deviceType.id));
      const parameters = sortBy(
        compose(toLower, prop('name')),
        uniq(flatten(selectedDeviceTypes.map((deviceType) => deviceType.parameters))),
      );
      return parameters.filter((p) => p.number !== 10000);
    },
  },
  methods: {
    stepThree() {
      if (this.$refs.stepTwoForm.validate()) {
        const lineOne = {
          lineName: this.lineName,
          deviceId: this.settings.devices[0],
          parameter_id: this.settings.parameters[0],
          goal: Number(this.goal),
        };
        const lineTwo = {
          lineName: this.lineName2,
          deviceId: this.settings.devices2[0],
          parameter_id: this.settings.parameters2[0],
          goal: Number(this.goal2),
        };
        this.lineParams.push(lineOne);
        this.lineParams.push(lineTwo);

        this.$emit('setstep2values', this.lineParams);

        return true;
      }
      return false;
    },
    setValues(lines) {
      const deviceArray = [];
      deviceArray.id = Number(lines[0].deviceId);
      this.settings.devices[0] = deviceArray;

      const deviceArray2 = [];
      deviceArray2.id = Number(lines[1].deviceId);
      this.settings.devices2[0] = deviceArray2;

      this.$refs.DeviceField.emitInput(Number(lines[0].deviceId));
      this.$refs.DeviceField2.emitInput(Number(lines[1].deviceId));

      const paramArray = [];
      paramArray.number = Number(lines[0].param);
      this.settings.parameters[0] = paramArray;

      const paramArray2 = [];
      paramArray2.number = Number(lines[1].param);
      this.settings.parameters2[0] = paramArray2;

      this.$refs.ParamField.emitInput(Number(lines[0].param));
      this.$refs.ParamField2.emitInput(Number(lines[1].param));

      this.lineName = lines[0].label;
      this.goal = lines[0].target;
      this.lineName2 = lines[1].label;
      this.goal2 = lines[1].target;
    },
  },
};
</script>
