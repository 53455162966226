import { Notification } from 'element-ui';
import trans from '../utils/trans';

/**
 * Notify some information
 * This is wrapper function with default options
 * @param {{}} options
 * @param {Notification} type
 */
function notify(options, type = null) {
  if (typeof options === 'string') {
    return Notification({
      message: trans(options),
      title: trans(`notify.${type || 'info'}`),
      duration: 2000,
      type,
    });
  }

  if (type) {
    options.type = type;
  }

  if (!options.offset) {
    options.offset = 50;
  }

  if (!options.title && type) {
    options.title = trans(`notify.${options.type}`);
  }

  return Notification(options);
}

// Compatibility functions to uniform official api from element-ui:
// http://element.eleme.io/#/en-US/component/notification
notify.success = (options) => notify(options, 'success');
notify.info = (options) => notify(options, 'info');
notify.warning = (options) => notify(options, 'warning');
notify.error = (options) => notify(options, 'error');

/**
 * Notification system from Element-UI framework
 */
export default {
  install(Vue) {
    Vue.prototype.$bkf.notify = notify;
    Vue.prototype.$notify = notify;
  },
};
