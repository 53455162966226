<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="false"
        :error="error"
        class="pt-3"
      >
        <bkf-widget>
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto"
            >
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="!siteLoading"
            :loading="siteLoading"
            :headers="columns"
            :items="administrationSensors"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
          >
            <template #[`item.sensoreStatus`]="{ item }">
              <module-activity-indicator
                class="my-2"
                :last-sync="$deep(item, 'sensorStatus.lastSynchroTime')"
                :online-status="item.sensorStatus.lastSynchroStatus"
              />
            </template>

            <template #[`item.sensorStatus.powerSupply`]="{ item }">
              <supply-voltage-indicator
                class="my-2"
                :value="item.sensorStatus.powerSupply"
                :battery-status="item.sensorStatus.powerSupplyStatus"
              />
            </template>

            <template
              #expanded-item="{ headers, item }"
              class="expand-table"
            >
              <td
                :colspan="headers.length"
                class="py-2"
              >
                <v-card
                  class="px-3 py-3"
                  outlined
                  color="blue-grey lighten-5"
                >
                  <h3 class="mb-3">
                    {{ 'sensor.peripheries'|trans }}
                  </h3>
                  <v-simple-table
                    v-if="item.peripheries.length > 0"
                    dense
                  >
                    <template #default>
                      <tbody>
                        <tr>
                          <td
                            class="text-sm-start"
                          >
                            {{ 'id'|trans }}
                          </td>
                          <td
                            class="text-sm-start"
                          >
                            {{ 'adr'|trans }}
                          </td>
                          <td
                            class="text-sm-end"
                          >
                            {{ 'sensor.type'|trans }}
                          </td>
                          <td
                            class="text-sm-end"
                          >
                            {{ 'pcb'|trans }}
                          </td>
                          <td
                            class="text-sm-end"
                          >
                            {{ 'sensor.software-version'|trans }}
                          </td>
                          <td
                            class="text-sm-end"
                          >
                            {{ 'sensor.last-activity'|trans }}
                          </td>
                        </tr>
                        <tr
                          v-for="periphery in item.peripheries"
                          :key="periphery.id"
                        >
                          <td class="text-sm-start">
                            {{ periphery.id }}
                          </td>
                          <td class="text-sm-start">
                            {{ periphery.adr }}
                          </td>
                          <td class="text-sm-end">
                            {{ periphery.type }}
                          </td>
                          <td class="text-sm-end">
                            {{ periphery.pcbVersion }}
                          </td>
                          <td class="text-sm-end">
                            {{ periphery.softVersion }}
                          </td>
                          <td class="text-sm-end">
                            <module-activity-indicator
                              class="my-2"
                              :last-sync="$deep(periphery, 'lastContact')"
                              :online-status="periphery.lastContactStatus"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>

                <details-table
                  v-if="item.ai !== null && Object.keys(item.ai).length > 0"
                  :key="`sensor-ai-${item.id}`"
                  :data="item.ai"
                  :header-text="$trans(`additional-info`)"
                />
              </td>
            </template>
          </v-data-table>
          <v-progress-circular
            v-if="siteLoading"
            color="primary"
            class="mb-8"
            size="60"
            indeterminate
          />
        </bkf-widget>
      </bkf-page>
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import debounce from 'lodash/debounce';
import indicatorsMixin from '@mixins/domain/device/indicators';
import DetailsTable from './tabs/DetailsTable.vue';

export default {
  components: {
    DetailsTable,
  },
  mixins: [
    pageMixin,
    listMixin,
    indicatorsMixin,
  ],
  data() {
    return {
      expanded: [],
      siteLoading: true,
      search: null,
      settings: {
        size: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      totalItems: 13,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      administrationSensors: [],
      columns: [
        {
          value: 'id',
          text: t`id`,
        },
        {
          value: 'name',
          text: t`device.name`,
        },
        {
          value: 'owner.name',
          text: t`sensor.owner`,
        },
        {
          value: 'sensorStatus.software',
          text: t`sensor.software-version`,
        },
        {
          value: 'device.name',
          text: t`sensor.device`,
        },
        {
          value: 'sensoreStatus',
          text: t`sensor.active`,
        },
        {
          value: 'sensorStatus.powerSupply',
          text: t`device.power-state`,
        },
      ],
    };
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.siteLoading = true;
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  mounted() {
    this.siteLoading = true;
    this.getData();
  },
  methods: {
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `admin/sensors?${urlParams}`,
      )
        .then(
          (response) => {
            this.administrationSensors = response.data;
            this.totalItems = parseInt(response.headers['x-pager-total-entries'], 10);
            this.siteLoading = false;
          },
        );
    },
    async onActivatePage() {
      this.siteLoading = true;
      // await this.getData();
    },
  },
};
</script>

<style scoped lang="stylus">
.expand-table
  box-shadow: inset 0 0px 0px 0px !important;
</style>
