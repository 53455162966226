var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bkf-widget',[_c('template',{slot:"title"},[_vm._v("\n    "+_vm._s(_vm._f("trans")('device-operator-group.assigned-device-operators'))+"\n  ")]),_vm._v(" "),(_vm.loaded)?_c('template',{slot:"actions"},[_c('bkf-action-add',{attrs:{"tooltip":_vm.$trans('action-button.assign')},on:{"click":_vm.$refs.dialog.open}})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?[(_vm.linkedDeviceOperators.length)?_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.linkedDeviceOperators,"item-class":_vm.rowClass,"items-per-page":_vm.settings.size},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((_vm.columns),function(h){return {key:("header." + (h.value)),fn:function(){return [(h.tooltip)?_c('v-tooltip',{key:h.value,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(h.tooltip))])]):[_vm._v("\n          "+_vm._s(h.text)+"\n        ")]]},proxy:true}}),{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('sensor-indicator',{attrs:{"last-sync":_vm._f("deep")(item.diagnostics,'operator_last_active.date')}}),_vm._v("\n        "+_vm._s(item.name)+"\n      ")]}},{key:"item.diagnostics.day_data.active",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getActiveTime(item)))+"\n      ")]}},{key:"item.diagnostics.day_data.work",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("human")(_vm.getWorkTime(item)))+"\n      ")]}},{key:"item.productivity",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.productivity)+"%\n      ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('bkf-action-delete',{on:{"click":function($event){return _vm.unlink([item.id])}}})]}}],null,true)}):_c('v-alert',{staticClass:"mt-0",attrs:{"type":"info","tile":""}},[_vm._v("\n      "+_vm._s(_vm._f("trans")('device-operator-group.no-device-operators'))+"\n    ")])]:_vm._e(),_vm._v(" "),_c('assign-dialog',{ref:"dialog",attrs:{"device-operators":_vm.unlinkedDeviceOperators},on:{"submit":_vm.link}}),_vm._v(" "),_c('bkf-page-settings',{attrs:{"settings":_vm.componentSettings},on:{"update:settings":function($event){_vm.componentSettings=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }