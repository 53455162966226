<template>
  <indicator :state="state">
    <bkf-icon-work-time-last-active
      :work-time="workTime"
      :last-work="lastWork"
      :state="state"
    />
    <template slot="description">
      <template v-if="workTime !== null">
        {{ workTime|human }}
      </template>
      <template v-else>
        {{ 'device.indicator.no-data'|trans }}
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: Boolean,
      default: null,
    },
    status: {
      required: false,
      type: String,
      default: null,
    },
    lastWork: {
      default: null,
      required: false,
      type: String,
    },
    workTime: {
      required: false,
      type: Number,
      default: null,
    },
  },
  computed: {
    state() {
      if (this.status !== null) {
        return `icon-${this.status}`;
      }

      const { value } = this;

      if (value === null || value === undefined) {
        return null;
      }

      if (value) {
        return 'positive';
      }

      if (!value) {
        return 'negative';
      }

      return null;
    },
  },
};
</script>
