<template>
  <div>
    <div
      v-if="!isMobile"
      class="flex mt-2 mb-4 primary-color-text fw-500"
    >
      <div class="flex-4 center-align grey caption font-weight-bold">
        {{ 'widget.effective-work.device'|trans }}
      </div>
      <div class="flex-10 flex flex-align-items-center ml-10">
        <span class="working">
          &#x25FC;
        </span>
        <span class="widget-effective-work-working-label working caption font-weight-bold">
          {{ 'widget.effective-work.device-working'|trans }}
        </span>
        <span class="ml-10 enabled">
          &#x25FC;
        </span>
        <span class="widget-effective-work-active-label enabled caption font-weight-bold">
          {{ 'widget.effective-work.device-enabled'|trans }}
        </span>
      </div>
      <div class="flex-3 center-align grey caption font-weight-bold">
        <span class="widget-effective-work-working-time">
          {{ 'widget.effective-work.work-time'|trans }}
        </span>
      </div>
    </div>
    <chart-row
      v-for="d in sortedData"
      :key="d.id"
      :average="average"
      :data="d"
      :date="date"
      :width="width"
    />
    <div
      v-if="!isMobile"
      class="flex"
    >
      <div class="flex-4" />
      <div
        class="flex-10 mh-10"
        style="line-height: 14px"
      >
        <svg
          :viewBox="`0 0 ${width} 16`"
          :width="width"
          height="16"
          preserveAspectRatio="none"
        >
          <g
            stroke="#999"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line
              :x2="width"
              x1="0"
              y1="1"
              y2="1"
            />
            <path
              v-for="i in 25"
              :key="i"
              :d="`M${1 + (i - 1) * (width - 2) / 24},0 V${(i - 1) % 12 === 0 ? 12 : 6}`"
            />
          </g>
        </svg>
        <div
          class="flex flex-justify-space-between fs-08r"
        >
          <div class="flex flex-column left-align">
            <div>{{ dateTimeStart|time }}</div>
            <div class="fs-07r">
              {{ dateTimeStart|date }}
            </div>
          </div>
          <div class="flex flex-column center-align">
            <div>{{ dateTimeMiddle|time }}</div>
            <div class="fs-07r">
              {{ dateTimeMiddle|date }}
            </div>
          </div>
          <div class="flex flex-column right-align">
            <div>{{ dateTimeEnd|time }}</div>
            <div class="fs-07r">
              {{ dateTimeEnd|date }}
            </div>
          </div>
        </div>
        <resize-sensor
          @resize="resize"
        />
      </div>
      <div class="flex-3" />
    </div>
  </div>
</template>

<script>
import { prop, reverse, sortBy } from 'ramda';
import { addHours } from 'date-fns';
import ResizeSensor from 'vue-resize-sensor';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import ChartRow from './ChartRow.vue';

export default {
  components: {
    ChartRow,
    ResizeSensor,
  },
  mixins: [
    settingsMixin,
    sizeMixin,
  ],
  props: {
    data: {
      required: true,
      type: Array,
    },
    date: {
      required: true,
      type: Date,
    },
  },
  data() {
    return {
      moreThenAvg: false,
      sum: 0,
      width: 100,
      average: 0,
    };
  },
  computed: {
    dateTimeStart() {
      return this.date;
    },
    dateTimeMiddle() {
      return addHours(this.date, 12);
    },
    dateTimeEnd() {
      return addHours(this.date, 24);
    },
    sortedData() {
      return reverse(sortBy(prop('workSum'), this.data));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcSum();
      this.$emit('calc-sum', this.sum);
      this.$emit('average', this.average);
    });
  },
  methods: {
    resize({ width }) {
      this.width = width;
    },
    calcSum() {
      this.data.forEach((device) => {
        if (device.workSum) {
          const deviceAverage = (Math.round((device.workSum / device.activeSum) * 100));
          this.sum += deviceAverage;
          this.average = Math.round(this.sum / this.data.length);
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus">
working-color = #61a5f4
enabled-color = darken(#ddd, 40%)
.grey
 color #858585
 background-color transparent !important
.working
  color: working-color
.enabled
  color: enabled-color
</style>
