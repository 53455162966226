import io from 'socket.io-client';

let socket;

const mutations = {
  CONNECTED_SET: 'CONNECTED_SET',
  ENABLED_SET: 'ENABLED_SET',
  URL_SET: 'URL_SET',
};

export default {
  namespaced: true,
  state: {
    connected: false,
    enabled: false,
    url: null,
  },
  getters: {
    connected: (s) => s.connected,
    enabled: (s) => s.enabled,
    url: (s) => s.url,
    socket: () => socket,
  },
  mutations: {
    [mutations.CONNECTED_SET](state, status) {
      state.connected = status;
    },
    [mutations.ENABLED_SET](state, status) {
      state.enabled = status;
    },
    [mutations.URL_SET](state, url) {
      state.url = url;
    },
  },
  actions: {
    /* eslint-disable */
    async connect(context) {
      const {
        commit,
        dispatch,
        getters,
        rootGetters,
        rootState,
      } = context;

      const { enabled, url } = rootGetters.config.proxy;

      commit(mutations.ENABLED_SET, !!enabled);
      commit(mutations.URL_SET, url);

      if (!getters.enabled) {
        return;
      }

      // Initialize socket.io client
      socket = io(url, {
        autoConnect: true,
        transports: ['websocket'],
      });

      // On connect event
      socket.on('connect', () => {
        commit(mutations.CONNECTED_SET, true);
        const accessToken = rootGetters['oauth/accessToken'];

        if (accessToken) {
          socket.emit('auth', { accessToken });
        }
      });

      // On disconnect event
      socket.on('disconnect', () => {
        commit(mutations.CONNECTED_SET, false);
      });

      socket.on('alarm', async (payload) => {
        const { alarmId, changes, userId } = payload;

        // You archived alarm, so don't react to yourself actions
        if (userId === rootState.users.current.id) {
          return;
        }

        // Id is changed so this is new alarm
        if (changes.id) {
          dispatch('alerts/add', alarmId, { root: true });
        }

        // Alarm archived
        if (changes.status && changes.status.a === 'h') {
          dispatch('alerts/unset', alarmId, { root: true });
        }
      });

      socket.on('device', async (payload) => {
        if (payload.added === true) {
          dispatch('devices/add', { payload }, { root: true });
        }

        if (payload.added === false && payload.removed === false) {
          dispatch('devices/update', { payload }, { root: true });
        }

        if (payload.removed === true) {
          dispatch('devices/remove', { payload }, { root: true });
        }
      });

      socket.on('deviceOperators', async (payload) => {
        if (payload.added === true) {
          dispatch('deviceoperators/add', { payload }, { root: true });
        }
        if (payload.added === false && payload.removed === false) {
            dispatch('deviceoperators/update', { payload }, { root: true });
        }
        if (payload.removed === true) {
          dispatch('deviceoperators/remove', { payload }, { root: true });
        }
      });
    },
  },
};
