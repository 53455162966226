/**
 * Format message by replacing placeholder with given values
 * @param {string} message
 * @param {array} values
 */
export default function format(message, values) {
  let buffer = message;
  values.forEach((value) => {
    buffer = buffer.replace('%s', value);
  });

  return buffer;
}
