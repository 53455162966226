var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-devices-location"},[(_vm.isActivePage)?_c('bkf-page',{staticClass:"full-height",attrs:{"loading":_vm.loadingPage,"error":_vm.error}},[_c('div',{staticClass:"flex mt-3 full-height"},[_c('bkf-widget',{staticClass:"panel"},[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"offset":"5","cols":"1"}},[_c('bkf-action-refresh',{on:{"click":function($event){return _vm.getData()}}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$trans('action-button.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[(!_vm.siteLoading)?_c('v-data-table',{staticClass:"scrollable-table",style:(_vm.mapStyle),attrs:{"loading":_vm.siteLoading,"headers":_vm.columns,"items":_vm.devicesAsync,"footer-props":_vm.footerProps,"options":_vm.pagination,"server-items-length":_vm.totalItems,"single-expand":true,"item-key":"id"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("\n                  "+_vm._s(item.name)+"\n                  "),(item.deviceStatus.deviceLocation !== null)?_c('div',{staticClass:"caption blue-grey--text lighten-1"},[_vm._v("\n                    "+_vm._s(_vm.$deep(item, 'deviceStatus.deviceLocation.address'))+"\n                  ")]):_vm._e()])]}},{key:"item.deviceStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex py-2"},[_c('module-activity-indicator',{attrs:{"value":item.deviceStatus.online,"last-sync":_vm.$deep(item, 'deviceStatus.lastOnline')}}),_vm._v(" "),(item.deviceStatus.locationStatus !== null)?_c('location-indicator',{key:item.id,attrs:{"status":item.deviceStatus.locationStatus,"last-sync":item.deviceStatus.deviceLocation !== null
                      ? item.deviceStatus.deviceLocation.time : null},on:{"toggle-visibility":function($event){return _vm.toggleVisibilty(item)}}}):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(item.deviceStatus.deviceLocation !== null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleVisibilty(item)}}},[_c('v-icon',{class:{ 'primary--text': _vm.isDeviceShown(item.id) }},[_vm._v("\n                    mdi-check\n                  ")])],1):_vm._e()]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.siteLoading)?_c('v-progress-circular',{staticClass:"mb-8",attrs:{"color":"primary","size":"60","indeterminate":""}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('bkf-widget',{staticClass:"map ml-3"},[_c('div',{ref:"mapElement",staticClass:"mapa mx-n4",attrs:{"id":"devicesLocation"}})])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }