<template>
  <div class="w-100p flex">
    <v-input type="hidden" />
    <div
      id="map"
      class="w-100p h-300"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-editable';
import 'leaflet/dist/leaflet.css';

export default {
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      center: {
        lat: 51.919438,
        lon: 19.145136,
      },
      mapSource: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 5,
      polygonLayer: null,
      map: null,
    };
  },
  mounted() {
    this.initMap();
    this.createControls();
    this.onPolygonLayerCreate();
    this.onPolygonLayerCreated();

    if (this.value) {
      this.createPolygonLayer(JSON.parse(this.value).map((position) => [position[1], position[0]]));
    }
  },
  methods: {
    initMap() {
      const { center, zoom, mapSource } = this;

      this.map = L.map('map', { editable: true, attributionControl: false })
        .setView([center.lat, center.lon], zoom);
      L.tileLayer(mapSource).addTo(this.map);
    },
    createPolygonLayer(latlngs) {
      this.polygonLayer = L.polygon(latlngs);
      this.polygonLayer.addTo(this.map);
      this.map.fitBounds(new L.LatLngBounds(latlngs));
    },
    onPolygonLayerCreate() {
      const callback = () => {
        if (this.polygonLayer) {
          this.map.removeLayer(this.polygonLayer);
        }
      };

      this.map.on('editable:created', callback);
    },
    onPolygonLayerCreated() {
      this.map.on('editable:drawing:commit', (event) => {
        const poly = [];
        event.vertex.latlngs.forEach((position) => poly.push([position.lng, position.lat]));
        this.polygonLayer = event.layer;
        this.$emit('input', JSON.stringify(poly));
      });

      this.map.on('editable:drawing:start', () => {
        this.$emit('editing');
      });
    },
    createControls() {
      L.EditControl = L.Control.extend({
        options: {},
        onAdd(mapInstance) {
          const container = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
          const link = L.DomUtil.create('a', '', container);
          link.href = '#';
          link.title = t`map.area.create`;
          link.innerHTML = this.options.html;
          L.DomEvent.on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', () => {
              window.LAYER = this.options.callback.call(mapInstance.editTools);
            });

          return container;
        },
      });
      L.NewPolygonControl = L.EditControl.extend({
        options: {
          position: 'topleft',
          callback: this.map.editTools.startPolygon,
          kind: 'polygon',
          html: '<i class="material-icons pt-1">mode_edit</i>',
        },
      });
      this.map.addControl(new L.NewPolygonControl());
    },
  },
};
</script>

<style scoped lang="stylus" src="./GeoPolygon.styl"></style>
