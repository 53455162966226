<template>
  <div>
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        v-if="!$vuetify.breakpoint.mobile"
        :options="otherDeviceOperatorGroups"
        placeholder="device-group.go-to-group"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div
        v-if="deviceOperatorGroup"
      >
        <div class="flex flex-wrap flex-justify-space-between flex-align-end">
          <bkf-widget>
            <template slot="title">
              {{ 'device-operator-group.details'|trans }}
            </template>
            <template slot="actions">
              <bkf-action-edit @click="edit" />
              <bkf-action-delete @click="remove" />
            </template>
            <v-list dense>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'device-operator-group.name'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ deviceOperatorGroup.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </bkf-widget>
          <group-stats
            :device-operator-group="deviceOperatorGroup"
            class="ml-3"
          />
          <assigned-device-operators
            class="mt-3"
            :device-operator-group="entry"
            :device-operator-bus="devicesOperatorBus"
            :settings="settings"
          />
        </div>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
      @deleted="deleted"
    >
      <delete
        slot="delete"
        @deleted="deleted"
        @deleteError="onError"
        @fetchError="onError"
        @device-unlinked="onDeviceOperatorUnlinked"
      />
    </bkf-crud>
  </div>
</template>

<script>
import Vue from 'vue';
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import AssignedDeviceOperators from './DeviceOperators/AssignedDeviceOperators.vue';
import Delete from './Delete.vue';
import GroupStats from './GroupStats.vue';

export default {
  components: {
    AssignedDeviceOperators,
    Delete,
    GroupStats,
  },
  mixins: [
    detailsMixin,
    pageMixin,
    propsMixin,
  ],
  data() {
    return {
      model: null,
      entry: null,
      deviceOperatorGroup: null,
      deviceOperatorGroups: [],
      otherDeviceOperatorGroups: [],
      settings: {
        deviceOperators: {
          page: 1,
          size: 5,
        },
      },
    };
  },
  created() {
    this.devicesOperatorBus = new Vue();
  },
  methods: {
    async onActivatePage() {
      this.model = await this.$model('DeviceOperatorGroup');

      const { entries } = await this.model.query({
        sort: { name: 'asc' },
      });

      this.deviceOperatorGroups = entries;
      this.entry = await this.model.get({
        id: Number(this.$route.params.id),
        contexts: ['deviceoperatorgroup_details'],
      });

      this.deviceOperatorGroup = this.entry.$data;
      this.otherDeviceOperatorGroups = this.deviceOperatorGroups.filter(
        (deviceOperatorGroup) => deviceOperatorGroup.id !== this.deviceOperatorGroup.id,
      );
    },
    onUpdated() {
      this.updated();
      this.reloadAlerts();
    },
    onError({ response }) {
      if (response.status === 404) {
        this.$notify.error('form.error.not-found');
      } else {
        this.$notify.error('form.error.internal-error');
      }
    },
    onDeviceOperatorUnlinked(deviceOperator) {
      this.devicesOperatorBus.$emit('unassign-device-operator', deviceOperator.id);
    },
  },
};
</script>
