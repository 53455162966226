<template>
  <v-tooltip
    bottom
    absolute
  >
    <template #activator="{ on, attrs }">
      <trend
        v-bind="attrs"
        :width="width"
        :height="32"
        :stroke-width="2"
        :data="trend"
        :gradient="['#2196f3', '#00c853']"
        smooth
        v-on="on"
      />
    </template>
    <span> {{ 'device.indicator.work-trend'|trans }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [Object, Array],
      default: null,
    },
    width: {
      required: false,
      type: Number,
      default: 100,
    },
  },
  computed: {
    trend() {
      const { value } = this;

      if (value === null || value === undefined) {
        return [];
      }

      return Object.keys(value).sort().map((key) => value[key].work || 0);
    },
  },
};
</script>
