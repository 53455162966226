<template>
  <div class="chart-container">
    <chart
      v-if="render"
      ref="chart"
      :options="getChartOptions"
      :autoresize="true"
    />
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/axisPointer';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/toolbox';

import dateTime from '@utils/date';
import deep from '@utils/deep';
import colors, { swatches } from './colors';

export default {
  components: {
    chart: ECharts,
  },
  mixins: [colors],
  props: {
    data: {
      type: Array,
      required: true,
    },
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      render: true,
      echart: {
        grid: {
          top: 80,
          right: 50,
          left: 30,
          bottom: 20,
          containLabel: true,
        },
        toolbox: {
          show: true,
          itemGap: 10,
          itemSize: 20,
          feature: {
            restore: {
              title: t`chart.restore`,
            },
            dataZoom: {
              title: {
                zoom: t`chart.area-zooming`,
                back: t`chart.restore-area-zooming`,
              },
            },
            saveAsImage: {
              title: t`chart.save-as-image`,
            },
            mySaveAsCSV: {
              show: true,
              title: t`chart.save-as-csv`,
              icon: 'path://M0,0h10l4,4v16h-14v-20M10,0v4h4M4,8l6,8m0,-8l-6,8',
              onclick: this.toCSV,
            },
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: t`chart.line-chart`,
                bar: t`chart.bar-chart`,
              },
              option: {
                bar: {
                  animationEasing: 'elasticOut',
                  animationDelay: (idx) => idx * 10,
                },
                line: {
                  animationEasing: 'linear',
                },
              },
            },
          },
        },
        legend: {
          data: [],
          top: 40,
          type: 'scroll',
        },
        tooltip: {
          confine: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: null,
          },
          axisLine: {
            show: false,
          },
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }],
        series: [],
        animationDuration: 300,
        color: colors[0][swatches[2]],
      },
    };
  },
  computed: {
    getChartOptions() {
      const { echart } = this;
      const xAxis = [];
      const yAxis = [];
      const lines = [];
      const unit = deep(this.alert.alertRule.conditions[0], 'parameter.unit.short_name') || '';
      const parameterName = deep(this.alert.alertRule.conditions[0], 'parameter.name') || '';

      this.data.forEach((element) => {
        xAxis.push(dateTime(element.timestamp * 1000));
        yAxis.push(element.data.value);
      });

      echart.yAxis.axisLabel.formatter = (value) => `${value} ${unit}`;
      echart.tooltip.formatter = (params) => {
        const {
          marker, seriesName, value, componentType,
        } = params;
        let buffer = `${marker} ${seriesName}: ${Math.round(value * 100) / 100} ${unit}`;
        const title = params.name;

        if (componentType === 'markLine') {
          buffer = `${parameterName}: ${Math.round(value * 100) / 100} ${unit}`;
          return `${buffer}`;
        }

        return `${title}<br>${buffer}`;
      };

      this.alert.conditions.forEach((condition) => {
        lines.push({
          yAxis: parseFloat(condition.argument),
          itemStyle: { color: colors[5][swatches[1]] },
        });
      });

      echart.xAxis.data = xAxis;
      echart.series = [
        {
          name: parameterName,
          type: 'bar',
          data: yAxis,
          markLine: {
            data: lines,
            label: {
              show: true,
              formatter: ({ value }) => `${value} ${unit}`,
            },
          },
        },
      ];

      return echart;
    },
  },
};
</script>

<style>
.echarts {
  width: 100%;
  min-height: 400px;
}
</style>
