<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="40%"
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }} - {{ $deep(device, 'name') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="onClose()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <div
          v-show="fetching"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <v-form
          v-show="!fetching"
          ref="formEdit"
          v-model="form.valid"
          :disabled="submitting"
          lazy-validation
        >
          <v-text-field
            v-model="device.name"
            prepend-icon="mdi-label-outline"
            :label="$trans('device.name')"
            :rules="rules.required"
          />
          <v-autocomplete
            v-model="device.deviceType.id"
            class="mt-4"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-engine-outline"
            :loading="deviceTypeIsLoading"
            :search-input.sync="searchDeviceType"
            :label="$trans('device.type')"
            :items="deviceTypesOptions"
            :autocomplete="true"
            :rules="rules.required"
          />
          <v-autocomplete
            v-model="device.sensors[0].id"
            class="mt-4"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-memory"
            :loading="sensorIsLoading"
            :search-input.sync="sensorSearch"
            :label="$trans('device.assigned-sensors')"
            :items="sensorOptions"
            :rules="rules.required"
            :autocomplete="true"
          />
          <v-autocomplete
            v-model="device.owner.id"
            class="mt-4"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-account-group"
            :loading="ownerIsLoading"
            :search-input.sync="ownerSearch"
            :label="$trans('sensor.owner')"
            :items="ownerOptions"
            :autocomplete="true"
            :rules="rules.required"
          />
          <v-textarea
            v-model="device.description"
            :label="$trans('users.note')"
            counter
            maxlength="120"
            full-width
            single-line
            prepend-icon="mdi-text-box-outline"
          />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="device.subscriptionExpire"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="device.subscriptionExpire"
                :label="$trans('users.subscription-expire')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="device.subscriptionExpire"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                {{ 'dialog.cancel'|trans }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(device.subscriptionExpire)"
              >
                {{ 'dialog.save'|trans }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-spacer />
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          :loading="submitting"
          :disabled="!form.valid"
          color="primary"
          @click="submit"
        >
          {{ 'dialog.update'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import debounce from 'lodash/debounce';
import { format } from 'date-fns';

export default {
  props: {
    deviceId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    context: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: [
          (v) => !!v || this.$trans('productionview.required'),
        ],
        email: [
          (v) => !!v || this.$trans('productionview.required'),
          (v) => /.+@.+\..+/.test(v) || this.$trans('users.email-invalid'),
        ],
      },
      menu: false,
      form: {
        valid: false,
      },
      maxElements: 5,
      ownerIsLoading: false,
      ownerSearch: null,
      ownerOptions: [],
      sensorIsLoading: false,
      sensorSearch: null,
      sensorOptions: [],
      deviceTypeIsLoading: false,
      searchDeviceType: null,
      deviceTypesOptions: [],
      device: {
        id: null,
        name: null,
        owner: {
          id: 0,
          name: '',
        },
        deviceType: {
          id: 0,
          name: '',
        },
        sensors: [{
          id: 0,
          name: '',
        }],
        description: null,
        subscriptionExpire: null,
      },
      showDialog: false,
      fetching: false,
      submitting: false,
      entry: null,
      options: {},
    };
  },
  watch: {
    async searchDeviceType(val) {
      if (val !== null) {
        await this.getDeviceTypesDebounced(val);
      }
    },
    async sensorSearch(val) {
      if (val !== null) {
        await this.getSensorsDebounced(val);
      }
    },
    async ownerSearch(val) {
      if (val !== null) {
        await this.getOwnersDebounce(val);
      }
    },
    showDialog: {
      async handler(newValue) {
        this.fetching = true;
        if (newValue === true) {
          await this.onOpen();
        }

        if (newValue === false) {
          await this.onClose();
        }
      },
    },
  },
  created() {
    this.siteLoading = true;
    this.getDeviceTypesDebounced = debounce((val) => {
      this.getDeviceTypes(val);
    }, 1000);

    this.getSensorsDebounced = debounce((val) => {
      this.getSensors(val);
    }, 1000);

    this.getOwnersDebounce = debounce((val) => {
      this.getOwners(val);
    }, 1000);
  },
  methods: {
    async submitChanges() {
      if (this.$refs.formEdit.validate()) {
        this.submitting = true;
        const { status } = await this.$http.post(
          `/admin/device/${this.deviceId}`,
          {
            name: this.device.name,
            description: this.device.description,
            deviceType: { id: this.device.deviceType.id },
            sensor: { id: this.device.sensors[0].id ?? 0 },
            owner: { id: this.device.owner.id },
            subscriptionExpire: this.device.subscriptionExpire,
          },
        );

        if (status === 200) {
          this.showDialog = false;
          this.$emit('on-change');
        }
        this.submitting = false;
      }
    },
    async getOwners(search) {
      this.ownerIsLoading = true;

      const { data, status } = await this.$http.get(
        '/admin/user_groups',
        {
          page: 1,
          perPage: this.maxElements,
          search,
        },
      );

      if (status === 200) {
        this.ownerOptions = data.data;
      }

      this.ownerIsLoading = false;
    },
    async getSensors(search) {
      this.sensorIsLoading = true;

      const { data, status } = await this.$http.get(
        '/admin/sensors',
        {
          page: 1,
          perPage: this.maxElements,
          search,
        },
      );

      if (status === 200) {
        this.sensorOptions = data;
      }

      this.sensorIsLoading = false;
    },
    async getDeviceTypes(search) {
      this.deviceTypeIsLoading = true;

      const { data, status } = await this.$http.get(
        '/admin/device_types',
        {
          page: 1,
          perPage: this.maxElements,
          search,
        },
      );

      if (status === 200) {
        this.deviceTypesOptions = data.data;
      }

      this.deviceTypeIsLoading = false;
    },
    async getData() {
      const { data, status } = await this.$http.get(`/admin/device/${this.deviceId}`);

      if (status === 200) {
        if (typeof data.deviceType !== 'undefined') {
          this.deviceTypesOptions = [
            {
              id: data.deviceType.id,
              name: data.deviceType.name,
            },
          ];
        }
        if (data.owner !== null) {
          this.ownerOptions = [
            {
              id: data.owner.id,
              name: data.owner.name,
            },
          ];
        }
        if (data.sensors.length > 0) {
          this.sensorOptions = [
            {
              id: data.sensors[0].id,
              name: data.sensors[0].name,
            },
          ];
        } else {
          data.sensors = [
            {
              id: null,
              name: '',
            },
          ];
          this.getSensors();
        }
        this.device = Object.assign(this.device, data);
        if (this.device.subscriptionExpire !== null) {
          this.device.subscriptionExpire = format(this.device.subscriptionExpire, 'YYYY-MM-DD');
        }
      }
      this.fetching = false;
    },
    submit() {
      this.submitChanges();
    },
    async onOpen() {
      this.fetching = true;
      await this.getData();
    },
    onClose() {
      this.device = {
        id: null,
        name: null,
        deviceType: {
          id: 0,
          name: '',
        },
        owner: {
          id: 0,
          name: '',
        },
        sensors: [{
          id: 0,
          name: '',
        }],
        description: null,
        subscriptionExpire: null,
      };
      this.showDialog = false;
    },
  },
};
</script>
