<template>
  <v-autocomplete
    :value="deviceGroupsIds"
    :label="$trans('report.field.device-groups')"
    :clearable="true"
    :multiple="true"
    :items="deviceGroups"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    @input="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
  },
  computed: {
    deviceGroupsIds() {
      return this.value.map((group) => group.id);
    },
  },
  methods: {
    emitInput(deviceGroupsIds) {
      const g = deviceGroupsIds.map((id) => this.deviceGroups.find((group) => group.id === id));
      this.$emit('input', g);
    },
  },
};
</script>
