// Import custom filters
import { date, dateTime, time } from './date';
import deep from './deep';
import lower from './lower';
import human from './human';
import trans from './trans';

// Export filters as plugin to install
export default {
  install: (Vue) => [
    Vue.filter('date', date),
    Vue.filter('deep', deep),
    Vue.filter('dateTime', dateTime),
    Vue.filter('lower', lower),
    Vue.filter('human', human),
    Vue.filter('time', time),
    Vue.filter('trans', trans),
  ],
};
