import {
  blue,
  green,
  orange,
  brown,
  indigo,
  red,
  yellow,
} from 'material-colors';

export const swatches = [
  '900',
  '700',
  '500',
  '300',
];

export default [
  blue,
  green,
  orange,
  brown,
  indigo,
  red,
  yellow,
];
