<template>
  <div>
    <div class="flex flex-column">
      <div class="flex flex-justify-end">
        <v-btn
          v-if="$refs.dialog"
          color="primary"
          tile
          @click="$refs.dialog.open"
        >
          {{ 'device-operator.assign-devices'|trans }}
        </v-btn>
      </div>
    </div>
    <template v-if="loaded">
      <v-data-table
        v-if="linkedDevices.length"
        :headers="columns"
        :items="linkedDevices"
        :items-per-page="5"
      >
        <template #[`item.active`]="{ item }">
          <module-activity-indicator
            class="py-2"
            :value="$deep(item, 'active')"
          />
        </template>
        <template #[`item.actions`]="{ item }">
          <bkf-action-delete @click="unlink([item.id])" />
        </template>
      </v-data-table>
      <v-alert
        v-else
        type="info"
        class="mt-0"
      >
        {{ 'device-operator.assinged-devices-message'|trans }}
        <br>
        {{ 'device-operator.no-assigned-devices'| trans }}
      </v-alert>
    </template>
    <assign-dialog
      ref="dialog"
      :devices="unlinkedDevices"
      :device-groups="deviceGroups"
      @submit="link"
    />
  </div>
</template>

<script>
import { without } from 'ramda';
import { mapGetters } from 'vuex';
import AssignDialog from './AssignDeviceDialog.vue';

export default {
  components: {
    AssignDialog,
  },
  props: {
    deviceOperator: {
      type: Object,
      required: true,
    },
    devicesBus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      linkedDevicesIds: [],
      config: {
        multiSelect: false,
      },
      columns: [{
        value: 'name',
        text: t`device-operator.device-name`,
      }, {
        value: 'actions',
        text: '',
        align: 'right',
        sortable: false,
      }],
      deviceGroups: [],
    };
  },
  computed: {
    linkedDevices() {
      return this.devices.filter(
        (device) => this.linkedDevicesIds.includes(device.id),
      );
    },
    unlinkedDevices() {
      return this.devices.filter(
        (device) => !this.linkedDevicesIds.includes(device.id),
      );
    },
    ...mapGetters({
      devices: 'devices/devices',
    }),
  },
  created() {
    this.devicesBus.$on('unassign-device', this.onUnassign);
  },
  async mounted() {
    this.linkedDevicesIds = this.deviceOperator.devices.map((g) => g.id);
    this.getDeviceGroups();
    this.loaded = true;
  },
  methods: {
    redirect({ id }) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async link(ids) {
      const { deviceOperator } = this;
      const route = `/deviceoperators/${deviceOperator.id}/devices/${ids.join(',')}/assign`;
      const { data, status } = await this.$http.patch(route);
      if (status === 200) {
        await deviceOperator.sync();
        this.linkedDevicesIds.push(...ids);
        this.$notify.success(t`device-operator.assigned-devices`);
      } else {
        this.$notify.error(`${t`device.max-workers`} ${t`device.for-device`}: ${data.error}`);
      }
    },
    async unlink(ids) {
      const { deviceOperator } = this;
      const route = `/deviceoperators/${deviceOperator.id}/devices/${ids.join(',')}/unassign`;
      await this.$http.patch(route);
      await deviceOperator.sync();
      this.linkedDevicesIds = without(ids, this.linkedDevicesIds);
      this.$notify.success(t`device-operator.unassigned-device`);
    },
    onUnassign(...id) {
      this.linkedDevicesIds = without(id, this.linkedDevicesIds);
    },
    async getDeviceGroups() {
      const model = await this.$model('DeviceGroup', { contexts: ['default'] });
      const { entries } = await model.query({
        sort: { name: 'ASC' },
      });

      this.deviceGroups = entries.map((entry) => entry.$data);
    },
  },
};
</script>
