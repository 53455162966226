<template>
  <bkf-icon :class="state">
    <v-tooltip
      v-if="content"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          size="32"
          :color="color"
          v-bind="attrs"
          v-on="on"
        >
          mdi-power-plug-outline
        </v-icon>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
export default {
  props: {
    state: {
      default: null,
      required: false,
      type: String,
    },
    level: {
      default: null,
      required: false,
      type: [Number, String],
    },
  },
  computed: {
    color() {
      if (this.state === 'icon-ok') {
        return '#00c853';
      }

      if (this.state === 'icon-warning') {
        return '#e3ac34';
      }

      if (this.state === 'icon-error') {
        return '#ff5252';
      }

      return '#bdbdbd';
    },
    content() {
      return this.$trans(
        'device.indicator.supply-voltage',
        this.state === null ? t`device.indicator.no-data` : `${this.level}V`,
      );
    },
  },
};
</script>
