<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="40%"
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }} {{ $deep(user, 'email') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="onClose()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <div
          v-show="fetching"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <v-form
          v-show="!fetching"
          ref="form"
          v-model="form.valid"
          :disabled="submitting"
          lazy-validation
        >
          <v-text-field
            v-model="user.firstname"
            :label="$trans('users.firstname')"
            :rules="rules.required"
          />
          <v-text-field
            v-model="user.lastname"
            :label="$trans('users.lastname')"
            :rules="rules.required"
          />
          <v-text-field
            v-model="user.email"
            :label="$trans('users.email')"
            :rules="rules.email"
          />
          <v-autocomplete
            v-model="userGroupId"
            item-value="id"
            item-text="name"
            :search-input.sync="searchUserGroup"
            :loading="userGroupsIsLoading"
            :label="$trans('users.group_name')"
            :items="userGroupsOptions"
            :autocomplete="true"
            :rules="rules.required"
          />
          <v-checkbox
            v-model="user.extras.isSuperAdmin"
            class="mt-0"
            :label="$trans('users.super_admin')"
            hide-details
          />
          <v-autocomplete
            v-model="user.language.locale"
            class="mt-4"
            item-value="locale"
            item-text="name"
            :label="$trans('users.language')"
            :items="languageOptions"
            :rules="rules.required"
          />
          <v-textarea
            v-model="user.notes"
            :label="$trans('users.note')"
            counter
            maxlength="120"
            full-width
            single-line
          />
        </v-form>
      </v-card-text>
      <v-spacer />
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          :loading="submitting"
          :disabled="!form.valid"
          color="primary"
          @click="submit"
        >
          {{ 'dialog.add'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    context: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: [
          (v) => !!v || this.$trans('productionview.required'),
        ],
        email: [
          (v) => !!v || this.$trans('productionview.required'),
          (v) => /.+@.+\..+/.test(v) || this.$trans('users.email-invalid'),
        ],
      },
      form: {
        valid: false,
      },
      searchUserGroup: null,
      maxElements: 5,
      languageOptions: [
        {
          locale: 'pl',
          name: 'Polski',
        },
        {
          locale: 'en',
          name: 'English',
        },
        {
          locale: 'fr',
          name: 'Français',
        },
        {
          locale: 'ru',
          name: 'Русский',
        },
        {
          locale: 'se',
          name: 'Svenska',
        },
      ],
      user: {
        firstname: null,
        lastname: null,
        email: null,
        notes: null,
        userGroup: {
          id: null,
          name: '',
        },
        extras: {
          isSuperAdmin: null,
        },
        language: {
          locale: 'pl',
        },
      },
      userGroupId: null,
      userGroupsOptions: [],
      userGroupsIsLoading: true,
      showDialog: false,
      fetching: false,
      submitting: false,
      entry: null,
      options: {},
    };
  },
  watch: {
    async searchUserGroup(val) {
      if (val !== null) {
        await this.getUserGroupOptions(val);
      }
    },
    showDialog: {
      async handler(newValue) {
        this.fetching = true;
        if (newValue === true) {
          await this.onOpen();
        }

        if (newValue === false) {
          await this.onClose();
        }
      },
    },
  },
  methods: {
    async submitChanges() {
      this.submitting = true;
      const { status } = await this.$http.post(
        '/admin/users',
        {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          language: this.user.language,
          note: this.user.note,
          userGroupId: this.userGroupId,
          superAdmin: this.user.extras.isSuperAdmin,
          notes: this.user.notes,
        },
      );

      if (status === 200) {
        this.showDialog = false;
        this.$emit('on-change');
      }
      this.submitting = false;
    },
    async getUserGroupOptions(search) {
      this.userGroupsIsLoading = true;

      const { data, status } = await this.$http.get(
        '/admin/user_groups',
        {
          context: 'user:edit',
          page: 1,
          perPage: this.maxElements,
          search,
        },
      );

      if (status === 200) {
        this.userGroupsOptions = data.data.map((userGroup) => ({
          id: userGroup.id,
          name: userGroup.name,
        }));
        this.userGroupsIsLoading = false;
      }
      this.fetching = false;
    },
    submit() {
      this.submitChanges();
      // this.$refs.form.submit();
    },
    async onOpen() {
      this.fetching = true;
      await this.getUserGroupOptions();
      this.$refs.form.validate();
      // await this.getData();
    },
    onClose() {
      this.$emit('close');
      // await this.$sleep(500);
      this.user = {
        userGroup: {
          id: 0,
          name: '',
        },
        extras: {
          isSuperAdmin: null,
        },
        language: {
          locale: 'en',
        },
        notes: null,
      };
      this.userGroupId = null;
      this.showDialog = false;
    },
  },
};
</script>
