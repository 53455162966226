<template>
  <div>
    <div
      class="loader"
    >
      <div>
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </div>
    <div
      v-if="loaded"
      class="loaderItem"
    >
      <div class="loading">
        {{ 'application.loader.title'|trans }}
      </div>
      <div class="loading-name">
        {{ `application.loader.${loading}`|trans }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      title: 'Loading',
    };
  },
  computed: {
    loaded() {
      return this.loading !== null;
    },
    ...mapGetters([
      'loading',
    ]),
  },
};
</script>

<style scoped lang="stylus" src="./Loader.styl"></style>
