<template>
  <v-autocomplete
    :value="deviceOperatorsGroupIds"
    :label="$trans('report.field.device-operators-groups')"
    :clearable="true"
    :multiple="true"
    :items="deviceOperatorsGroup"
    chips
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    @input="emitInput"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    deviceOperatorsGroup: {
      required: true,
      type: Array,
    },
  },
  computed: {
    deviceOperatorsGroupIds() {
      return this.value.map((group) => group.id);
    },
  },
  methods: {
    emitInput(deviceOperatorsGroupIds) {
      const items = deviceOperatorsGroupIds.map(
        (id) => this.deviceOperatorsGroup.find((item) => item.id === id),
      );
      this.$emit('input', items);
    },
  },
};
</script>
