<template>
  <bkf-widget
    v-if="showWidget"
  >
    <div class="p-10 center-align">
      <slot
        name="icon"
      >
        <i class="material-icons-no-data">
          devices
        </i>
      </slot>
    </div>
    <div class="p-10 fs-20r center-align no-data-color">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div
      v-if="showMessage"
      class="p-10 fs-10r center-align no-data-color"
    >
      <slot
        name="message"
      >
        <span>
          {{ message }}
        </span>
      </slot>
    </div>
    <div
      class="p-10 fs-6r center-align"
    >
      <portal-target
        v-if="showButton"
        name="page-actions"
      />
    </div>
  </bkf-widget>
  <div v-else>
    <div class="p-10 center-align">
      <slot
        name="icon"
      >
        <i class="material-icons-no-data">
          devices
        </i>
      </slot>
    </div>
    <div class="p-10 fs-20r center-align no-data-color">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div
      v-if="showMessage"
      class="p-10 fs-10r center-align no-data-color"
    >
      <slot
        name="message"
      >
        <span>
          {{ message }}
        </span>
      </slot>
    </div>
    <div
      class="p-10 fs-6r center-align"
    >
      <portal-target
        v-if="showButton"
        name="page-actions"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => t`widget.empty-state.title`,
    },
    message: {
      type: String,
      default: () => t`widget.empty-state.message`,
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showWidget: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="stylus" src="./EmptyState.styl"></style>
