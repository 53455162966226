<template>
  <div class="configuration-message fs-12r p-20 center-align">
    {{ 'dashboard.configure-dashboard-message'|trans }}
  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="stylus" src="./ConfigurationMessage.styl"></style>
