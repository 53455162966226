<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    eager
    max-width="800"
    transform-origin="bottom center"
  >
    <v-card
      class="d-flex flex-column"
    >
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'settings.settings'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-tabs>
          <v-tab>
            {{ 'shifts.shifts'|trans }}
          </v-tab>
          <v-tab-item>
            <shifts-settings
              :loading="loading"
              :shifts.sync="shifts"
              @change="shiftsChanged"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :loading="loading"
          @click="submit"
        >
          {{ 'settings.save'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShiftsSettings from './ShiftsSettings';

export default {
  components: {
    ShiftsSettings,
  },
  data() {
    return {
      loading: true,
      shifts: [],
      errors: {},
      showDialog: false,
    };
  },
  computed: {
    showAvatar() {
      return this.avatar || this.user.avatar;
    },
  },
  methods: {
    async open() {
      this.errors = {};
      this.showDialog = true;

      const { data } = await this.$http.get('/user/production_shifts');

      data.forEach((obj) => {
        obj.index = `${this.uniqId()}-${obj.id}`;
      });

      this.shifts = data || this.shifts;
      this.loading = false;
    },
    close() {
      this.loading = false;
      this.showDialog = false;
    },
    uniqId() {
      return new Date().valueOf();
    },
    async submit() {
      this.loading = true;

      const route = '/user/production_shifts';
      const { data, status } = await this.$http.post(route, this.shifts);

      if (status === 400) {
        this.errors = data.error;
        this.loading = false;
      }

      if (status === 200) {
        this.errors = {};
        this.loading = true;
        this.close();
      }
    },
    shiftsChanged(shifts) {
      this.shifts = shifts;
    },
  },
};
</script>
