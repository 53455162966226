import palette from 'material-colors';

/**
 * Module responsible for providing material colors from themes in shortest way
 */
export default {
  namespaced: true,
  state: {
    primary: 'magenta',
    header: 'magenta',
  },
  getters: {
    primary: (s) => s.primary,
    header: (s) => s.header,
  },
  mutations: {
    COLORS_SET(state, { primary, header }) {
      const fromTheme = ({ color, hue }) => palette[color][hue || 500];

      if (primary) {
        state.primary = fromTheme(primary);
      }

      if (header) {
        state.header = fromTheme(header);
      }
    },
  },
  actions: {
    setColors({ commit }, { primary, header } = {}) {
      commit('COLORS_SET', { primary, header });
    },
  },
};
