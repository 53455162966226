import { get as getApi } from '@services/api';

export default {
  async install(Vue, { url }) {
    const api = getApi({
      baseUrl: url.api,
      loginUrl: url.login,
      monitors: [],
    });

    Vue.prototype.$bkf.http = api;
    Vue.prototype.$http = Vue.prototype.$bkf.http;
    Vue.http = Vue.prototype.$http;
  },
};
