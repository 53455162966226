<template>
  <bkf-dialog-entity-delete
    ref="delete"
    :title="title"
    :disabled="!!conditions.length"
    v-on="$listeners"
  >
    <div
      v-if="conditions.length"
      class="w-100p"
    >
      {{ 'device-operator.delete-conditions.unresolved'|trans }}:
      <div class="flex flex-justify-center flex-align-items-center mb-20">
        <bkf-action-previous-page
          :disabled="progress"
          @click="previousPage"
        />
        <div class="mt-2">
          {{ 'device-operator.delete-conditions.condition-of'|trans(page, pages) }}
        </div>
        <bkf-action-next-page
          :disabled="progress"
          @click="nextPage"
        />
      </div><template v-if="condition.type === 'device'">
        {{ 'device-operator.delete-conditions.device.message'|trans }}:
        <span class="fw-500">{{ condition.device.name }}</span>
        <div class="flex flex-justify-center">
          <bkf-tooltip :content="$trans('device-operator.delete-conditions.device.unassign-tip')">
            <v-btn
              color="primary"
              tile
              :disabled="progress"
              @click="unlinkDevice(condition.device)"
            >
              {{ 'device-operator.delete-conditions.device.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
        :class="{active: progress}"
      />
    </div>
    <div v-else>
      {{ 'device-operator.delete-confirm'|trans }}?
    </div>
  </bkf-dialog-entity-delete>
</template>

<script>
export default {
  props: {
    devicesBus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      index: 0,
      deviceOperator: null,
      devices: [],
      progress: false,
    };
  },
  computed: {
    title() {
      return t`device-operator.deleting`;
    },
    conditions() {
      const { devices } = this;
      const conditions = [];

      devices.forEach((device) => {
        conditions.push({
          type: 'device',
          device,
        });
      });

      return conditions;
    },
    condition() {
      const { conditions } = this;
      if (!conditions.length) {
        return null;
      }

      return conditions[this.index];
    },
    page() {
      return this.index + 1;
    },
    pages() {
      return this.conditions.length;
    },
  },
  methods: {
    open(deviceOperator, options = {}) {
      this.deviceOperator = deviceOperator;
      this.devices = deviceOperator.devices;
      this.progress = false;
      this.index = 0;
      this.$refs.delete.open(deviceOperator, options);
    },
    previousPage() {
      this.index = Math.max(0, this.index - 1);
    },
    nextPage() {
      this.index = Math.min(this.conditions.length - 1, this.index + 1);
    },
    async unlinkDevice(device) {
      const { deviceOperator } = this;
      this.progress = true;
      await this.$http.patch(`/deviceoperators/${deviceOperator.id}/devices/${device.id}/unassign`);
      this.devicesBus.$emit('unassign-device', device.id);
      await deviceOperator.sync();
      this.devices = deviceOperator.devices;
      this.$notify.success(t`device-operator.delete-conditions.device.unassigned`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  opacity: 0
  transition: opacity 300ms linear

.progress.active
  opacity: 1
</style>
