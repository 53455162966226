<template>
  <v-autocomplete
    :value="value"
    :items="reportsType"
    chips
    small-chips
    item-text="title"
    item-value="name"
    :label="$trans('report.field.types')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    reportsType: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: String,
    },
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
