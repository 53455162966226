<template>
  <indicator :state="state">
    <v-icon
      color="#00c853"
      :class="state"
    >
      access_time
    </v-icon>
    <template slot="description">
      <template v-if="state !== null">
        {{ value|human({
          ceilHours: true,
          labels: { hours: ' h' }
        }) }}
      </template>
      <template v-else>
        {{ 'device.indicator.no-data'|trans }}
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: Number,
      default: null,
    },
  },
  computed: {
    state() {
      const { value } = this;
      if (value === null || value === undefined) {
        return null;
      }

      if (value > 0) {
        return 'positive';
      }

      return 'negative';
    },
  },
};
</script>

<style>
.negative i{
  color: #ff5252 !important;
}
</style>
