<template>
  <bkf-card
    class="widget"
    :flat="flat"
  >
    <bkf-card-media v-if="$slots.media">
      <slot name="media" />
    </bkf-card-media>
    <bkf-card-header
      v-if="$slots.title || $slots.actions"
      class="flex-grow-0"
    >
      <slot
        v-if="$slots.title"
        name="title"
      />
      <template
        v-if="$slots.actions"
        slot="actions"
      >
        <slot name="actions" />
      </template>
    </bkf-card-header>
    <bkf-card-content
      v-if="$slots.default"
      class="content"
    >
      <slot />
    </bkf-card-content>
    <slot
      v-if="$slots.map"
      name="map"
    />
  </bkf-card>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="stylus" src="./Widget.styl"></style>
