<template>
  <indicator
    v-if="value === 'damaged'"
    :state="state"
  >
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          class="technical-status-icon"
          color="#ff5252"
          v-bind="attrs"
          v-on="on"
        >
          mdi-wrench-outline
        </v-icon>
      </template>
      <span>{{ 'device.technical-status'|trans }}: {{ `device.statuses.${value}`|trans }}</span>
    </v-tooltip>
    <template
      slot="description"
    >
      <span
        class="technical-status-damaged"
      > {{ `device.statuses.${value}`|trans }}</span>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    state() {
      const { value } = this;

      if (value === null || value === undefined) {
        return null;
      }

      if (value === 'operational') {
        return 'positive';
      }

      return 'negative';
    },
  },
};
</script>

<style>
.technical-status-icon {
  font-size: 28px !important;
}
.technical-status-damaged {
  color: #ff5252 !important;
}
</style>
