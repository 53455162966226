<template>
  <div>
    <v-form
      ref="stepThreeForm"
      v-model="valid"
    >
      <v-autocomplete
        v-model="resetHour"
        :items="items"
        :label="$trans('productionview.resethours')"
        :rules="resetRule"
        multiple
        required
      />
      <v-row no-gutters>
        <v-col
          :sm="editing || avatar?4:12"
        >
          <v-file-input
            id="file"
            accept="image/png, image/jpeg"
            :label="editing ?$trans('productionview.logo.change'):$trans('productionview.logo.set')"
            name="file"
            :rules="!fileBase ? fileRule : []"
            :clearable="false"
            @change="convertImage($event)"
          />
        </v-col>
        <v-col
          v-if="editing || avatar"
          :sm="editing || avatar?4:0"
          class="text-center hidden"
        >
          <v-img
            v-if="editing"
            :src="fileBase"
          />
          <v-img
            v-if="avatar"
            :src="avatar"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

export default {
  data: () => ({
    editing: false,
    resetHour: [],
    fileBase: '',
    avatar: '',
    items: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
    valid: true,
    resetRule: [
      (v) => !!v || t`productionview.required`,
      (v) => (v && v.length > 0) || t`productionview.required`,
    ],
    fileRule: [
      (v) => !!v || t`productionview.required`,
    ],
  }),
  methods: {
    convertImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const binaryData = e.target.result;
        this.avatar = e.target.result;
        this.editing = false;
        this.fileBase = window.btoa(binaryData);
      };
    },
    save() {
      if (this.$refs.stepThreeForm.validate()) {
        this.$emit('setstep3values', this.resetHour, this.fileBase);
        return true;
      }

      return false;
    },
    setValues(resetHours, logo) {
      this.editing = true;
      this.resetHour = resetHours;
      this.fileBase = logo;
    },
  },
};
</script>
