<template>
  <div>
    <portal
      v-if="isActivePage"
      to="page-actions"
    >
      <bkf-redirect
        v-if="!$vuetify.breakpoint.mobile"
        :options="sensors"
        placeholder="sensor.go-to-sensor"
      />
    </portal>
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-3"
    >
      <div v-if="sensor">
        <div class="flex flex-wrap flex-justify-space-between flex-align-end">
          <bkf-widget>
            <template slot="title">
              {{ 'sensor.details'|trans }}
            </template>
            <template slot="actions">
              <bkf-action-edit
                @click="edit"
              />
            </template>
            <v-list dense>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'sensor.name'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ sensor.name }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'sensor.type'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ sensor.sensorType.name }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'sensor.serial-number'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ sensor.sensorNumber }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="hmin-30">
                <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                  {{ 'sensor.subscription'|trans }}:
                </v-list-item-content>
                <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                  {{ getSubscriptionName }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </bkf-widget>
          <assigned-device
            :device-id="deviceId"
            class="ml-3"
          />
        </div>
      </div>
    </bkf-page>
    <bkf-crud
      ref="crud"
      @updated="updated"
    />
  </div>
</template>

<script>
import detailsMixin from '@mixins/page/details';
import pageMixin from '@mixins/page';
import propsMixin from '@mixins/router/props';
import AssignedDevice from './AssignedDevice.vue';

export default {
  components: {
    AssignedDevice,
  },
  mixins: [
    detailsMixin,
    pageMixin,
    propsMixin,
  ],
  data() {
    return {
      model: null,
      sensor: null,
      entry: null,
      loaded: false,
      sensors: [],
      deviceId: null,
    };
  },
  computed: {
    getSubscriptionName() {
      return this.sensor.subscription ? this.sensor.subscription.name : '-';
    },
  },
  methods: {
    async onActivatePage() {
      this.model = await this.$model('Sensor');
      const { entries } = await this.model.query({
        sort: { name: 'asc' },
      });
      this.sensors = entries;
      this.entry = await this.model.get({
        id: Number(this.$route.params.id),
        contexts: ['sensor_details'],
      });
      this.sensor = this.entry.$data;

      if (this.entry.device) {
        this.deviceId = this.entry.device.id;
      }
    },
    async updated() {
      await this.entry.sync();

      this.deviceId = this.entry.device
        ? this.deviceId = this.entry.device.id : this.deviceId = null;

      this.$notify.success(t`sensor.edited`);
    },
    onError({ response }) {
      if (response.status === 404) {
        this.$notify.error('form.error.not-found');
      } else {
        this.$notify.error('form.error.internal-error');
      }
    },
  },
};
</script>
