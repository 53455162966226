<template>
  <v-autocomplete
    :value="value"
    :items="intervals"
    chips
    small-chips
    item-text="name"
    item-value="id"
    :label="$trans('report.field.interval')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
    intervals: {
      required: true,
      type: Array,
    },
  },
  methods: {
    emitInput(intervalId) {
      this.$emit('input', intervalId);
    },
  },
};
</script>
