<template>
  <div>
    <v-alert
      type="info"
      class="mt-0"
    >
      {{ 'alert.rules.user-info'|trans }}
    </v-alert>
    <v-autocomplete
      v-model="value"
      :items="users"
      :multiple="true"
      chips
      small-chips
      deletable-chips
      item-text="name"
      item-value="id"
      :label="$trans('alert-rule.users.user')"
    />
  </div>
</template>

<script>
export default {
  props: {
    users: {
      required: true,
      type: Array,
    },
    alertRule: {
      required: true,
      type: Object,
    },
    currentUser: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hasFocus: false,
      value: [],
    };
  },
  mounted() {
    this.value.push(this.currentUser.id);
  },
  methods: {
    focus(state) {
      this.hasFocus = state;
    },
    async link() {
      const { alertRule } = this;
      const ids = this.value;
      await this.$http.patch(
        `/alertrules/${alertRule.id}/users/${ids.join(',')}/assign`,
      );
    },
  },
};
</script>
