<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device-group.assigned-devices'|trans }}
    </template>
    <template
      v-if="loaded"
      slot="actions"
    >
      <bkf-action-add
        :tooltip="$trans('action-button.assign')"
        @click="$refs.dialog.open"
      />
    </template>
    <template v-if="loaded">
      <v-data-table
        v-if="linkedDevices.length"
        :headers="columns"
        :items="linkedDevices"
        :item-class="rowClass"
        :items-per-page="settings.size"
        @click:row="handleClick"
      >
        <template #[`item.name`]="{ item }">
          <sensor-indicator
            :last-sync="item.diagnostics|deep('operator_last_active.date')"
          />
          {{ item.name }}
        </template>
        <template #[`item.diagnostics.active`]="{ item }">
          {{ getDeviceActiveTime(item)|human }}
        </template>
        <template #[`item.diagnostics.work`]="{ item }">
          {{ getDeviceWorkTime(item)|human }}
        </template>
        <template #[`item.productivity`]="{ item }">
          {{ getDeviceProductivity(item) }}%
        </template>
        <template #[`item.actions`]="{ item }">
          <bkf-action-delete @click="unlink([item.id])" />
        </template>
      </v-data-table>
      <v-alert
        v-else
        type="info"
        tile
        class="mt-0"
      >
        {{ 'device-group.no-devices'| trans }}
      </v-alert>
    </template>
    <div
      v-else
      class="pv-50 center-align"
    >
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <assign-dialog
      ref="dialog"
      :devices="unlinkedDevices"
      @submit="link"
    />

    <bkf-page-settings :settings.sync="componentSettings" />
  </bkf-widget>
</template>

<script>
import { without } from 'ramda';
import { mapGetters } from 'vuex';
import AssignDialog from './AssignDeviceDialog.vue';
import SensorIndicator from './SensorIndicator.vue';

export default {
  components: {
    AssignDialog,
    SensorIndicator,
  },
  props: {
    deviceGroup: {
      type: Object,
      required: true,
    },
    devicesBus: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      linkedDevicesIds: [],
      componentSettings: this.settings,
      config: {
        multiSelect: false,
      },
      columns: [
        {
          value: 'name',
          text: t`device-group.name`,
        },
        {
          value: 'diagnostics.active',
          text: t`device-group.activity`,
        },
        {
          value: 'diagnostics.work',
          text: t`device-group.work`,
        },
        {
          value: 'productivity',
          text: t`device-group.productivity`,
        },
        {
          value: 'actions',
          text: '',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    linkedDevices() {
      const devices = this.devices.filter(
        (device) => this.linkedDevicesIds.includes(device.id),
      );

      const modifiedDevices = [];

      devices.forEach((device) => {
        device.productivity = this.getDeviceProductivity(device);
        modifiedDevices.push(device);
      });

      return modifiedDevices;
    },
    unlinkedDevices() {
      return this.devices.filter(
        (device) => !this.linkedDevicesIds.includes(device.id),
      );
    },
    ...mapGetters({
      devices: 'devices/devices',
    }),
  },
  created() {
    this.devicesBus.$on('unassign-device', this.onUnassign);
  },
  async mounted() {
    this.linkedDevicesIds = this.deviceGroup.devices.map((g) => g.id);
    this.loaded = true;
  },
  methods: {
    rowClass() {
      return 'clickable';
    },
    handleClick({ id }) {
      this.$router.push({ name: 'device', params: { id } });
    },
    async link(ids) {
      const { deviceGroup } = this;
      this.loaded = false;
      await this.$http.patch(`/devicegroups/${deviceGroup.id}/devices/${ids.join(',')}/assign`);
      await deviceGroup.sync();
      this.linkedDevicesIds.push(...ids);
      this.$notify.success(t`device-group.assigned-devices`);
      this.loaded = true;
    },
    async unlink(ids) {
      const { deviceGroup } = this;
      this.loaded = false;
      await this.$http.patch(`/devicegroups/${deviceGroup.id}/devices/${ids.join(',')}/unassign`);
      await deviceGroup.sync();
      this.linkedDevicesIds = without(ids, this.linkedDevicesIds);
      this.$notify.success(t`alert-rule.unassign-device`);
      this.loaded = true;
    },
    onUnassign(...id) {
      this.linkedDevicesIds = without(id, this.linkedDevicesIds);
    },
    getDeviceActiveTime(row) {
      return row?.diagnostics?.active;
    },
    getDeviceWorkTime(row) {
      return row?.diagnostics?.work;
    },
    getDeviceProductivity(row) {
      const activity = row.diagnostics.active;

      if (activity === 0 || activity === null) {
        return 0;
      }

      return Math.round(row.diagnostics.work / activity * 100);
    },
  },
};
</script>
