<template>
  <div class="page-alert">
    <bkf-page
      :loading="loading"
      :error="error"
      class="pt-6"
    >
      <div
        v-if="alert"
        class="flex flex-wrap flex-justify-space-between flex-align-end mt-n2"
      >
        <bkf-widget>
          <template slot="title">
            {{ 'alert.details'|trans }}
          </template>
          <template slot="actions">
            <bkf-action
              :tooltip="$trans('alert.archive')"
              @click="archiveAlert"
            >
              <v-icon>mdi-check</v-icon>
            </bkf-action>
          </template>
          <v-list dense>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.creation-time'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ alert.ctime|dateTime }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.last-occurence-time'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ lastOccurence|dateTime }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.counter'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                {{ alert.counter }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.device'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  tooltip="alert.go-to-device"
                  @click="onDeviceClick"
                >
                  {{ alert|deep('device.name') }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.rule'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <bkf-action-launch
                  tooltip="alert.go-to-alert-rule"
                  @click="onAlertDefinitionClick"
                >
                  {{ alert|deep('alertRule.name') }}
                </bkf-action-launch>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="hmin-30 pl-0">
              <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
                {{ 'alert.importance'|trans }}:
              </v-list-item-content>
              <v-list-item-content class="flex-grow-1 text-body-2 py-1">
                <span :class="getImportanceClass(alert.importance)">
                  <bkf-tooltip :content="getImportanceTooltip(alert.importance)">
                    <v-icon
                      small
                      :class="getImportanceClass(alert.importance)"
                    >
                      mdi-bell
                    </v-icon>
                  </bkf-tooltip>
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <conditions
            class="mt-4"
            :alert-rule="alertRuleEntry"
          />
        </bkf-widget>
        <bkf-widget class="ml-4">
          <template slot="title">
            {{ 'alert.occurrences-details'|trans }}
          </template>
          <gps-position
            v-if="alert.alertRule && alert.alertRule.type === 'geolocation'"
            v-model="alert"
          />
          <alert-graph
            v-else-if="alert.alertRule && alert.alertRule.type === 'value'"
            :data="alert.data"
            :alert="alert"
          />
          <alert-data
            v-else
            :data="alert.data"
            :alert="alert"
          />
        </bkf-widget>
      </div>
    </bkf-page>
  </div>
</template>

<script>
import {
  compose, last, multiply, prop,
} from 'ramda';
import { mapGetters, mapActions } from 'vuex';
import pageMixin from '@mixins/page';
import importances from '@globals/alerts/importances';
import Conditions from './Conditions.vue';
import AlertData from './AlertData';
import GpsPosition from './GpsPosition';
import AlertGraph from './AlertGraph';

const lastTimestamp = compose(multiply(1000), prop('timestamp'), last);

export default {
  components: {
    Conditions,
    AlertData,
    GpsPosition,
    AlertGraph,
  },
  mixins: [pageMixin],
  data() {
    return {
      model: null,
      entry: null,
      alert: null,
      alertRuleModel: null,
      alertRuleEntry: null,
    };
  },
  computed: {
    lastOccurence() {
      const { data } = this.alert;

      if (data && data.length) {
        return new Date(lastTimestamp(data));
      }

      return null;
    },
    ...mapGetters({
      alerts: 'alerts/alerts',
    }),
  },
  methods: {
    async onActivatePage() {
      this.model = await this.$model('Alert');

      this.entry = await this.model.get({
        id: Number(this.$route.params.id),
        contexts: ['alert_details'],
      });

      this.alert = this.entry.$data;

      this.alertRuleModel = await this.$model('AlertRule');
      this.alertRuleEntry = await this.alertRuleModel.get({
        id: Number(this.alert.alertRule.id),
        contexts: ['alert_details'],
      });
    },
    async archiveAlert() {
      const { alert } = this;
      await this.archive({ alert });
      this.$notify.success(t`alert.archived`);
      this.$router.push({ name: 'alerts-list' });
    },
    ...mapActions({
      archive: 'alerts/archive',
    }),
    onDeviceClick() {
      const { id } = this.alert.device;
      this.$router.push({ name: 'device', params: { id } });
    },
    onAlertDefinitionClick() {
      const { id } = this.alert.alertRule;
      this.$router.push({ name: 'alert-rule', params: { id } });
    },
    getImportanceClass: (level) => importances[level].name,
    getImportanceTooltip: (level) => t`alert.weight.${importances[level].name}`,
  },
};
</script>

<style lang="stylus" scoped>
  .v-list-item .critical
    fill: #f44336;
    background-color: transparent !important
  .v-list-item .warning
    fill: #fbc02d;
    background-color: transparent !important
  .v-list-item .info
    fill: #61a5f4;
    background-color: transparent !important
</style>
