<template>
  <v-autocomplete
    :value="selectedid"
    :items="parameters"
    :disabled="disabled"
    :multiple="false"
    :chips="false"
    :small-chips="false"
    :deletable-chips="false"
    :item-text="item => item.name?item.name.slice(0, 30):`name`"
    item-value="number"
    :label="$trans('productionview.parameter')"
    single-line
    :rules="parametersRule"
    @change="emitInput"
  />
</template>

<script>
import parameterTrans from '@mixins/misc/parameterTrans';

export default {
  mixins: [parameterTrans],
  props: {
    label: {
      required: true,
      type: String,
    },
    disabled: {
      required: true,
      type: Boolean,
      default: true,
    },
    parameters: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
      type: Array,
    },
    selectedid: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    parametersRule: [
      (v) => !!v || t`productionview.required`,
    ],
  }),
  methods: {
    emitInput(number) {
      this.$emit('input', [number]);
    },
  },
};
</script>
