import BatteryChargeIndicator from '@components/domains/device/indicators/BatteryCharge';
import BatteryVoltageIndicator from '@components/domains/device/indicators/BatteryVoltage';
import EnabledTimeIndicator from '@components/domains/device/indicators/EnabledTime';
import EnabledTimeLastActiveIndicator from '@components/domains/device/indicators/EnabledTimeLastActive';
import ModuleActivityIndicator from '@components/domains/device/indicators/ModuleActivity';
import WorkTimeIndicator from '@components/domains/device/indicators/WorkTime';
import WorkTimeLastActiveIndicator from '@components/domains/device/indicators/WorkTimeLastActive';
import WorkTrendIndicator from '@components/domains/device/indicators/WorkTrend';
import OperatorIndicator from '@components/domains/device/indicators/Operator';
import OperatorLastActiveIndicator from '@components/domains/device/indicators/OperatorLastActive';
import SupplyVoltageIndicator from '@components/domains/device/indicators/SupplyVoltage';
import TechnicalStatusIndicator from '@components/domains/device/indicators/TechnicalStatus';
import MotorHours from '@components/domains/device/indicators/MotorHours';
import LocationIndicator from '@components/domains/device/indicators/Location';

export default {
  components: {
    BatteryChargeIndicator,
    BatteryVoltageIndicator,
    EnabledTimeIndicator,
    EnabledTimeLastActiveIndicator,
    ModuleActivityIndicator,
    WorkTimeIndicator,
    WorkTimeLastActiveIndicator,
    WorkTrendIndicator,
    OperatorIndicator,
    OperatorLastActiveIndicator,
    SupplyVoltageIndicator,
    TechnicalStatusIndicator,
    MotorHours,
    LocationIndicator,
  },
};
