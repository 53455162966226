<template>
  <indicator :state="state">
    <bkf-icon-enabled-time
      :seconds="value"
      :state="state"
    />
    <template slot="description">
      <template v-if="state !== null">
        {{ value|human }}
      </template>
      <template v-else>
        {{ 'device.indicator.no-data'|trans }}
      </template>
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    value: {
      required: false,
      type: Number,
      default: null,
    },
    isActive: {
      required: false,
      type: Boolean,
      default: null,
    },
  },
  computed: {
    state() {
      const { value, isActive } = this;

      if (value === null || value === undefined) {
        return null;
      }

      if (isActive !== undefined) {
        if (isActive === false) {
          return 'negative';
        }

        if (isActive === true) {
          return 'positive';
        }
      }

      if (value > 0) {
        return 'positive';
      }

      return 'negative';
    },
  },
};
</script>
