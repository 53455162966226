/**
 * Mixin that defines simple input emmiter method
 */
export default {
  methods: {
    emitInput(event) {
      this.$emit('input', event);
    },
  },
};
