<template>
  <indicator :state="state">
    <bkf-icon-operator-last-active
      :state="state"
      :name="name"
      :last-sync="lastSync"
    />
    <template slot="description">
      {{ name }}
    </template>
  </indicator>
</template>

<script>
import Indicator from '../Indicator/Indicator.vue';

export default {
  components: {
    Indicator,
  },
  props: {
    name: {
      required: true,
      type: String,
    },
    active: {
      required: false,
      type: Boolean,
      default: null,
    },
    status: {
      required: false,
      type: String,
      default: null,
    },
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
  },
  computed: {
    state() {
      if (this.status !== null) {
        return `icon-${this.status}`;
      }

      if (this.active) {
        return 'positive';
      }

      return null;
    },
  },
};
</script>

<style lang="stylus" >
.device-indicator__description
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

</style>
