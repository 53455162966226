<template>
  <v-card-text>
    <v-card-subtitle class="px-0 pt-0">
      {{ 'notifications.alerts-notifications'|trans }}
    </v-card-subtitle>
    <v-checkbox
      v-model="notifications.email"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-notify-email')"
      hide-details
    />
    <v-checkbox
      v-model="notifications.app"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-notify-app')"
      hide-details
    />
    <v-checkbox
      v-model="notifications.levelInfo"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-notify-info')"
      :disabled="disablePriorities"
      hide-details
    />
    <v-checkbox
      v-model="notifications.levelWarning"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-notify-warning')"
      :disabled="disablePriorities"
      hide-details
    />
    <v-checkbox
      v-model="notifications.levelError"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-notify-error')"
      :disabled="disablePriorities"
      hide-details
    />
    <v-checkbox
      v-model="notifications.globalNotifications"
      color="primary"
      class="mt-0"
      :label="$trans('notifications.alerts-global-notifications')"
      hide-details
    />
  </v-card-text>
</template>

<script>
export default {
  props: {
    notifications: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disablePriorities() {
      return !(this.notifications.email || this.notifications.app);
    },
  },
};
</script>
