import {
  all,
  any,
  equals,
  identity,
  none,
  pipe,
  unapply,
} from 'ramda';

// Check if value is true
const isTruthy = pipe((x) => !!x, equals(true));

// Convert arguments to list (equivalent to (...args) => args)
const args = unapply(identity);

/**
 * Check if all arguments are truthy
 */
export const truthyAll = pipe(args, all(isTruthy));

/**
 * Check if any of arguments is truthy
 */
export const truthyAny = pipe(args, any(isTruthy));

/**
 * Check if none of arguments are truthy
 */
export const truthyNone = pipe(args, none(isTruthy));
