<template>
  <bkf-widget>
    <template slot="title">
      {{ 'alert-rule.conditions'|trans }}
    </template>
    <template
      v-if="!archived"
      slot="actions"
    >
      <bkf-action-add
        v-if="alertRule.type !== 'authentication' && alertRule.type !== 'work without operator'"
        @click="create"
      />
    </template>
    <v-data-table
      v-if="conditions.length"
      :headers="alertRuleColumns"
      :items="conditions"
      hide-default-footer
    >
      <template #[`item.condition`]="{ item }">
        <condition
          :alert-rule="alertRule"
          :row="item"
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <bkf-action-edit @click="edit(item)" />
        <bkf-action-delete @click="remove(item)" />
      </template>
    </v-data-table>
    <template v-else>
      <v-alert
        v-if="alertRule.type !== 'authentication' && alertRule.type !== 'work without operator'"
        type="warning"
        tile
        class="mt-0"
      >
        {{ 'alert-rule.no-conditions-add-new'|trans }}
      </v-alert>
      <v-alert
        v-else
        type="info"
        tile
        class="mt-0"
      >
        {{ 'alert-rule.no-conditions-needed'|trans }}
      </v-alert>
    </template>
    <bkf-crud
      ref="crud"
      :create-title="$trans('alert-rule.condition.creating')"
      :update-title="$trans('alert-rule.condition.editing')"
      @created="created"
      @updated="updated"
      @deleted="deleted"
    />
  </bkf-widget>
</template>

<script>
import Condition from './Condition.vue';

export default {
  components: {
    Condition,
  },
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    conditionsBus: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    model: null,
    entries: [],
    conditions: [],
    columns: [{
      value: 'condition',
      text: t`alert-rule.condition.name`,
      sortable: false,
    },
    {
      value: 'actions',
      text: '',
      align: 'right',
      sortable: false,
    }],
  }),
  computed: {
    archived() {
      return this.alertRule.status === 'h';
    },
    pluralized() {
      return `alertrules/${this.alertRule.id}/conditions`;
    },
    parameterId() {
      if (this.alertRule.conditions.length !== 0
          && this.alertRule.conditions[0].parameter) {
        return this.alertRule.conditions[0].parameter.id;
      }
      return null;
    },
    options() {
      return {
        pluralized: this.pluralized,
        model: this.model,
        parameterId: this.parameterId,
        alertRuleType: this.alertRule.type,
      };
    },
    alertRuleColumns() {
      if (this.alertRule.type === 'periodic performance') {
        return [{
          value: 'condition',
          text: t`alert-rule.condition.name`,
          align: 'left',
        },
        {
          value: 'actions',
          label: '',
          align: 'right',
          sortable: false,
        }];
      }
      if (this.alertRule.type === 'geolocation') {
        return [{
          value: 'condition',
          text: t`alert-rule.condition.name`,
          align: 'left',
        },
        {
          value: 'time',
          text: t`alert-rule.condition.time`,
          align: 'center',
          sortable: false,
        },
        {
          value: 'actions',
          text: '',
          align: 'right',
          sortable: false,
        }];
      }
      return this.columns;
    },
  },
  created() {
    this.model = this.alertRule.$model;
    this.conditions = this.alertRule.$data.conditions;
    this.conditionsBus.$on('unassign-conditions', this.onUnassign);
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
      this.$emit('detect-componet-resize', this.$el);
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('resize-masonry-item', this.$el);
    });
  },
  methods: {
    create() {
      this.$refs.crud.create(this.model, this.options);
    },
    edit(condition) {
      this.$refs.crud.update(condition, this.options);
    },
    async remove(condition) {
      const url = `/${this.pluralized}/${condition.id}`;
      const response = await this.model.$connector.$client.delete(url);

      if (response.status === 200) {
        this.deleted();
      }
    },
    async created() {
      this.$notify.success(t`alert-rule.condition.created`);
      await this.alertRule.sync();
      this.conditions = this.alertRule.$data.conditions;
    },
    async updated() {
      this.$notify.success(t`alert-rule.condition.edited`);
      await this.alertRule.sync();
      this.conditions = this.alertRule.$data.conditions;
    },
    async deleted() {
      this.$notify.success(t`alert-rule.condition.deleted`);
      await this.alertRule.sync();
      this.conditions = this.alertRule.$data.conditions;
    },
    onUnassign() {
      this.conditions = [];
    },
  },
};
</script>
