<template>
  <bkf-dialog-entity-delete
    ref="delete"
    :title="title"
    :disabled="!!conditions.length"
    v-on="$listeners"
  >
    <div
      v-if="conditions.length"
      class="w-100p"
    >
      {{ 'device.delete-conditions.unresolved'|trans }}:
      <div class="flex flex-justify-center flex-align-items-center mb-20">
        <bkf-action-previous-page
          :disabled="progress"
          @click="previousPage"
        />
        <div class="mt-2">
          {{ 'device.delete-conditions.condition-of'|trans(page, pages) }}
        </div>
        <bkf-action-next-page
          :disabled="progress"
          @click="nextPage"
        />
      </div><template v-if="condition.type === 'sensor'">
        {{ 'device.delete-conditions.sensor.message'|trans }}:
        <span class="ml-5 fw-500">{{ condition.sensor.name }}</span>
        <div class="flex flex-justify-center">
          <bkf-tooltip :content="$trans('device.delete-conditions.sensor.unassign-tip')">
            <v-btn
              color="primary"
              tile
              :disabled="progress"
              @click="unlinkSensor(condition.sensor)"
            >
              {{ 'device.delete-conditions.sensor.unassign'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <template
        v-else-if="condition.type === 'alerts'"
      >
        {{ 'device.delete-conditions.alerts.message'|trans(alerts.length) }}
        <div class="flex flex-justify-center">
          <bkf-tooltip :content="$trans('device.delete-conditions.alerts.archive-tip')">
            <v-btn
              :disabled="progress"
              @click="archiveAlerts"
            >
              {{ 'device.delete-conditions.alerts.archive'|trans }}
            </v-btn>
          </bkf-tooltip>
        </div>
      </template>
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
    </div>
    <div v-else>
      {{ 'device.delete-confirm'|trans }}?
    </div>
  </bkf-dialog-entity-delete>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      device: null,
      alerts: [],
      sensors: [],
      progress: false,
    };
  },
  computed: {
    title() {
      return t`device.deleting`;
    },
    conditions() {
      const { alerts, sensors } = this;
      const conditions = [];

      sensors.forEach((sensor) => {
        conditions.push({
          type: 'sensor',
          sensor,
        });
      });

      if (alerts.length > 0) {
        conditions.push({
          type: 'alerts',
          alerts,
        });
      }

      return conditions;
    },
    condition() {
      const { conditions } = this;
      if (!conditions.length) {
        return null;
      }

      return conditions[this.index];
    },
    page() {
      return this.index + 1;
    },
    pages() {
      return this.conditions.length;
    },
  },
  methods: {
    open(device, options = {}) {
      this.device = device;
      this.sensors = device.sensors;
      this.progress = false;
      this.index = 0;
      this.getAlerts(this.device);
      this.$refs.delete.open(device, options);
    },
    async getAlerts(device) {
      const { data, status } = await this.$http.get(`/devices/${device.id}`, {
        context: 'device_delete',
      });

      if (status === 200) {
        this.alerts = data.activeAlarms;
      }
    },
    previousPage() {
      this.index = this.index ? this.index - 1 : this.conditions.length - 1;
    },
    nextPage() {
      this.index = this.index === this.conditions.length - 1 ? 0 : this.index + 1;
    },
    async unlinkSensor(sensor) {
      const { device } = this;
      this.progress = true;
      await this.$http.patch(`/sensors/${sensor.id}/devices/${device.id}/unassign`);
      await device.sync();
      this.sensors = device.sensors;
      this.$notify.success(t`device.delete-conditions.sensor.unassigned`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
    async archiveAlerts() {
      const { device } = this;
      this.progress = true;
      await this.$http.get(`/devices/${device.id}/alert/archive`);
      this.alerts = [];
      this.$notify.success(t`device.delete-conditions.alerts.archived`);
      this.progress = false;
      this.index = Math.max(this.index - 1, 0);
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  opacity: 0
  transition: opacity 300ms linear

.progress.active
  opacity: 1
</style>
