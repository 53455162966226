<template>
  <v-card
    class="px-3 py-3"
    outlined
    color="blue-grey lighten-5"
  >
    <h3 class="mb-3">
      {{ headerText }}
    </h3>
    <v-simple-table
      v-if="items.length"
      dense
      class="elevation-3 py-2"
    >
      <template #default>
        <tbody>
          <tr
            v-for="[key, value] in items"
            :key="`item-${key}`"
          >
            <td
              :key="`itemName-${key}`"
              class="border-right text-left"
            >
              {{ key }}
            </td>
            <td
              :key="`itemValue-${key}`"
              class="text-left"
            >
              {{ value }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-text v-else>
      <v-icon small>
        mdi-alert-circle-outline
      </v-icon>
      <i class="ml-2">{{ table.no_data|trans }}</i>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'DetailsTable',
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    headerText: {
      type: String,
      default: () => t`fiscal_transactions.details.heading`,
      required: false,
    },
  },
  computed: {
    items() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      return Object.entries(this.data);
    },
  },
};
</script>
