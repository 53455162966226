/**
 * Generate base url by providing relative url
 * Use this on frontend to generate links independent
 * @example
 * // if base url was set to "http://localhost"
 * console.log(this.$url('/devices'));
 * // http://localhost/devices
 */
export default {
  install(Vue, { url }) {
    Vue.prototype.$bkf.url = (name) => `${url.api}${name}`;
    Vue.prototype.$url = Vue.prototype.$bkf.url;
  },
};
