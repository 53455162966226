import colors from './colors';

export default {
  data() {
    return {
      echart: {
        grid: {
          top: 80,
          right: 90,
          left: 60,
          bottom: 20,
          containLabel: true,
        },
        toolbox: {
          show: true,
          itemGap: 10,
          itemSize: 20,
          feature: {
            restore: {
              title: t`chart.restore`,
            },
            dataZoom: {
              title: {
                zoom: t`chart.area-zooming`,
                back: t`chart.restore-area-zooming`,
              },
              yAxisIndex: 'none',
            },
            saveAsImage: {
              title: t`chart.save-as-image`,
            },
            mySaveAsCSV: {
              show: true,
              title: t`chart.save-as-csv`,
              icon: 'path://M0,0h10l4,4v16h-14v-20M10,0v4h4M4,8l6,8m0,-8l-6,8',
              onclick: this.toCSV,
            },
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: t`chart.line-chart`,
                bar: t`chart.bar-chart`,
              },
              option: {
                bar: {
                  animationEasing: 'elasticOut',
                  animationDelay: (idx) => idx * 10,
                },
                line: {
                  animationEasing: 'linear',
                },
              },
            },
          },
        },
        legend: {
          data: [],
          top: 40,
          type: 'scroll',
        },
        tooltip: {
          confine: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: null,
          },
          axisLine: {
            show: false,
          },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
        ],
        series: [],
        animationDuration: 300,
        color: colors,
      },
    };
  },
};
