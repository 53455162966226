<template>
  <bkf-icon :class="state">
    <v-tooltip
      v-if="content"
      bottom
    >
      <template #activator="{ on, attrs }">
        <svg
          viewBox="0 0 32 32"
          v-bind="attrs"
          v-on="on"
        >
          <!-- eslint-disable max-len -->
          <g v-if="state === 'positive' || state === 'icon-ok' || state === 'icon-warning'">
            <path
              opacity=".88"
              stroke-linecap="round"
              stroke-width="1.5"
              fill="none"
              d="m14.3 2.25c-9.09-1.55-12.6 3.11-12.6 9.25m11.7-6.13c-3.94-1.17-8.61 0.3-8.86 6.13m8.06-3.05c-3.14-1.31-5.2 0.67-5.21 3.25"
            />
            <circle
              transform="scale(-1,1)"
              cy="11.2"
              cx="-10.9"
              r="1"
              stroke="none"
            />
          </g>
          <path
            v-else-if="state === 'negative' || state === 'icon-error'"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1.5"
            d="m10.7 3.42-6.47 6.48m0-6.48 6.48 6.48"
            fill="none"
          />
          <path
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1.5"
            fill="none"
            d="m16.9 14.4 5.7-3.34c0.458-0.268 1.05-0.0962 1.31 0.364l1.84 3.21c0.264 0.46 0.0982 1.03-0.36 1.3l-5.7 3.34c-0.458 0.268-1.05 0.0962-1.31-0.364l-1.7-3.2c-0.2-0.4-0.2-1 0.3-1.3zm3.96 10.7 1.1 1.68m-15.3-0.3-1.74 1m0.298-3.2-1.74 1.01m0.84-3.5-2.01 1.2m21 0.756 1.1 1.68m1.51-2.96 1.1 1.68m1.35-3.17 1.1 1.68m-24.5-4.9 17.7-10.1c1.15-0.65 2.59-0.248 3.24 0.899l4.53 8.01c0.649 1.15 0.248 2.6-0.9 3.24l-17.7 10.1c-1.1 0.7-2.57 0.3-3.22-0.9l-4.52-8c-0.53-1.3-0.64-2.3 0.95-3.3z"
          />
          <g stroke="none">
            <path
              d="m17.2 10.8c0 11-4.5 8.5-12.4 11.6l5.37 8.06c2.7-1.19 22.5-9.76 20.3-13.9l-4.5-7.98c-2.86-2.56-5.9 0.606-8.75 2.22z"
              fill-opacity=".0777"
            />
            <path
              d="m26.2 9.04c-1.9 0.57-4.1 1.46-5 2.76-1.69 2.5 1.94 6-3.75 10.9-3.76 3.22-7.54 3.42-10.1 4.1 0.866 1.88 2.45 3.56 4.52 3.16 3.49-2.1 15.6-8.67 18.8-11.2z"
              fill-opacity=".118"
            />
          </g>
          <g stroke="none">
            <circle
              transform="scale(-1,1)"
              cy="26.1"
              cx="-11.3"
              r="1"
            />
            <circle
              transform="scale(-1,1)"
              cy="24.4"
              cx="-14.3"
              r="1"
            />
            <circle
              transform="scale(-1,1)"
              cy="22.8"
              cx="-17.2"
              r="1"
            />
          </g>
        </svg>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
import { distanceInWords } from 'date-fns';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en';
import LocaleManager from '@services/locale';

export default {
  props: {
    state: {
      default: null,
      required: false,
      type: String,
    },
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
  },
  computed: {
    content() {
      const now = new Date();
      const locale = this.lang === 'pl' ? pl : en;
      let deviceLastSync = new Date(this.lastSync);

      /** Date is not UTC */
      if (this.lastSync && this.lastSync.search('T') !== -1) {
        deviceLastSync = this.convertDateToUTC(deviceLastSync);
      }

      now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
      return this.lastSync ? `${this.$trans('sensor.last-activity')}: ${distanceInWords(now, deviceLastSync, { addSuffix: true, locale })}` : `${this.$trans('sensor.last-activity')}: ${this.$trans('sensor.no-data')}`;
    },
    lang() {
      const localeManager = LocaleManager.get();
      return localeManager.$language;
    },
  },
  methods: {
    convertDateToUTC(date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );
    },
  },
};
</script>
