export default {
  name: 'widget-operator-summary',
  steps: [
    {
      id: 1,
      target: '.widget-operator-summary > header',
      content: 'widget.operator-summary.guide.step-1',
      header: {
        title: 'widget.operator-summary.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 2,
      target: '.widget-operator-summary .datepicker',
      content: 'widget.operator-summary.guide.step-2',
      header: {
        title: 'widget.operator-summary.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
    {
      id: 3,
      target: '.widget-operator-summary .widget-content',
      content: 'widget.operator-summary.guide.step-3',
      header: {
        title: 'widget.operator-summary.guide.header',
      },
      params: {
        placement: 'bottom',
      },
    },
  ],
};
