<template>
  <v-tooltip
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        small
        text
        class="normal-text no-flex"
        v-on="on"
        @click="goToDevice"
      >
        {{ device.name }}
        <v-icon
          right
          small
        >
          mdi-launch
        </v-icon>
      </v-btn>
    </template>
    <span>
      {{ 'device.go-to-device'|trans }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    device: {
      required: true,
      type: Object,
    },
  },
  methods: {
    goToDevice() {
      this.$router.push({ name: 'device', params: { id: this.device.id } });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .normal-text
    letter-spacing normal
  .no-flex
    flex: none
</style>
