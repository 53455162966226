<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="550"
    eager
    transform-origin="bottom center"
    @input="v => v || submit()"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ 'alert-rule.users.assign-users'|trans }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click.stop="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-2">
        <v-autocomplete
          v-model="value"
          :items="users"
          :multiple="true"
          chips
          small-chips
          deletable-chips
          item-text="name"
          item-value="id"
          :label="$trans('alert-rule.users.user')"
        />
      </v-card-text>
      <v-spacer />
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :disabled="isEmpty"
          @click="submit"
        >
          {{ 'dialog.add'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    users: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      showDialog: false,
      value: [],
    };
  },
  computed: {
    isEmpty() {
      return this.value.length === 0;
    },
  },
  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    async submit() {
      this.$emit('submit', this.value);
      this.value = [];
      this.close();
    },
  },
};
</script>
