<template>
  <div
    :class="type"
    class="alertCount"
  >
    <bkf-alert />
    <bkfAlertBadge>
      {{ value }}
    </bkfAlertBadge>
  </div>
</template>

<script>
import bkfAlertBadge from './AlertBadge';

export default {
  components: {
    bkfAlertBadge,
  },
  props: {
    value: {
      required: true,
      type: Number,
      default: 0,
    },
    type: {
      required: true,
      type: String,
      default: 'info',
    },
  },
};
</script>

<style lang="stylus" src="./AlertCount.styl"></style>
