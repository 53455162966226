<template>
  <bkf-widget>
    <v-card-title>
      {{ 'device-operator.work-history'|trans }}
    </v-card-title>
    <template v-if="!loading">
      <v-data-table
        v-if="devicesHistory.length"
        :headers="columns"
        :items="devicesHistory"
        :items-per-page="5"
      >
        <template #[`item.name`]="{ item }">
          {{ item.name }}
          <div class="row__device-type">
            <strong>Start:</strong> {{ item.begin }}
          </div>
          <div class="row__device-type">
            <strong>Koniec:</strong> {{ item.end }}
          </div>
        </template>
        <template #[`item.activity`]="{ item }">
          <enabled-time-indicator
            :value="item.active"
          />
          <work-time-indicator
            :value="item.work"
          />
        </template>
      </v-data-table>
      <bkf-empty-state
        v-else
        :show-widget="false"
        :show-button="false"
      />
    </template>
    <div
      v-else
      name="fade"
      class="pv-50 center-align"
    >
      <div
        v-if="loading"
        class="progress"
      >
        <v-progress-circular
          :size="80"
          color="primary"
          indeterminate
        />
      </div>
    </div>
  </bkf-widget>
</template>

<script>
import indicatorsMixin from '@mixins/domain/deviceOperator/indicators';
import sizeMixin from '@mixins/store/size';

export default {
  mixins: [
    indicatorsMixin,
    sizeMixin,
  ],
  props: {
    deviceOperator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      devicesHistory: [],
      config: {
        multiSelect: false,
      },
      columns: [
        {
          value: 'name',
          text: t`device-operator.device-name`,
          align: 'left',
        },
        {
          value: 'activity',
          text: t`device-operator.diagnostics`,
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { deviceOperator, year } = this;
      const url = `/deviceoperators/${deviceOperator.id}/work/history`;
      const { data } = await this.$http.get(url, { year });
      this.devicesHistory = data;
      this.loading = false;
    },
    redirect(row) {
      this.$router.push({ name: 'device', params: { id: row.device_id } });
    },
  },
};
</script>

<style lang="stylus" scoped>
dark-text-color = #757575
tiny-text-size = 0.9em

  .row__device-type
    font-size: tiny-text-size
    color: dark-text-color

  .progress
    z-index: 10
    position: relative
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(255,255,255,0.5)
</style>
