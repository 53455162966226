import format from '@utils/date';

// Default formats
const defaults = {
  dateTime: 'YYYY-MM-DD HH:mm',
  date: 'YYYY-MM-DD',
  time: 'HH:mm',
};

/**
 * Format dateString as date or provided formatter
 *
 * @param {string} dateString
 * @param {string} formatString
 * @returns {string}
 */
export function date(dateString, formatString) {
  if (dateString === null) {
    return '-';
  }
  return format(dateString, formatString || defaults.date);
}

/**
 * Format dateString as time or provided formatter
 *
 * @param {string} dateString
 * @param {string} formatString
 * @returns {string}
 */
export function time(dateString, formatString) {
  if (dateString === null) {
    return '-';
  }
  return format(dateString, formatString || defaults.time);
}

/**
 * Format dateString as dateTime or provided formatter
 *
 * @param {string} dateString
 * @param {string} formatString
 * @returns {string}
 */
export function dateTime(dateString, formatString) {
  if (dateString === null) {
    return '-';
  }
  return format(dateString, formatString || defaults.dateTime);
}
