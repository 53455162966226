import deep from '@utils/deep';

/**
 * Gets deep property value if exists, otherwise returns undefined
 * @param {{}} object
 * @param {string} path
 */
export default function (object, path) {
  return deep(object, path);
}
