import conditionsObject from '@globals/alerts/conditions';

export default {
  computed: {
    conditionsObject() {
      return conditionsObject;
    },
  },
  methods: {
    createDescription(alertRule, row) {
      if (alertRule.type === 'work efficiency') {
        return this.workEffectiveDescription(row.conditions);
      }
      if (alertRule.type === 'effective work time') {
        return this.effectiveWorkTimeDescription(row.conditions);
      }
      if (alertRule.type === 'periodic performance') {
        return this.periodicPerformanceDescription(row.conditions);
      }
      if (alertRule.type === 'effective work percentage') {
        return this.effectiveWorkPercentageDescription(row.conditions);
      }
      if (alertRule.type === 'geolocation') {
        return this.geolocationDescription();
      }
      if (alertRule.type === 'part replace') {
        return this.partReplaceDescription(row.parameter, row.conditions);
      }
      if (alertRule.type === 'lastsync') {
        return this.lastSyncDescription(row.conditions);
      }
      if (alertRule.type === 'counter per hour') {
        return this.counterPerHourDescription(row.parameter, row.conditions);
      }
      if (alertRule.type === 'charging') {
        return this.chargingDescription(row.parameter, row.conditions);
      }
      if (alertRule.type === 'periodic') {
        return this.periodicDescription(row.parameter, row.conditions);
      }
      return this.defaultDescription(row);
    },
    effectiveWorkTimeDescription(rowConditions) {
      if (rowConditions.operator) {
        // eslint-disable-next-line prefer-template
        return t`alert-rule.condition.effective-work-time`
          + ' '
          + t`alert-rule.condition.operator.${conditionsObject[rowConditions.operator].name}`.toLowerCase()
          + ' '
          + rowConditions.argument
          + ' '
          + t`alert-rule.condition.minutes`.toLowerCase()
          + ' '
          + t`alert-rule.condition.last-${rowConditions.date_interval}`.toLowerCase();
      }
      // eslint-disable-next-line prefer-template
      return t`alert-rule.condition.effective-work-time`
        + ' '
        + rowConditions.argument
        + ' '
        + t`alert-rule.condition.minutes`.toLowerCase()
        + ' '
        + t`alert-rule.condition.last-${rowConditions.date_interval}`.toLowerCase();
    },
    workEffectiveDescription(rowConditions) {
      if (rowConditions.operator) {
        // eslint-disable-next-line prefer-template
        return t`alert-rule.condition.efficiency`
            + ' '
            + t`alert-rule.condition.operator.${conditionsObject[rowConditions.operator].name}`.toLowerCase()
            + ' '
            + rowConditions.argument
            + ' '
            + t`alert-rule.condition.last-${rowConditions.date_interval}`.toLowerCase();
      }
      // eslint-disable-next-line prefer-template
      return t`alert-rule.condition.efficiency`
          + ' '
          + rowConditions.argument
          + ' '
          + t`alert-rule.condition.last-${rowConditions.date_interval}`.toLowerCase();
    },
    periodicPerformanceDescription(rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.condition.efficiency`
          + ' '
          + t`alert-rule.condition.operator.${conditionsObject[rowConditions.operator].name}`.toLowerCase()
          + ` ${rowConditions.argument}% `
          + t`alert-rule.condition.checked`
          + ' '
          + t`alert-rule.condition.${conditionsObject[rowConditions.date_interval].name}`
          + ' '
          + t`alert-rule.condition.from-hour`
          + ' '
          + rowConditions.fromHour
          + ' '
          + t`alert-rule.condition.to-hour`
          + ' '
          + rowConditions.toHour + '.';
    },
    effectiveWorkPercentageDescription(rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.parameter`
          + ' '
          + t`alert-rule.condition.efficiency-in-percents`.toLowerCase()
          + ' '
          + t`alert-rule.condition.operator.${conditionsObject[rowConditions.operator].name}`.toLowerCase()
          + ' '
          + rowConditions.argument + '%';
    },
    geolocationDescription() {
      return t`alert-rule.condition.device-outside-defined-area`;
    },
    partReplaceDescription(rowParameter, rowConditions) {
      // eslint-disable-next-line prefer-template
      return rowParameter.name + ': '
        + t`alert-rule.condition.every`
        + ' '
        + rowConditions.argument
        + ' h';
    },
    lastSyncDescription(rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.condition.${conditionsObject[rowConditions.date_interval].name}`;
    },
    counterPerHourDescription(rowParameter, rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.condition.parameter-quantity`
        + ' '
        + rowParameter.name
        + ' '
        + `${rowConditions.argument}`
        + ' '
        + t`alert-rule.condition.perhour`;
    },
    chargingDescription(rowParameter, rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.types.charging`
        + ' '
        + t`alert-rule.condition.operator.${conditionsObject[rowConditions.operator].name}`.toLowerCase()
        + ' '
        + `${rowConditions.argument}`
        + ' '
        + t`alert-rule.condition.on`
        + ' '
        + t`alert-rule.condition.${conditionsObject[rowConditions.date_interval].name}`.toLowerCase();
    },
    periodicDescription(rowParameter, rowConditions) {
      // eslint-disable-next-line prefer-template
      return t`alert-rule.types.repeat-every`
        + ' '
        + `${rowConditions.argument}`
        + ' '
        + t`alert-rule.condition.periodic.${conditionsObject[rowConditions.date_interval].name}`.toLowerCase();
    },
    defaultDescription(row) {
      let description = '';
      if (row.conditions.date_interval) {
        description = t`alert-rule.condition.${conditionsObject[row.conditions.date_interval].name}`;
        description += ' ';
      }
      if (row.conditions.variable) {
        description += t`alert-rule.condition.variable.${conditionsObject[row.conditions.variable].name}`;
        description += ' ';
      }
      if (row.conditions.operator) {
        description += row.parameter.name.toLowerCase();
        description += ' ';
        description += t`alert-rule.condition.operator.${conditionsObject[row.conditions.operator].name}`.toLowerCase();
        description += ' ';
      }
      description += row.conditions.argument;
      return description;
    },
  },
};
