<template>
  <div>
    <slot name="configurator">
      <bkf-configurator-dialog
        ref="configurator"
        :title="createSubmitLabel"
        :reports-type="reportsType"
        :new-alarm="newAlarm"
        v-on="$listeners"
      />
    </slot>
    <slot name="create">
      <bkf-dialog-entity-create
        ref="create"
        :title="titleCreate"
        :submit-label="createSubmitLabel"
        :created="created"
        v-on="$listeners"
      />
    </slot>
    <slot name="update">
      <bkf-dialog-entity-update
        ref="update"
        :title="titleUpdate"
        v-on="$listeners"
      />
    </slot>
    <slot name="delete">
      <bkf-dialog-entity-delete
        ref="delete"
        :title="titleDelete"
        v-on="$listeners"
      >
        {{ confirmDelete }}?
      </bkf-dialog-entity-delete>
    </slot>
  </div>
</template>

<script>
import { dasherize, underscore } from 'inflection';

const titles = {
  create: 'creating',
  update: 'editing',
  delete: 'deleting',
  confirm: 'delete-confirm',
};

const format = (name) => dasherize(underscore(name));
const message = (name, event) => t`${format(name)}.${event}`;
const title = (model = null, event) => (model ? message(model.$name, event) : null);

export default {
  props: {
    createTitle: {
      type: String,
      required: false,
      default: null,
    },
    createDialog: {
      type: Object,
      required: false,
      default: null,
    },
    createSubmitLabel: {
      type: String,
      required: false,
      default: 'dialog.create',
    },
    updateTitle: {
      type: String,
      required: false,
      default: null,
    },
    updateDialog: {
      type: Object,
      required: false,
      default: null,
    },
    deleteTitle: {
      type: String,
      required: false,
      default: null,
    },
    deleteDialog: {
      type: Object,
      required: false,
      default: null,
    },
    reportsType: {
      type: Array,
      required: false,
      default: null,
    },
    newAlarm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    titleCreate() {
      return this.createTitle || title(this.model, titles.create);
    },
    titleUpdate() {
      return this.updateTitle || title(this.model, titles.update);
    },
    titleDelete() {
      return this.deleteTitle || title(this.model, titles.delete);
    },
    confirmDelete() {
      return title(this.model, titles.confirm);
    },
    createDialogRef() {
      return this.createDialog
        || this.$refs.create
        || this.$slots.create[0].componentInstance;
    },
    updateDialogRef() {
      return this.updateDialog
        || this.$refs.update
        || this.$slots.update[0].componentInstance;
    },
    deleteDialogRef() {
      return this.deleteDialog
        || this.$refs.delete
        || this.$slots.delete[0].componentInstance;
    },
    configuratorDialogRef() {
      return this.configuratorDialog
        || this.$refs.configurator
        || this.$slots.configurator[0].componentInstance;
    },
  },
  methods: {
    create(model, options = {}) {
      this.model = model;
      this.createDialogRef.open(model, options);
    },
    update(entry, options = {}) {
      this.model = entry.$model;
      this.updateDialogRef.open(entry, options);
    },
    delete(entry, options = {}) {
      this.model = entry.$model;
      this.deleteDialogRef.open(entry, options);
    },
    configurator(model, options = {}) {
      this.model = model;
      this.configuratorDialogRef.openDialog(model, options);
    },
    created(payload) {
      this.$emit(payload);
    },
  },
};
</script>
