<template>
  <span>{{ createDescription(alertRule, row) }}</span>
</template>

<script>
import conditionDescription from '@mixins/misc/conditionDescription';
import { format } from 'date-fns';

export default {
  mixins: [conditionDescription],
  props: {
    alertRule: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  computed: {
    archived() {
      return this.alertRule.status === 'h';
    },
    conditionCtime() {
      if (this.row.ctime) {
        return format(new Date(this.row.ctime), 'YYYY-MM-DD HH:mm');
      }
      return '-';
    },
  },
};
</script>
