import { create } from 'apisauce';
import oauth from './oauth';

let api;
let loginURL;

/**
 * Is Forbidden status
 * @param status {number}
 * @returns {boolean}
 */
// const isForbidden = (status) => status === 403;

/**
 * Is Unauthorized status
 * @param status {number}
 * @returns {boolean}
 */
const isUnauthorized = (status) => status === 401;

/**
 * Redirect page to given url
 * @param url {string}
 */
const redirectTo = (url) => window.location.assign(url);

/**
 * Make all http statuses in axios valid (don't throw
 * @param state
 */
const makeStatusesValid = (state) => { api.axiosInstance.defaults.validateStatus = () => state; };

/**
 * Register monitor
 * @param {*} monitor
 */
export const registerMonitor = (monitor) => api.addMonitor(monitor);

/**
 * Get API or create new one
 * @param {{}} payload
 */
export const get = ({ baseUrl, loginUrl } = {}) => {
  if (!api) {
    // Set data values
    api = create({ baseURL: `${baseUrl}/api` });
    loginURL = loginUrl;

    // Make all response statuses valid (dont throw exceptions on >= 400)
    // we want to ckeck them manually
    makeStatusesValid(true);

    // Set policy for CORS
    api.setHeader('Access-Control-Allow-Origin', '*');

    if (loginURL) {
    // Add monitor to response go check if we get forbidden / unauthorized status
      api.axiosInstance.interceptors.response.use(async (response) => {
        if (isUnauthorized(response.status)) {
          const oauthManager = oauth.get();
          const { success, error } = await oauthManager.refresh();
          if (success) {
            const newToken = oauthManager.getAccessToken();
            api.setHeader('Authorization', `Bearer ${newToken}`);
            response.config.headers.Authorization = `Bearer ${newToken}`;
            return api.axiosInstance(response.config);
          }
          if (error) {
            redirectTo(loginURL);
          }
        }
        return response;
      });
    }
  }

  return api;
};

export default (payload) => get(payload);
