<template>
  <bkf-widget class="widget-activity-summary">
    <template slot="title">
      {{ 'widget.activity-summary.title'|trans }}
    </template>
    <template slot="actions">
      <v-menu
        left
        min-width="250"
      >
        <template #activator="{ on: menu, attrs }">
          <v-tooltip
            bottom
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <span> {{ 'action-button.more'|trans }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="openSettings">
            <v-list-item-icon>
              <v-icon>
                settings
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.settings'|trans }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="startGuide('widget-activity-summary')">
            <v-list-item-icon>
              <v-icon>
                help
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ 'widget.guide'|trans }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <div class="flex flex-justify-center container">
      <chart :values="values" />
      <v-list
        v-if="settings.showAdditionalInfo"
        class="add-info flex-align-center text-body-2"
        dense
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <span class="pr-1">{{ 'widget.activity-summary.average-work-time'|trans }}: </span>
              <strong> {{ additionalInfo.avgDeviceWork|human }}</strong>
            </v-list-item>
          </template>
          <span>{{ 'widget.activity-summary.average-work-time-explanation'|trans }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <span class="pr-1">{{ 'widget.activity-summary.max-work-time'|trans }}: </span>
              <strong>{{ additionalInfo.maxDeviceWork|human }}</strong>
            </v-list-item>
          </template>
          <span>{{ 'widget.activity-summary.max-work-time-explanation'|trans }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <span class="pr-1">{{ 'widget.activity-summary.average-activity-time'|trans }}:</span>
              <strong>{{ additionalInfo.avgDeviceActive|human }}</strong>
            </v-list-item>
          </template>
          <span>{{ 'widget.activity-summary.average-activity-time-explanation'|trans }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <span class="pr-1">{{ 'widget.activity-summary.max-activity-time'|trans }}: </span>
              <strong>{{ additionalInfo.maxDeviceActive|human }}</strong>
            </v-list-item>
          </template>
          <span>{{ 'widget.activity-summary.max-activity-time-explanation'|trans }}</span>
        </v-tooltip>
      </v-list>
    </div>
    <settings
      v-if="initialized"
      ref="settings"
      v-model="settings"
      :device-groups="deviceGroups"
      @submit="saveSettings"
    />
    <bkf-refresher
      :speed="60"
      :pause="loading"
      @refresh="refresh"
    />
    <v-alert
      v-if="initialized && values.total === 0"
      type="warning"
      class="mt-0"
    >
      {{ 'alert-rule.no-devices'| trans }}
    </v-alert>
  </bkf-widget>
</template>

<script>
import { startOfDay, format } from 'date-fns';
import ResizeObserver from 'resize-observer-polyfill';
import guideMixin from '@mixins/misc/guide';
import settingsMixin from '@mixins/misc/settings';
import sizeMixin from '@mixins/store/size';
import Chart from './Chart.vue';
import Settings from './Settings.vue';
import guide from './guide';

export default {
  components: {
    Chart,
    Settings,
  },
  mixins: [
    guideMixin,
    settingsMixin,
    sizeMixin,
  ],
  props: {
    deviceGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: startOfDay(new Date()),
      initialized: false,
      loading: true,
      settings: {
        deviceGroups: [],
        showAdditionalInfo: true,
      },
      values: {
        total: 0,
        enabled: 0,
        working: 0,
        online: 0,
        offline: 0,
      },
      additionalInfo: {
        avgDeviceActive: 0,
        avgDeviceEfficiency: 0,
        avgDeviceWork: 0,
        maxDeviceActive: 0,
        maxDeviceEfficiency: 0,
        maxDeviceWork: 0,
      },
    };
  },
  async created() {
    // Try to load settings from backend
    const settings = this.getSetting('activity-summary');
    this.settings = settings || this.settings;
    if (this.settings.showAdditionalInfo === undefined) {
      this.settings.showAdditionalInfo = true;
    }
    // get the data
    await this.fetchData();
    this.registerGuide(guide(this.isMobile));

    this.initialized = true;
    //  this.loading = false;
  },
  mounted() {
    this.$nextTick(() => {
      this.detectComponetResize();
    });
  },
  updated() {
    this.detectComponetResize();
  },
  methods: {
    openSettings() {
      this.$refs.settings.open();
    },
    async saveSettings() {
      await this.setSetting('activity-summary', this.settings);
      await this.fetchData();
    },
    async fetchData() {
      const { date } = this;
      const { deviceGroups } = this.settings;
      const { values } = this;

      this.loading = true;

      const payload = {
        deviceGroups: deviceGroups.join(','),
        date: format(date, 'YYYY-MM-DD'),
      };

      const response = await this.$http.get('/devices/diagnostics/activity_summary', payload);

      if (response.status === 200) {
        const {
          total, working, enabled, online, offline, additionalInfo,
        } = response.data;

        values.total = total;
        values.working = working;
        values.enabled = enabled;
        values.online = online;
        values.offline = offline;
        this.additionalInfo = additionalInfo;
      }

      this.loading = false;
    },
    async refresh() {
      await this.fetchData();
    },
    detectComponetResize() {
      const resizeObserver = new ResizeObserver((items) => {
        this.$emit('resize-masonry-item', items[0].target);
      });
      if (this.$el instanceof Element) {
        resizeObserver.observe(document.querySelector('.widget-activity-summary'));
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.progress
  position: absolute
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)

.container
  flex-wrap wrap

// Desktop
.add-info >>> .v-list-item
  font-size 14px
  min-height 25px
  flex-wrap wrap
  justify-content flex-start
  padding 0
  span
    margin-right 5px
// Mobile
@media (max-width: 768px)
  .contener
    flex-direction column
    align-items center
// Two column
@media (min-width: 1045px) and (max-width: 1550px)
  .contener
    flex-direction column
    align-items center
</style>
