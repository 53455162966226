/**
 * Get model from store module
 * @param name
 * @returns {Promise.<*>}
 */
function getModel(name) {
  return this.$store.dispatch('models/get', name);
}

/**
 * This plugins exposes 'models/get' action from Vuex as properties for simple use:
 *
 * this.$model(modelName)
 * this.$bkf.model(modelName)
 */
export default {
  install(Vue) {
    Vue.prototype.$model = getModel;
    Vue.prototype.$bkf.model = Vue.prototype.$model;
  },
};
