<template>
  <bkf-action
    :tooltip="$trans('action-button.export-table')"
    :disabled="disabled"
    :exporter="exporter"
    @click="$emit('click')"
  >
    <v-icon>
      archive
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    exporter: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>
