<template>
  <bkf-icon :class="state">
    <v-tooltip
      bottom
    >
      <template #activator="{ on, attrs }">
        <!-- eslint-disable max-len -->
        <svg
          viewBox="0 0 297 297"
          style="enable-background:new 0 0 297 297;"
          v-bind="attrs"
          v-on="on"
        >
          <g>
            <path
              d="M 148.5,0 C 87.43,0 37.747,49.703 37.747,110.797 c 0,91.026 99.729,179.905 103.976,183.645 1.936,1.705 4.356,2.559 6.777,2.559 2.421,0 4.841,-0.853 6.778,-2.559 4.245,-3.739 103.975,-92.618 103.975,-183.645 C 259.253,49.703 209.57,0 148.5,0 Z m -0.92315,281.92055 C 125.52785,260.55455 50.871762,179.66 50.871762,110.797 50.871762,61.013 98.74,14.971071 148.5,14.971071 c 49.76,0 95.78193,46.041929 95.78193,95.825929 0,68.862 -74.65608,149.75655 -96.70508,171.12355 z"
            />
            <path
              d="m 148.5,59.183 c -28.273,0 -51.274,23.154 -51.274,51.614 0,28.461 23.001,51.614 51.274,51.614 28.273,0 51.274,-23.153 51.274,-51.614 0,-28.46 -23.001,-51.614 -51.274,-51.614 z m 0,87.33377 c -16.964,0 -36.30393,-18.56877 -36.30393,-35.71977 0,-17.15 19.33993,-36.642929 36.30393,-36.642929 16.964,0 35.38077,19.491929 35.38077,36.642929 0,17.151 -18.41677,35.71977 -35.38077,35.71977 z"
            />
          </g>
        </svg>
      </template>
      <span>{{ content }}</span>
    </v-tooltip>
  </bkf-icon>
</template>

<script>
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en';
import { distanceInWords } from 'date-fns';
import LocaleManager from '@services/locale';

export default {
  props: {
    lastSync: {
      default: null,
      required: false,
      type: String,
    },
    state: {
      default: null,
      required: false,
      type: String,
    },
  },
  computed: {
    content() {
      const now = new Date();
      const locale = this.lang === 'pl' ? pl : en;
      let deviceLastSync = new Date(this.lastSync);

      /** Date is not UTC */
      if (this.lastSync && this.lastSync.search('T') !== -1) {
        deviceLastSync = this.convertDateToUTC(deviceLastSync);
      }

      now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
      return this.lastSync
        ? `${this.$trans('sensor.last-location')}: ${distanceInWords(now, deviceLastSync, { addSuffix: true, locale })}`
        : `${this.$trans('sensor.last-location')}: ${this.$trans('sensor.no-data')}`;
    },
    lang() {
      const localeManager = LocaleManager.get();
      return localeManager.$language;
    },
  },
  methods: {
    convertDateToUTC(date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );
    },
  },
};
</script>
