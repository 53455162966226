<template>
  <bkf-action
    :tooltip="tooltip"
    @click="$emit('click')"
  >
    <v-icon>
      navigate_next
    </v-icon>
  </bkf-action>
</template>

<script>
export default {
  props: {
    tooltip: {
      required: false,
      type: String,
      default: 'action-button.next-page',
    },
  },
};
</script>
