import config from './config';
import copy from './copy';
import deep from './deep';
import http from './http';
import model from './model';
import notify from './notify';
import random from './random';
import sleep from './sleep';
import translate from './trans';
import url from './url';

/**
 * Install global plugins
 */
export default {
  install(Vue, options) {
    // Define safe object for aliasing properties
    Vue.prototype.$bkf = {};

    // Use plugins
    Vue.use(config);
    Vue.use(copy);
    Vue.use(deep);
    Vue.use(http, options);
    Vue.use(model);
    Vue.use(notify);
    Vue.use(random);
    Vue.use(sleep);
    Vue.use(translate);
    Vue.use(url, options);
  },
};
