<template>
  <v-tooltip
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        small
        text
        class="normal-text no-flex"
        v-on="on"
        @click.stop="$emit('click')"
      >
        <slot />
        <v-icon
          right
          small
        >
          mdi-launch
        </v-icon>
      </v-btn>
    </template>
    <span>
      {{ tooltip|trans }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      required: false,
      default: 'action-button.launch',
      type: String,
    },
  },
};
</script>

<style lang="stylus" scoped>
  .normal-text
    letter-spacing normal
  .no-flex
    flex: none
</style>
