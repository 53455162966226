import format from 'date-fns/format';
import getTime from 'date-fns/get_time';
import slugify from 'slugify';

/**
 * Format value for CSV purposes
 * @param {*} value
 */
const formatValue = (value) => `"${slugify(value ? value.toString() : '', ' ')}"`;

/**
 * Export data to csv and force download in browser
 * Possible options: delimeter, filename
 */
export default function csv(data, labels = [], title = '', options = {}) {
  // options
  const delimeter = options.delimeter || ';';
  const filename = options.filename || getTime(new Date());

  // Add title
  let buffer = title || `${format(new Date(), 'YYYY-MM-DD H:mm')}\n\n`;

  // Add labels
  if (Array.isArray(labels)) {
    const result = labels.map(formatValue);
    buffer += `${result.join(delimeter)}\n`;
  }

  // Add data
  if (Array.isArray(data)) {
    buffer += data.map((row) => {
      const result = row.map(formatValue);
      return result.join(delimeter);
    }).join('\n');
  }

  // Encode buffer
  buffer = encodeURI(buffer);

  // Check if current environment is browser
  if (document) {
    const a = document.createElement('a');
    const charset = document.characterSet;
    document.body.appendChild(a);
    a.setAttribute('href', `data:text/csv;charset=${charset},${buffer}`);
    a.setAttribute('download', `${filename}.csv`);
    a.click();
  }
}
