<template>
  <v-autocomplete
    :value="value"
    :items="devices"
    :multiple="true"
    chips
    :error="error"
    :error-messages="errorMsg"
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :label="$trans('monitor.devices')"
    @change="emitInput"
  />
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    devices: {
      required: true,
      type: Array,
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorMsg() {
      return this.error ? this.$trans('form.field.error.not-blank') : null;
    },
  },
  methods: {
    /**
     * Emit input current state
     * @param {array} devicesIds Array of devices ids
     */
    emitInput(devicesIds) {
      this.$emit('input', devicesIds);
    },
  },
};
</script>
