import { render, staticRenderFns } from "./Alerts.vue?vue&type=template&id=517bdbca&scoped=true&"
import script from "./Alerts.vue?vue&type=script&lang=js&"
export * from "./Alerts.vue?vue&type=script&lang=js&"
import style0 from "./Alerts.vue?vue&type=style&index=0&id=517bdbca&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517bdbca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VContainer,VDataTable,VSpacer,VSwitch})
