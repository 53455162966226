<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      />
      <bkf-page
        v-if="isActivePage"
        :loading="false"
        :error="error"
        class="pt-3"
      >
        <bkf-widget>
          <v-card-title>
            <v-col
              cols="auto"
              class="ml-auto d-flex"
            >
              <v-spacer />
              <v-select
                v-model="status"
                :items="statuses"
                :label="$trans('alert.status.name')"
                item-value="id"
                item-text="name"
                class="pr-4"
                @change="getData"
              />
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$trans('table.search')"
                single-line
                hide-details
                class="font-weight-regular"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0 pt-7"
            >
              <bkf-action-refresh
                @click="refresh"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            v-if="!siteLoading"
            :loading="siteLoading"
            :headers="columns"
            :items="alarms"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="totalItems"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
          >
            <template #[`item.alarmRule.level`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="mt-n1"
                    small
                    :class="getImportanceClass(item.alarmRule.level)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-bell
                  </v-icon>
                </template>
                <span>{{ getImportanceTooltip(item.alarmRule.level) }}</span>
              </v-tooltip>
            </template>

            <template #[`item.ctime`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.ctime|dateTime }}
                  </span>
                </template>
                <span> {{ 'alert.last-occurence-time'|trans }}: {{ item.lastTime|dateTime }}</span>
              </v-tooltip>
            </template>

            <template #[`item.endTime`]="{ item }">
              <span v-if="item.endTime !== null">{{ item.endTime|dateTime }}</span>
              <span v-else>{{ '-' }}</span>
            </template>

            <template #[`item.device.name`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.device.name }}
                  </span>
                </template>
                <span> {{ item.device.description }}</span>
              </v-tooltip>
            </template>

            <template #[`item.alarmRule.name`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.alarmRule.name }}
                  </span>
                </template>
                <span>
                  {{ 'alert.description'|trans }}: {{ item.alarmRule.description }}
                </span>
              </v-tooltip>
            </template>

            <template #[`item.status`]="{ item }">
              <span :class="getStatusClass(item.status)">
                {{ getStatusTranslation(item.status)|trans }}
              </span>
            </template>

            <template #[`item.confirm`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="item.status === 'a'"
                    icon
                    v-bind="attrs"
                    @click.stop="archiveAndReload({ alert: item, remove: false })"
                    v-on="on"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>{{ 'alert.archive'|trans }}</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-progress-circular
            v-if="siteLoading"
            color="primary"
            class="mb-8"
            size="60"
            indeterminate
          />
        </bkf-widget>
      </bkf-page>
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import debounce from 'lodash/debounce';
import indicatorsMixin from '@mixins/domain/device/indicators';
import importancesLevel from '@globals/alerts/importancesLevel';
import statuses from '@globals/alerts/statuses';
import { mapActions } from 'vuex';

export default {
  mixins: [
    pageMixin,
    listMixin,
    indicatorsMixin,
  ],
  data() {
    return {
      expanded: [],
      siteLoading: true,
      search: null,
      status: 'null',
      statuses: [
        {
          id: 'null',
          name: t`alert.status.active`,
        },
        {
          id: 'hidden',
          name: t`alert.status.confirmed`,
        },
        {
          id: 'all',
          name: t`alert.status.all`,
        },
      ],
      confirmedAlertsSwitch: false,
      settings: {
        size: 10,
      },
      footerProps: {
        'items-per-page-options': [5, 10, 25],
      },
      totalItems: 13,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      alarms: [],
      columns: [
        {
          value: 'alarmRule.level',
          text: t`alert.importance`,
          sortable: false,
          filterable: false,
        },
        {
          value: 'device.name',
          text: t`alert.device`,
          sortable: false,
          filterable: false,
        },
        {
          value: 'ctime',
          text: t`alert.ctime`,
          sortable: false,
          filterable: false,
        },
        {
          value: 'endTime',
          text: t`alert.end-time`,
          sortable: false,
          filterable: false,
        },
        {
          value: 'alarmRule.name',
          text: t`alert.rule`,
          sortable: false,
          filterable: false,
        },
        {
          value: 'status',
          text: t`alert.status.name`,
          align: 'right',
          sortable: false,
          filterable: false,
        },
        {
          value: 'confirm',
          text: '',
          align: 'right',
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  watch: {
    search: {
      handler() {
        this.pagination.page = 1;
        this.getDataDebounced();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage) {
          // return to first page when sorting has change
          if (oldValue.itemsPerPage !== newValue.itemsPerPage) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
          }
          this.siteLoading = true;
          this.getDataDebounced();
        }
      },
      deep: true,
    },
  },
  created() {
    this.siteLoading = true;
    this.getDataDebounced = debounce(() => {
      this.getData();
    }, 1000);
  },
  mounted() {
    this.siteLoading = true;
    this.getData();
  },
  methods: {
    ...mapActions({
      archive: 'alerts/archive',
    }),
    async archiveAndReload(alert) {
      this.loading = true;
      await this.archive(alert);
      await this.refresh();
    },
    getStatusTranslation: (status) => statuses[status]?.transKey ?? '',
    getStatusClass: (status) => statuses[status]?.name ?? '',
    getImportanceClass: (level) => importancesLevel[level]?.name ?? '',
    getImportanceTooltip: (level) => t`alert.weight.${importancesLevel[level]?.theme ?? undefined}`,
    async refresh() {
      this.loading = true;
      await this.getData();
      this.loading = false;
    },
    getStatus() {
      return this.status === 'null' ? null : this.status;
    },
    async getData() {
      this.siteLoading = true;
      const params = {
        page: [this.pagination.page],
        perPage: [this.pagination.itemsPerPage],
        search: [this.search],
        status: [this.getStatus()],
      };

      const urlParams = new URLSearchParams(params).toString();

      this.$http.get(
        `user/alarms?${urlParams}`,
      )
        .then(
          (response) => {
            this.alarms = response.data;
            this.totalItems = parseInt(response.headers['x-pager-total-entries'], 10);
            this.siteLoading = false;
          },
        );
    },
    async onActivatePage() {
      this.siteLoading = true;
      // await this.getData();
    },
  },
};
</script>

<style scoped lang="stylus">
.expand-table
  box-shadow: inset 0 0px 0px 0px !important;
</style>
