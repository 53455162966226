<template>
  <bkf-widget>
    <template slot="title">
      {{ 'device-group.statistics'|trans }}
    </template>
    <v-list dense>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device-group.today-activity'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getGroupStats.active|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          {{ 'device-group.today-work'|trans }}:
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getGroupStats.work|human }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="hmin-30">
        <v-list-item-content class="flex-none mr-2 text-subtitle-2 py-1">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">{{ 'device-group.today-productivity'|trans }}:</span>
            </template>
            <span>{{ 'device-group.average-productivity-explanation'|trans }}</span>
          </v-tooltip>
        </v-list-item-content>
        <v-list-item-content class="flex-grow-1 text-body-2 py-1">
          {{ getGroupStats.productivity }}%
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </bkf-widget>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    deviceGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedInterval: 'today',
    };
  },
  computed: {
    ...mapGetters({
      devicesGroupDiagnostics: 'devices/devicesGroupDiagnostics',
    }),
    getGroupStats() {
      const diagnostics = {
        active: this.averageGroupActivity(this.deviceGroup.id, this.deviceGroup.devices.length),
        work: this.averageGroupWork(this.deviceGroup.id, this.deviceGroup.devices.length),
        productivity: this.groupProductivity(this.deviceGroup.id, this.deviceGroup.devices.length),
      };

      return diagnostics;
    },
  },
  methods: {
    averageGroupActivity(id, devicesCount) {
      if (!this.devicesGroupDiagnostics(id)) {
        return 0;
      }
      return devicesCount !== 0 ? (this.devicesGroupDiagnostics(id).active / devicesCount) : 0;
    },
    averageGroupWork(id, devicesCount) {
      if (!this.devicesGroupDiagnostics(id)) {
        return 0;
      }
      return devicesCount !== 0 ? (this.devicesGroupDiagnostics(id).work / devicesCount) : 0;
    },
    groupProductivity(id, devicesCount) {
      const activity = this.averageGroupActivity(id, devicesCount);

      if (activity === 0) {
        return 0;
      }

      return Math.round(this.averageGroupWork(id, devicesCount) / activity * 100);
    },
    calculatePercentage: (actual, past, day) => (
      past === 0 ? 100 : Math.floor(
        ((actual - (past * day)) / (past * day)) * 100,
      )),
    calculatePeriodPercent(period) {
      if (period === 'today') {
        return new Date().getHours() / 24;
      }
      if (period === 'week') {
        return new Date().getDay() / 7;
      }
      if (period === 'month') {
        return new Date().getDate() / 30;
      }
      return 1;
    },
  },
};
</script>

<style scoped lang="stylus">
.progress
  z-index: 10
  width: 100%
  height: 100px
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,0.5)
</style>
