<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="40%"
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>{{ title }} {{ $deep(userGroup, 'name') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="onClose()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <div
          v-show="fetching"
          class="progress"
        >
          <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
          />
        </div>
        <v-form
          v-show="!fetching"
          ref="formEdit"
          v-model="form.valid"
          :disabled="submitting"
          lazy-validation
        >
          <v-text-field
            v-model="userGroup.name"
            :label="$trans('users.group_name')"
            :rules="rules.required"
          />
          <v-checkbox
            v-model="userGroup.registerDeviceOperators"
            class="mt-0"
            :label="$trans('user-group.automatic-operator-register')"
            hide-details
          />
          <v-autocomplete
            v-model="subscriptionType"
            class="mt-4"
            item-value="id"
            item-text="name"
            :label="$trans('user-group.subscription-type')"
            :items="subscriptionsOptions"
            :rules="rules.required"
          />
          <v-autocomplete
            v-model="userGroup.timezone.id"
            class="mt-4"
            item-value="id"
            item-text="location"
            :loading="timezoneIsLoading"
            :search-input.sync="searchTimezone"
            :label="$trans('user-group.timezone')"
            :items="timezonesOptions"
            :autocomplete="true"
            :rules="rules.required"
          />
          <v-textarea
            v-model="userGroup.description"
            :label="$trans('users.note')"
            counter
            maxlength="120"
            full-width
            single-line
          />
        </v-form>
      </v-card-text>
      <v-spacer />
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          :loading="submitting"
          :disabled="!form.valid"
          color="primary"
          @click="submit"
        >
          {{ 'dialog.update'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import debounce from 'lodash/debounce';

export default {
  props: {
    groupId: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    context: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rules: {
        required: [
          (v) => !!v || this.$trans('productionview.required'),
        ],
        email: [
          (v) => !!v || this.$trans('productionview.required'),
          (v) => /.+@.+\..+/.test(v) || this.$trans('users.email-invalid'),
        ],
      },
      form: {
        valid: false,
      },
      maxElements: 5,
      timezoneIsLoading: false,
      searchTimezone: null,
      subscriptionsOptions: [],
      timezonesOptions: [],
      userGroup: {
        id: null,
        name: null,
        registerDeviceOperators: false,
        subscriptionType: {
          id: 0,
          name: '',
        },
        timezone: {
          id: 0,
        },
        description: null,
      },
      showDialog: false,
      fetching: false,
      submitting: false,
      entry: null,
      options: {},
    };
  },
  computed: {
    subscriptionType: {
      get() {
        if (this.userGroup.subscriptionType === null) {
          return null;
        }

        return this.userGroup.subscriptionType.id;
      },
      set(value) {
        if (value === null) {
          this.userGroup.subscriptionType = null;
        }
        this.userGroup.subscriptionType = { id: value };
      },
    },
  },
  watch: {
    async searchTimezone(val) {
      if (val !== null) {
        await this.getTimezonesDebounced(val);
      }
    },
    showDialog: {
      async handler(newValue) {
        this.fetching = true;
        if (newValue === true) {
          await this.onOpen();
        }

        if (newValue === false) {
          await this.onClose();
        }
      },
    },
  },
  created() {
    this.siteLoading = true;
    this.getTimezonesDebounced = debounce((val) => {
      this.getTimezones(val);
    }, 1000);
  },
  methods: {
    async submitChanges() {
      if (this.$refs.formEdit.validate()) {
        this.submitting = true;
        const { status } = await this.$http.post(
          `/admin/user_group/${this.groupId}`,
          {
            name: this.userGroup.name,
            description: this.userGroup.description,
            registerDeviceOperators: this.userGroup.registerDeviceOperators,
            timezone: this.userGroup.timezone.id,
            subscriptionType: this.subscriptionType,
          },
        );

        if (status === 200) {
          this.showDialog = false;
          this.$emit('on-change');
        }
        this.submitting = false;
      }
    },
    async getTimezones(search) {
      this.timezoneIsLoading = true;

      const { data, status } = await this.$http.get(
        '/user/timezones',
        {
          page: 1,
          perPage: this.maxElements,
          search,
        },
      );

      if (status === 200) {
        this.timezonesOptions = data.data;
      }

      this.timezoneIsLoading = false;
    },
    async getSubscriptionsTypes() {
      const { data, status } = await this.$http.get('/user/subscriptions/types');

      if (status === 200) {
        this.subscriptionsOptions = data.data;
      }
    },
    async getData() {
      const { data, status } = await this.$http.get(`/admin/user_group/${this.groupId}`);

      if (status === 200) {
        this.userGroup = data;
        if (data.timezone !== null) {
          this.timezonesOptions = [
            {
              id: data.timezone.id,
              location: data.timezone.location,
            },
          ];
        } else {
          this.timezonesOptions = [
            {
              id: 0,
              location: '',
            },
          ];
        }

        if (data.subscriptionType !== null) {
          this.subscriptionsOptions = [
            {
              id: data.subscriptionType.id,
              name: data.subscriptionType.name,
            },
          ];
        } else {
          this.subscriptionsOptions = [
            {
              id: 0,
              name: '',
            },
          ];
        }
        await this.getSubscriptionsTypes();
      }
      this.fetching = false;
    },
    submit() {
      this.submitChanges();
    },
    async onOpen() {
      this.fetching = true;
      await this.getTimezones();
      await this.getData();
    },
    onClose() {
      this.userGroup = {
        id: 0,
        subscriptionType: {
          id: 0,
        },
        timezone: {
          id: 0,
        },
        language: {
          locale: 'en',
        },
        notes: null,
      };
      this.showDialog = false;
    },
  },
};
</script>
