<template>
  <v-row>
    <v-col>
      <portal
        v-if="isActivePage"
        to="page-actions"
      >
        <bkf-floating-button-add
          v-if="isMobile"
          :tooltip="$trans('productionview.create')"
          @click.native="prodviewconfigurator"
        />
        <v-btn
          tile
          color="primary"
          @click.native="openMyDialog"
        >
          {{ 'productionview.create'|trans }}
        </v-btn>
      </portal>
      <bkf-page
        v-if="isActivePage"
        :loading="loading"
        :error="error"
        class="pt-3"
      >
        <bkf-widget v-if="productionview.length">
          <v-data-table
            :headers="headers"
            :items="productionview"
          >
            <template
              #[`item.name`]="{ item }"
            >
              <div class="pt-4 pb-2">
                {{ item.name }}
              </div>
              <div class="caption blue-grey--text lighten-1">
                <div
                  v-for="(device, key) in item.devices"
                  :key="key"
                  class="pb-2"
                >
                  {{ device.name }}
                </div>
              </div>
            </template>

            <template
              #[`item.action`]="{ item }"
            >
              <bkf-action-delete
                :tooltip="$trans('productionview.delete')"
                @click="removeProductionView(item.id, item.name)"
              />
              <bkf-action-edit
                :tooltip="$trans('productionview.editicon')"
                @click="editProductionView(item.id)"
              />
              <v-btn
                class="ml-2"
                color="primary"
                small
                tile
                :href="`/monitor/#/` + item.id"
              >
                {{ 'productionview.view'|trans }}
              </v-btn>
            </template>
          </v-data-table>
        </bkf-widget>
        <bkf-empty-state v-else />
      </bkf-page>
      <production-view-configurator
        :title="title"
        :show-dialog="showDialog"
        :edit-prod-view="editProdView"
        :edited-production-view="editedProductionView"
        :prodviewtype="prodviewtype"
        @send-parameters="sendParameters"
        @open-configurator-dialog="openConfiguratorDialog"
        @close-configurator-dialog="closeConfiguratorDialog"
      />
      <delete-dialog
        :dialog="dialog"
        :delete-id="deleteId"
        :delete-name="deleteName"
        @close-dialog="closeDialog"
        @delete-production-view="deleteProductionView"
      />
      <router-view />
    </v-col>
    <bkf-crud
      ref="crud"
      :create-submit-label="$trans('productionview.create')"
    />
  </v-row>
</template>

<script>
import pageMixin from '@mixins/page';
import listMixin from '@mixins/page/list';
import { mapGetters } from 'vuex';
import deleteDialog from './Delete.vue';
import productionViewConfigurator from '../../components/dialogs/productionviewconfigurator/productionViewConfigurator.vue';

export default {
  components: {
    deleteDialog,
    productionViewConfigurator,
  },

  mixins: [pageMixin, listMixin],
  data() {
    return {
      dialog: false,
      deleteId: 0,
      deleteName: '',
      productionViewIndex: 0,
      index: 0,
      loading: false,
      productionview: [],
      settings: {
        size: 10,
      },
      title: '',
      showDialog: false,
      editProdView: false,
      prodviewtype: [],
      editedProductionView: {},
      headers: [
        {
          text: t`device.name`, align: 'left', value: 'name', sortable: false,
        },
        {
          text: t`productionview.action`, align: 'right', value: 'action', sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      productionViewDevices: 'devices/deviceNameById',
      isMobile: 'size/isMobile',
    }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const url = '/productionview';
      this.loading = true;
      const { data, status } = await this.$http.get(url);
      this.loading = false;

      if (status === 200) {
        this.productionview = data;
      }
    },
    async openMyDialog() {
      this.title = t`productionview.create`;
      this.editProdView = false;
      const url = '/productionview/new';
      const { data, status } = await this.$http.get(url);

      if (status === 200) {
        this.prodviewtype = data;
        this.showDialog = true;
      }
    },
    async sendParameters(params) {
      let url = '';
      let response = '';

      if (this.editProdView) {
        url = `/productionview/update/${this.productionViewIndex}`;
        response = await this.$http.patch(url, params, {});
      } else {
        url = '/productionview/save';
        response = await this.$http.post(url, params, {});
      }

      if (response.ok) {
        this.index = 0;
        this.showDialog = false;
        this.productionview = [];
        this.prodviewtype = [];
        this.fetchData();
      } else {
        this.$notify.error(t`productionview.error.save`);
      }
    },
    async editProductionView(id) {
      this.title = t`productionview.edit`;
      this.productionViewIndex = id;
      const url = `/productionview/edit/${id}`;
      const { data, status } = await this.$http.get(url);
      this.loading = false;

      if (status === 200) {
        this.prodviewtype = data.data.view_types;
        this.editProdView = true;
        this.showDialog = true;
        this.editedProductionView = data.data;
      }
    },
    removeProductionView(id, name) {
      this.showDialog = false;
      this.dialog = true;
      this.deleteId = id;
      this.deleteName = name;
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleteProductionView(id) {
      const url = `/productionview/${id}`;
      const response = await this.$http.delete(url);

      if (response.status === 200) {
        this.index = 0;
        this.dialog = false;
        this.fetchData();
      } else {
        this.$notify.error(t`productionview.error.save`);
      }
    },
    openConfiguratorDialog() {
      this.showDialog = true;
    },
    closeConfiguratorDialog() {
      this.showDialog = false;
    },
  },
};
</script>
