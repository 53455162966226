import { render, staticRenderFns } from "./AdministrationDevices.vue?vue&type=template&id=51156c2c&scoped=true&"
import script from "./AdministrationDevices.vue?vue&type=script&lang=js&"
export * from "./AdministrationDevices.vue?vue&type=script&lang=js&"
import style0 from "./AdministrationDevices.vue?vue&type=style&index=0&id=51156c2c&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51156c2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VCol,VDataTable,VProgressCircular,VRow,VSimpleTable,VSpacer,VTextField})
