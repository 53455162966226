export default function deep(object, path) {
  if (typeof path !== 'string') {
    return undefined;
  }

  const leafs = path.split('.');
  const { length } = leafs;
  let index = 0;
  let current = object;

  while (current !== undefined && index < length) {
    if (current === null) {
      current = undefined;
    } else {
      current = current[leafs[index]];
      index += 1;
    }
  }

  return current;
}
