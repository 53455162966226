<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-toolbar
          dark
          color="error"
          class="flex-grow-0"
        >
          <v-toolbar-title> {{ 'productionview.delete.title'|trans }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <p class="mt-8">
            {{ 'productionview.delete.message'|trans }}
            {{ deleteName }}
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="normal"
            @click="closeDialog()"
          >
            {{ 'productionview.delete.discard'|trans }}
          </v-btn>
          <v-btn
            color="error"
            @click="deleteProductionView()"
          >
            {{ 'productionview.delete.confirm'|trans }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteId: {
      type: Number,
      required: true,
    },
    deleteName: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    deleteProductionView() {
      this.$emit('delete-production-view', this.deleteId);
    },
  },
};
</script>
