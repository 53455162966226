<template>
  <div
    class="device-indicator"
    :class="state"
  >
    <slot />
    <div class="device-indicator__description">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      default: null,
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="stylus" src="./Indicator.styl"></style>
