<template>
  <v-dialog
    ref="modal"
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="400"
    eager
    transform-origin="bottom center"
  >
    <v-card class="d-flex flex-column">
      <v-toolbar
        dark
        color="primary"
        class="flex-grow-0"
      >
        <v-toolbar-title>
          {{ 'device-operator.assign-devices'|trans }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 flex-grow-1">
        <v-form
          @keyup.native.enter="submit"
        >
          <v-row>
            <v-col>
              <v-radio-group
                v-model="settings.byGroups"
                :mandatory="true"
                :hide-details="true"
                row
                dense
                class="mb-4 mt-2"
                @change="changeRadioEvent"
              >
                <v-radio
                  :label="$trans('monitor.devices')"
                  :value="false"
                />
                <v-radio
                  :label="$trans('monitor.device-groups')"
                  :value="true"
                />
              </v-radio-group>
              <devices-field
                v-show="!settings.byGroups"
                v-model="settings.devices"
                :devices="devices"
                :error="error"
              />
              <device-groups-field
                v-show="settings.byGroups"
                v-model="settings.deviceGroups"
                :device-groups="deviceGroups"
                :error="error"
                @input="changeEvent"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          tile
          color="primary"
          :disabled="isEmpty"
          @click="submit"
        >
          {{ 'dialog.add'|trans }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceGroupsField from '../fields/DeviceGroups/DeviceGroups.vue';
import DevicesField from '../fields/Devices/Devices.vue';

export default {
  components: {
    DeviceGroupsField,
    DevicesField,
  },
  props: {
    devices: {
      required: true,
      type: Array,
    },
    deviceGroups: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      hasFocus: false,
      showDialog: false,
      settings: {
        devices: [],
        deviceGroups: [],
        byGroups: false,
      },
      error: false,
      model: null,
      entry: null,
    };
  },
  computed: {
    isEmpty() {
      return this.settings.devices.length === 0 && this.settings.deviceGroups.length === 0;
    },
  },
  methods: {
    focus(state) {
      this.hasFocus = state;
    },
    open() {
      this.settings.devices = [];
      this.settings.deviceGroups = [];
      this.settings.byGroups = false;
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.$emit('submit', this.settings.devices);
      this.settings.devices = [];
      this.settings.deviceGroups = [];
      this.close();
    },
    async getDevicesFromGroup(group) {
      this.model = await this.$model('DeviceGroup');
      this.entry = await this.model.get({
        id: group,
        contexts: ['devicegroup_details'],
      });
      this.entry.$data.devices.forEach((device) => {
        this.settings.devices.push(device.id);
      });
    },
    getSelectItemText(item) { return item.name; },
    getSelectItemValue: (item) => item.id,
    changeEvent(groupsId) {
      this.settings.devices = [];
      groupsId.forEach((id) => {
        this.getDevicesFromGroup(id);
      });
    },
    changeRadioEvent() {
      this.settings.devices = [];
      this.settings.deviceGroups = [];
    },
  },
};
</script>
